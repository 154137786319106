<template>
  <div class="screen-container">
    <div class="demo" :data-noscroll="$store.state.overlayPanel?true:false" :data-guided="guidedMode" :data-show-links="showLinks">

      <div class="close-button" data-tgt="hiring-onboarding" data-tgtMode="admin" @click="close">
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 21 21">
          <path d="M28.5,9.615,26.385,7.5,18,15.885,9.615,7.5,7.5,9.615,15.885,18,7.5,26.385,9.615,28.5,18,20.115,26.385,28.5,28.5,26.385,20.115,18Z" fill="white" transform="translate(-7.5 -7.5)"/>
        </svg>
      </div>

      <div class="screens" ref="screens">
        <!--- DEFAULT ONBOARDING WITH FULL RECRUITING -->
        <div>

          <div class="screen z-max" data-screen="hiring-copilot" :data-active="currentScreen=='hiring-copilot'">
            <img class="w-full max-w-100" src="/img/screens/hiring/hiring-copilot-projects.png?v=12222024" />
            <div class="links">
              <screen-link left="23.5%" top="32%" width="57.5%" height="11.5%" dot-class="dot-left-center" data-tgt="hiring-copilot-project-create" @click.native="onLinkClick"></screen-link>
            </div>
          </div>

          <div class="screen z-max" data-screen="hiring-copilot-project-create" :data-active="currentScreen=='hiring-copilot-project-create'">
            <img class="w-full max-w-100" src="/img/screens/hiring/hiring-copilot-projects-new.png?v=12222024" />
            <div class="links">
              <screen-link left="47.25%" top="47%" width="10%" height="5%" dot-class="dot-bottom-center" data-tgt="hiring-copilot-project" @click.native="onLinkClick"></screen-link>
            </div>
          </div>

          <div class="screen z-max" data-screen="hiring-copilot-project" :data-active="currentScreen=='hiring-copilot-project'">
            <img class="w-full max-w-100" src="/img/screens/hiring/hiring-copilot-project-matches.png?v=12222024" />
            <div class="links">
              <screen-link left="6%" top="51%" width="18%" height="6%" dot-class="dot-left-center" data-tgt="hiring-copilot-project-skills" @click.native="onLinkClick"></screen-link>
            </div>
          </div>

          <div class="screen z-max" data-screen="hiring-copilot-project-skills" :data-active="currentScreen=='hiring-copilot-project-skills'">
            <img class="w-full max-w-100" src="/img/screens/hiring/hiring-copilot-project-skills.png?v=12222024" />
            <div class="links">
              <screen-link left="30.5%" top="21%" width="18%" height="12%" dot-class="dot-left-center" data-tgt="hiring-copilot-project-match" @click.native="onLinkClick"></screen-link>
            </div>
          </div>

          <div class="screen z-max" data-screen="hiring-copilot-project-match" :data-active="currentScreen=='hiring-copilot-project-match'">
            <img class="w-full max-w-100" src="/img/screens/hiring/hiring-copilot-project-match.png?v=12222024" />
            <div class="links">
              <screen-link left="96.5%" top="0%" width="3%" height="4.75%" dot-class="dot-left-center" data-tgt="hiring-copilot-project-skills" @click.native="onLinkClick"></screen-link>
              <screen-link left="27%" top="54%" width="40.5%" height="5.5%" dot-class="dot-left-center" data-tgt="hiring-copilot-project-match-unlocked" @click.native="onLinkClick"></screen-link>
            </div>
          </div>

          <div class="screen z-max" data-screen="hiring-copilot-project-match-unlocked" :data-active="currentScreen=='hiring-copilot-project-match-unlocked'">
            <img class="w-full max-w-100" src="/img/screens/hiring/hiring-copilot-project-match-unlocked.png?v=12222024" />
            <div class="links">
              <screen-link left="34%" top="18%" width="6.5%" height="5.5%" dot-class="dot-left-center" data-tgt="hiring-copilot-project-match-contact" @click.native="onLinkClick"></screen-link>
            </div>
          </div>

          <div class="screen z-max" data-screen="hiring-copilot-project-match-contact" :data-active="currentScreen=='hiring-copilot-project-match-contact'">
            <img class="w-full max-w-100" src="/img/screens/hiring/hiring-copilot-project-match-contact.png?v=12222024" />
            <div class="links">
              <screen-link left="62.5%" top="40%" width="34%" height="10%" dot-class="dot-left-center" data-tgt="hiring-copilot-project-match-contacted" @click.native="onLinkClick"></screen-link>
              <screen-link left="62.5%" top="55%" width="34%" height="22%" dot-class="dot-left-center" data-tgt="hiring-copilot-project-match-contacted" @click.native="onLinkClick"></screen-link>
            </div>
          </div>

          <div class="screen z-max" data-screen="hiring-copilot-project-match-contacted" :data-active="currentScreen=='hiring-copilot-project-match-contacted'">
            <img class="w-full max-w-100" src="/img/screens/hiring/hiring-copilot-project-match-contacted.png?v=12222024" />
            <div class="links">
              <screen-link left="16%" top="7%" width="8%" height="5.5%" dot-class="dot-bottom-center" data-tgt="hiring-copilot-project-contacts" @click.native="onLinkClick"></screen-link>
            </div>
          </div>

          <div class="screen z-max" data-screen="hiring-copilot-project-contacts" :data-active="currentScreen=='hiring-copilot-project-contacts'">
            <img class="w-full max-w-100" src="/img/screens/hiring/hiring-copilot-project-contacts.png?v=12222024" />
            <div class="links">
              <screen-link left="7%" top="7%" width="8.5%" height="5%" dot-class="dot-bottom-center" data-tgt="hiring-copilot-project-match-contacted" @click.native="onLinkClick"></screen-link>
              <screen-link left="93.6%" top="40%" width="4%" height="5%" dot-class="dot-bottom-center" data-tgt="hiring-copilot-project-contact-export" @click.native="onLinkClick"></screen-link>
            </div>
          </div>

          <div class="screen z-max" data-screen="hiring-copilot-project-contact-export" :data-active="currentScreen=='hiring-copilot-project-contact-export'">
            <img class="w-full max-w-100" src="/img/screens/hiring/hiring-copilot-project-contact-export.png?v=12222024" />
            <div class="links">
              <screen-link left="62.9%" top="12.8%" width="3%" height="5%" dot-class="dot-bottom-center" data-tgt="hiring-copilot-project-contacts" @click.native="onLinkClick"></screen-link>
              <screen-link left="35%" top="30.5%" width="30%" height="5.5%" dot-class="dot-right-center" data-tgt="hiring-copilot-project-contact-export-type" @click.native="onLinkClick"></screen-link>
            </div>
          </div>

          <div class="screen z-max" data-screen="hiring-copilot-project-contact-export-type" :data-active="currentScreen=='hiring-copilot-project-contact-export-type'">
            <img class="w-full max-w-100" src="/img/screens/hiring/hiring-copilot-project-contact-export-type.png?v=12222024" />
            <div class="links">
              <screen-link left="62.9%" top="12.8%" width="3%" height="5%" dot-class="dot-bottom-center" data-tgt="hiring-copilot-project-contacts" @click.native="onLinkClick"></screen-link>
              <screen-link left="35%" top="46.5%" width="30%" height="5.5%" dot-class="dot-top-center" data-tgt="hiring-onboarding" data-tgt-mode="admin" @click.native="addEmployee"></screen-link>
            </div>
          </div>


        </div>



      </div>

    </div>

  </div>

</template>

<script>
import ScreenLink from "../components/ScreenLink";
import router from "@/router";
//import DocumentSign from "@/components/onboarding/DocumentSign";
//import FlexNavTopBar from "../components/FlexNavTopBar";
//import FlexSideNav from "../components/FlexAdminSideNav";
//import InfoBox from "../components/InfoBox";

// import PeoplePullout from "@/components/PeoplePullout";
// import SelectBox from "@/components/SelectBox";
// import NewsPullout from "@/components/NewsPullout";
// import NotificationsPullout from "@/components/NotificationsPullout";

// import FiltersPanel from "../components/FiltersPanel";
// import SelectBox from "@/components/SelectBox";
// import FlexInfo from "@/components/FlexInfo";

export default {
  name: "hiring-copilot-screens",
  components: {ScreenLink, /*FiltersPanel, FlexInfo, SelectBox,*/ },
  mounted: function(){

    this.$store.state.screenScrollRef = window;

    // this.$store.commit("showMenu", false);
    // if(this.$route.query){
    //   this.$store.commit("guidedMode", this.$route.query.guided);
    //   if(this.$route.query.comments){
    //     this.$store.commit("showFeedback", true);
    //   }
    // }

  },
  props: {},
  data: function () {
    return {
      defaultScreen: 'hiring-copilot',
      visibility: {
        topnav: true
      },
      pulloutPanel: null,
      disableScreenScoll: false,
      //noRecruiting: false,
      //essentials: false,
      //i9BackTgt: "onboarding-i9",
      //i9FinishTgt: "onboarding-final",
      //managerI9finishTgt: ""


    }
  },
  methods: {

    close: function(){

        this.onLinkClick({
          target: {
            dataset: {
              tgt: "hiring-onboarding",
              tgtMode: "admin",
              tgtScrolltop: "true"
            }
          }
        })

    },

    showPrevious: function(){
      this.$router.back();
    },

    onLinkClick: function(e){
      // this.currentScreen = e.target.dataset.tgt;
      if(!e.target.dataset.tgtMode){
        e.target.dataset.tgtMode = 'copilot';
      }

      console.log(e)

      this.$screens.onLinkClick(e);
    },

    showExternalPage: function(e){
      // this.currentScreen = e.target.dataset.tgt;
      let tgtUrl = e.target.dataset.tgt;
      window.open(tgtUrl, "_blank");
    },

    showPullout: function(e){
      // this.currentScreen = e.target.dataset.tgt;
      this.pulloutPanel = e.target.dataset.tgt
    },

    showOverlay: function(id){
      this.$screens.setOverlayPanel(id)
      //this.overlayPanel = id;
    },

    addEmployee: function(){
      router.push("/admin/dashboard?tab=people&level=add-employee-details")
    }
  },
  computed: {

    showMenu: function(){
      return this.$store.state.showMenu;
    },

    showLinks: function(){
      return this.$store.state.showLinks;
    },

    guidedMode: function(){
      return this.$store.state.guidedMode;
    },

    currentScreen: function(){

      let target = this.defaultScreen;
      if(this.$route.params.screen){
        target = this.$route.params.screen;
      }

      return target
    },

    sideNavLink: function(){
      if(this.$route.params.screen){
        return this.$route.params.screen.split("-")[0];
      }else{
        return "dashboard";
      }
    },

    sideNavMode: function(){
      if(this.$route.query.mode){
        return this.$route.query.mode;
      }else{
        return "admin";
      }
    }
  }
}
</script>

<style scoped>

.close-button {
  position: absolute;
  top:0;
  left: 0;
  margin: 1em 1.9em;
  z-index: 200;
  cursor: pointer;
  opacity: 0.5;
}

.close-button:hover {
  opacity: 1;
}


img {
  margin: 0;
}

.screen-container {

  margin: 0px auto;
}

#app[data-feedback-mode="true"] .screen-container {

  margin: 0;

}


.demo {
  width: 100%;
  position: relative;



}

.demo[data-noscroll="true"]{


}



.screens {

  position: relative;
  width: 100%;
  overflow-x: hidden;
}

.overlay {
  position: absolute;
  width: calc((320/1580) * 100%);
  top: calc((60/920) * 100%);
  right: 0;
  display: none;
}

.overlay[data-active="true"] {
  display: block;
}

.pullout {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  display: none;
  background-color: rgba(0,0,0,0.49);
}

.pullout[data-active="true"] {
  display: block;
}

.popover {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  display: none;
}

.popover[data-active="true"] {
  display: block;
}

.overlay[data-active="true"] {
  display: block;
}

.screens .screen {
  position: relative;
  width: 100%;
  display: none;
}

.screen[data-active="true"] {
  display: block;
}

.screen .links {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  padding-top: calc((920/1580) * 100%);
}

.pullout .links {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  padding-top: calc((920/1580) * 100%);
}


/* UTILITIES */

.w-full {
  width: 100%;
}



.pullout-1200 {
  position: absolute;
  width: calc(100% * (1200 / 3160));
  top: 0;
  right: 0;
}

.popover-email {
  position: absolute;
  width: calc(100% * (1000 / 1580));
  top: 20%;
  right: 5%;
  box-shadow: 0 10px 20px rgba(0,0,0,0.3);

}

.popover-with-bkg {
  background-color: rgba(0,0,0,0.4);


}

.popover-time-attendance {
  position: absolute;
  width: calc(100% * (1000 / 1580));
  top: 20%;
  right: 15%;
  box-shadow: 0 10px 20px rgba(0,0,0,0.3);

}

.popover-schedule-details {
  position: absolute;
  width: calc(100% * (808 / 1580));
  top: 20%;
  right: 20%;
  box-shadow: 0 10px 20px rgba(0,0,0,0.3);

}

.x-ray {
  opacity: 0.5;
}

*[data-hidden] {
  opacity: 0;
}



.demo[data-show-links="true"] .select-box {

}

.demo[data-show-links="true"] .flex-info {
  border: 2px solid var(--color-green);
}

.demo[data-show-links="true"] .info-box {
  display: block;
}

.demo .info-box {
  display: none;
}


.demo[data-guided="true"] .info-box {
  display: block;
}

.z-max {
  z-index: 50;
}

.z-max-200 {
  z-index: 200;
}

.z-45 {
  z-index: 45;
}

.z-40 {
  z-index: 40;
}

.info-box {
  z-index: 30;
}

.demo-menu {
  top: 0;
}

</style>

<style>
html {
  background: #F3F7FA;
}
</style>
