


<template>
<div class="">

  <div v-if="$store.state.company == 'pro'" class="screen" data-screen="reports" :data-active="currentScreen=='reports'">
    <img class="w-full max-w-100" src="/img/screens/reports/reports-pro.png?v=10122022" />
    <div class="links">
      <screen-link left="14.8%" top="18%" width="5%" height="4%"  dot-class="dot-bottom-center" data-tgt="reports" @click.native="onLinkClick"></screen-link>

      <screen-link left="20%" top="18%" width="5%" height="4%"  dot-class="dot-bottom-center" data-tgt="reports-analytics" @click.native="onLinkClick"></screen-link>
      <screen-link left="25.5%" top="18%" width="6.5%" height="4%"  dot-class="dot-bottom-center" data-tgt="reports-hr-analytics" @click.native="onLinkClick"></screen-link>
      <screen-link left="32%" top="52%" width="10%" height="4%"  dot-class="dot-bottom-center" data-tgt="pullout-quick-reports" @click.native="showPullout"></screen-link>
      <screen-link left="32%" top="81%" width="10%" height="4%"  dot-class="dot-bottom-center" data-tgt="pullout-live-reports" @click.native="showPullout"></screen-link>

      <payroll-labor-cost-panel style="position:absolute;top: 42.5%;left: 47.6%;width: 18.9%;"></payroll-labor-cost-panel>

      <screen-link left="52%" top="95%" width="10%" height="4%"  dot-class="dot-bottom-center" data-init-info="labor-cost" data-tgt="pullout-live-reports" @click.native="showPullout"></screen-link>


      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" />

      <info-box v-if="!$store.state.pulloutPanel && !$store.state.overlayPanel" left="97.5%" top="10.5%" width="40em" height="4.5%" >
        <p>Under the Analytics & Reports feature, admins can see reports grouped by category. This is where payroll journals and cash requirements can be quickly accessed. In the Live Reports tile, clients can find interactive reporting with on-screen feedback and insights into their organization. Reporting can be downloaded and accessed for more than three years.</p>
      </info-box>
    </div>
  </div>

  <div v-else class="screen" data-screen="reports" :data-active="currentScreen=='reports'">
    <img class="w-full max-w-100" src="/img/screens/reports/reports-enterpriseg.png?v=12222024" />
    <div class="links">
      <screen-link left="14.8%" top="18%" width="5%" height="4%"  dot-class="dot-bottom-center" data-tgt="reports" @click.native="onLinkClick"></screen-link>

      <screen-link left="20%" top="18%" width="5%" height="4%"  dot-class="dot-bottom-center" data-tgt="reports-analytics" @click.native="onLinkClick"></screen-link>
      <screen-link left="25.5%" top="18%" width="6.5%" height="4%"  dot-class="dot-bottom-center" data-tgt="reports-hr-analytics" @click.native="onLinkClick"></screen-link>
     

      <screen-link left="32.25%" top="54.5%" width="10%" height="4%"  dot-class="dot-bottom-center" data-tgt="pullout-quick-reports" @click.native="showPullout"></screen-link>
      <screen-link left="52.5%" top="54.5%" width="10%" height="4%"  dot-class="dot-bottom-center" data-tgt="pullout-live-reports" @click.native="showPullout"></screen-link>
      <screen-link left="71.5%" top="35.25%" width="10%" height="4%"  dot-class="dot-bottom-center" data-tgt="reports-hr-analytics" @click.native="onLinkClick"></screen-link>

      <payroll-labor-cost-panel style="position:absolute;top: 73%;left: 47.9%;width: 18.9%;"></payroll-labor-cost-panel>

      <payroll-item-details-panel style="position:absolute;top: 59%;left: 47.6%;width: 18.9%; display:none;"></payroll-item-details-panel>
      <equal-pay-panel style="position:absolute;top: 71%;left: 27.4%;width: 18.9%; display:none;"></equal-pay-panel>

      <screen-link left="52.5%" top="125%" width="10%" height="4%"  dot-class="dot-bottom-center" data-tgt="pullout-live-reports" data-init-info="labor-cost" @click.native="showPullout"></screen-link>
      <screen-link left="32.25%" top="110.5%" width="10%" height="4%"  dot-class="dot-bottom-center" data-tgt="pullout-live-reports" data-init-info="pay-benchmarking" @click.native="showPullout"></screen-link>
      <screen-link left="52.5%" top="183%" width="10%" height="4%"  dot-class="dot-bottom-center" data-tgt="pullout-live-reports" data-init-info="document-management" @click.native="showPullout"></screen-link>

      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" :active-companies="{enterprise:true,pro:true}"/>

      <info-box v-if="!$store.state.pulloutPanel && !$store.state.overlayPanel" left="97.5%" top="10.5%" width="40em" height="4.5%" >
        <p>Under the Analytics & Reports feature, admins can see reports grouped by category. This is where payroll journals and cash requirements can be quickly accessed. In the Live Reports tile, clients can find interactive reporting with on-screen feedback and insights into their organization. Reporting can be downloaded and accessed for more than three years.</p>
      </info-box>
    </div>
  </div>

  <div class="screen" data-screen="reports-hr-analytics" :data-active="currentScreen=='reports-hr-analytics'">
    <img class="w-full max-w-100" src="/img/screens/reports/reports-hr-analytics.png?v=12222024" />
    <div class="links">
      <screen-link left="14.8%" top="18%" width="5%" height="4%"  dot-class="dot-bottom-center" data-tgt="reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="20%" top="18%" width="5%" height="4%"  dot-class="dot-bottom-center" data-tgt="reports-analytics" @click.native="onLinkClick"></screen-link>
      <screen-link left="25.5%" top="18%" width="6.5%" height="4%"  dot-class="dot-bottom-center" data-tgt="reports-hr-analytics" @click.native="onLinkClick"></screen-link>

      <screen-link left="28.50%" top="30.5%" width="56.5%" height="9%"  dot-class="dot-right-center" data-tgt="reports-hr-insights-guidebooks" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
      <screen-link left="28.50%" top="39.6%" width="56.5%" height="8%"  dot-class="dot-right-center" data-tgt="reports-hr-analytics-comp-benchmarking" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>


      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" :active-companies="{enterprise:true,pro:true}"/>
    </div>
  </div>

  <div class="screen" data-screen="reports-hr-insights-guidebooks" :data-active="currentScreen=='reports-hr-insights-guidebooks'">
    <img class="w-full max-w-100" src="/img/screens/reports/reports-insights-guidebooks.png?v=12222024" />
    <div class="links">
      <screen-link left="14.8%" top="14%" width="8.5%" height="4%"  dot-class="dot-right-center" data-tgt="reports-hr-analytics" @click.native="onLinkClick"></screen-link>
      <screen-link left="16%" top="26.5%" width="8.5%" height="4%"  dot-class="dot-left-center" data-tgt="reports-hr-analytics" @click.native="onLinkClick"></screen-link>

      <screen-link left="90%" top="18%" width="9%" height="6%"  dot-class="dot-left-center" data-tgt="reports-hr-ai" @click.native="onLinkClick"></screen-link>

      <screen-link left="23.5%" top="44%" width="16%" height="30%"  dot-class="dot-top-center" data-tgt="reports-hr-analytics-start" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
      <screen-link left="40.5%" top="44%" width="16%" height="30%"  dot-class="dot-top-center" data-tgt="reports-hr-analytics-comp" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
      <screen-link left="57.5%" top="44%" width="16%" height="30%"  dot-class="dot-top-center" data-tgt="reports-hr-analytics-diversity" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
      <screen-link left="74.5%" top="44%" width="16%" height="30%"  dot-class="dot-top-center" data-tgt="reports-hr-analytics-org" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
      <screen-link left="23.5%" top="77%" width="16%" height="32%"  dot-class="dot-top-center" data-tgt="reports-hr-analytics-mobility" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>

      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" :active-companies="{enterprise:true,pro:true}"/>
    </div>
  </div>


  <div class="screen" data-screen="reports-hr-ai" :data-active="currentScreen=='reports-hr-ai'">
    <img class="w-full max-w-100" src="/img/screens/reports/reports-insights-ai.png?v=12222024" />
    <div class="links">
      <screen-link left="76.5%" top="10%" width="3%" height="4%"  dot-class="dot-left-center" data-tgt="reports-hr-insights-guidebooks" @click.native="onLinkClick"></screen-link>
      <screen-link left="21%" top="84%" width="58%" height="6%"  dot-class="dot-top-center" data-tgt="reports-hr-ai-results" @click.native="onLinkClick"></screen-link>

    </div>
  </div>

  <div class="screen" data-screen="reports-hr-ai-results" :data-active="currentScreen=='reports-hr-ai-results'">
    <img class="w-full max-w-100" src="/img/screens/reports/reports-ai-popup-results.png?v=12222024" />
    <div class="links">
      <screen-link left="76.5%" top="10%" width="3%" height="4%"  dot-class="dot-left-center" data-tgt="reports-hr-insights-guidebooks" @click.native="onLinkClick"></screen-link>
      <screen-link left="26.5%" top="49%" width="2.75%" height="4.5%"  dot-class="dot-top-center" data-tgt="reports-hr-ai-results-details" @click.native="onLinkClick"></screen-link>

    </div>
  </div>

  <div class="screen" data-screen="reports-hr-ai-results-details" :data-active="currentScreen=='reports-hr-ai-results-details'">
    <img class="w-full max-w-100" src="/img/screens/reports/reports-ai-popup-results-details.png?v=12222024" />
    <div class="links">
      <screen-link left="76.5%" top="10%" width="3%" height="4%"  dot-class="dot-left-center" data-tgt="reports-hr-insights-guidebooks" @click.native="onLinkClick"></screen-link>
      <screen-link left="21%" top="114.5%" width="58%" height="6%"  dot-class="dot-top-center" data-tgt-scrollTop="true" data-tgt="reports-hr-ai-results-details-2" @click.native="onLinkClick"></screen-link>
    </div>
  </div>

  <div class="screen" data-screen="reports-hr-ai-results-details-2" :data-active="currentScreen=='reports-hr-ai-results-details-2'">
    <img class="w-full max-w-100" src="/img/screens/reports/reports-ai-popup-results-details-2.png?v=12222024" />
    <div class="links">
      <screen-link left="76.5%" top="10%" width="3%" height="4%"  dot-class="dot-left-center" data-tgt="reports-hr-insights-guidebooks" @click.native="onLinkClick"></screen-link>
      <screen-link left="21%" top="57%" width="58%" height="46%"  dot-class="dot-left-center" data-tgt-scrollTop="true" data-tgt="reports-hr-ai-results-details-3" @click.native="onLinkClick"></screen-link>
    </div>
  </div>

  <div class="screen" data-screen="reports-hr-ai-results-details-3" :data-active="currentScreen=='reports-hr-ai-results-details-3'">
    <img class="w-full max-w-100" src="/img/screens/reports/reports-ai-popup-results-details-3.png?v=12222024" />
    <div class="links">
      <screen-link left="76.5%" top="10%" width="3%" height="4%"  dot-class="dot-left-center" data-tgt="reports-hr-insights-guidebooks" @click.native="onLinkClick"></screen-link>
      <screen-link left="66%" top="15%" width="11%" height="4%"  dot-class="dot-bottom-center" data-tgt="reports-hr-ai-results-details-4" @click.native="onLinkClick"></screen-link>
    </div>
  </div>

  <div class="screen" data-screen="reports-hr-ai-results-details-4" :data-active="currentScreen=='reports-hr-ai-results-details-4'">
    <img class="w-full max-w-100" src="/img/screens/reports/reports-ai-popup-results-details-4.png?v=12222024" />
    <div class="links">
      <screen-link left="76.5%" top="10%" width="3%" height="4%"  dot-class="dot-left-center" data-tgt="reports-hr-insights-guidebooks" @click.native="onLinkClick"></screen-link>
      <screen-link left="57%" top="15%" width="9%" height="4%"  dot-class="dot-bottom-center" data-tgt-scrollTop="true" data-tgt="reports-hr-ai-results-details-3" @click.native="onLinkClick"></screen-link>
      <screen-link left="58%" top="25.5%" width="14.5%" height="4%"  dot-class="dot-bottom-center" data-tgt-scrollTop="true" data-tgt="reports-hr-ai-results-details-5" @click.native="onLinkClick"></screen-link>
    </div>
  </div>

  <div class="screen" data-screen="reports-hr-ai-results-details-5" :data-active="currentScreen=='reports-hr-ai-results-details-5'">
    <img class="w-full max-w-100" src="/img/screens/reports/reports-ai-popup-results-details-5.png?v=12222024" />
    <div class="links">
      <screen-link left="76.5%" top="10%" width="3%" height="4%"  dot-class="dot-left-center" data-tgt="reports-hr-insights-guidebooks" @click.native="onLinkClick"></screen-link>
      <screen-link left="57%" top="15%" width="9%" height="4%"  dot-class="dot-bottom-center" data-tgt-scrollTop="true" data-tgt="reports-hr-ai-results-details-3" @click.native="onLinkClick"></screen-link>
      <screen-link left="76.5%" top="20.5%" width="3%" height="4%"  dot-class="dot-left-center" data-tgt-scrollTop="true" data-tgt="reports-hr-ai-results-details-download" @click.native="onLinkClick"></screen-link>
    </div>
  </div>

  <div class="screen" data-screen="reports-hr-ai-results-details-download" :data-active="currentScreen=='reports-hr-ai-results-details-download'">
    <img class="w-full max-w-100" src="/img/screens/reports/reports-ai-popup-results-details-download.png?v=12222024" />
    <div class="links">
      <screen-link left="76.5%" top="10%" width="3%" height="4%"  dot-class="dot-left-center" data-tgt="reports-hr-insights-guidebooks" @click.native="onLinkClick"></screen-link>
    </div>
  </div>


  <div class="screen" data-screen="reports-hr-analytics-start" :data-active="currentScreen=='reports-hr-analytics-start'">
    <img class="w-full max-w-100" src="/img/screens/reports/reports-guidebooks-start.png?v=12222024" />
    <div class="links">

      <screen-link left="14.8%" top="14%" width="8.5%" height="4%"  dot-class="dot-right-center" data-tgt="reports-hr-analytics" @click.native="onLinkClick"></screen-link>
      <screen-link left="16%" top="26.5%" width="8.5%" height="4%"  dot-class="dot-left-center" data-tgt="reports-hr-insights-guidebooks" @click.native="onLinkClick"></screen-link>

      <screen-link left="90%" top="18%" width="9%" height="6%"  dot-class="dot-left-center" data-tgt="reports-hr-ai" @click.native="onLinkClick"></screen-link>



      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" :active-companies="{enterprise:true,pro:true}"/>

    </div>
  </div>

  <div class="screen" data-screen="reports-hr-analytics-diversity" :data-active="currentScreen=='reports-hr-analytics-diversity'">
    <img class="w-full max-w-100" src="/img/screens/reports/reports-guidebooks-diversity.png?v=12222024" />
    <div class="links">x

      <screen-link left="14.8%" top="14%" width="8.5%" height="4%"  dot-class="dot-right-center" data-tgt="reports-hr-analytics" @click.native="onLinkClick"></screen-link>
      <screen-link left="16%" top="26.5%" width="8.5%" height="4%"  dot-class="dot-left-center" data-tgt="reports-hr-insights-guidebooks" @click.native="onLinkClick"></screen-link>

      <screen-link left="90%" top="18%" width="9%" height="6%"  dot-class="dot-left-center" data-tgt="reports-hr-ai" @click.native="onLinkClick"></screen-link>

      <screen-link left="69%" top="65%" width="23.5%" height="17%"  dot-class="dot-left-center" data-tgt="reports-hr-analytics-pay-equity" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>


      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" :active-companies="{enterprise:true,pro:true}"/>

    </div>
  </div>

  <div class="screen" data-screen="reports-hr-analytics-mobility" :data-active="currentScreen=='reports-hr-analytics-mobility'">
    <img class="w-full max-w-100" src="/img/screens/reports/reports-guidebooks-mobility.png?v=12222024" />
    <div class="links">

      <screen-link left="14.8%" top="14%" width="8.5%" height="4%"  dot-class="dot-right-center" data-tgt="reports-hr-analytics" @click.native="onLinkClick"></screen-link>
      <screen-link left="16%" top="26.5%" width="8.5%" height="4%"  dot-class="dot-left-center" data-tgt="reports-hr-insights-guidebooks" @click.native="onLinkClick"></screen-link>

      <screen-link left="90%" top="18%" width="9%" height="6%"  dot-class="dot-left-center" data-tgt="reports-hr-ai" @click.native="onLinkClick"></screen-link>

      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" :active-companies="{enterprise:true,pro:true}"/>

    </div>
  </div>

  <div class="screen" data-screen="reports-hr-analytics-org" :data-active="currentScreen=='reports-hr-analytics-org'">
    <img class="w-full max-w-100" src="/img/screens/reports/reports-insights-guidebooks-retention.png?v=12222024" />
    <div class="links">

      <screen-link left="14.8%" top="14%" width="8.5%" height="4%"  dot-class="dot-right-center" data-tgt="reports-hr-analytics" @click.native="onLinkClick"></screen-link>
      <screen-link left="16%" top="26.5%" width="8.5%" height="4%"  dot-class="dot-left-center" data-tgt="reports-hr-insights-guidebooks" @click.native="onLinkClick"></screen-link>

      <screen-link left="90%" top="18%" width="9%" height="6%"  dot-class="dot-left-center" data-tgt="reports-hr-ai" @click.native="onLinkClick"></screen-link>

      <screen-link left="70.25%" top="65.25%" width="23%" height="14%"  dot-class="dot-top-center" data-tgt="reports-hr-analytics-attrition" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
      <screen-link left="70.25%" top="82.5%" width="23%" height="18%"  dot-class="dot-top-center" data-tgt="reports-hr-analytics-headcount" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>

      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" :active-companies="{enterprise:true,pro:true}"/>

    </div>
  </div>

  <div class="screen" data-screen="reports-hr-analytics-attrition" :data-active="currentScreen=='reports-hr-analytics-attrition'">
    <img class="w-full max-w-100" src="/img/screens/reports/reports-hr-analytics-understandingattrition.png?v=12222024" />
    <div class="links">

      <screen-link left="89.5%" top="18%" width="9.25%" height="4.5%"  dot-class="dot-left-center" data-tgt="reports-hr-ai" @click.native="onLinkClick"></screen-link>

      <screen-link left="15%" top="13.75%" width="11%" height="4%"  dot-class="dot-left-center" data-tgt="reports-hr-analytics-org" @click.native="onLinkClick"></screen-link>

      <screen-link left="52%" top="132%" width="32.5%" height="42%"  dot-class="dot-left-center" data-tgt="reports-hr-analytics-exit-rate-details" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>

      <screen-link left="29%" top="305.5%" width="56%" height="44%"  dot-class="dot-left-center" data-tgt="reports-hr-analytics-headcount" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>

      <div class="hover-area" style="position: absolute;top: 132%; left: 52%;height: 42%; width: 32.5%;" @click="onLinkClick" data-tgt-scrolltop="true"  data-tgt="reports-hr-analytics-exit-rate-details">
        <img style="position: absolute; left: 40%; top: 15%; width: 34.3%; pointer-events: none;" src="/img/screens/reports/reports-exit-rate-hover.png" />
      </div>

      <div class="hover-area" style="position: absolute;top: 305.5%; left: 29%;height: 64%; width: 56%;" @click="onLinkClick" data-tgt-scrolltop="true"  data-tgt="reports-hr-analytics-attrition-details">
        <img style="position: absolute; left: 15%; top: 15%; width: 20.12%; pointer-events: none;" src="/img/screens/reports/reports-hr-attrition-hover.png" />
      </div>
      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" :active-companies="{enterprise:true,pro:true}"/>
    </div>
  </div>

  <div class="screen" data-screen="reports-hr-analytics-exit-rate-details" :data-active="currentScreen=='reports-hr-analytics-exit-rate-details'">
    <img class="w-full max-w-100" src="/img/screens/reports/reports-HR-analytics-understandingattrition-exit-rate-about.png?v=12222024" />
    <div class="links">

      <screen-link left="89.5%" top="18%" width="9.25%" height="4.5%"  dot-class="dot-left-center" data-tgt="reports-hr-ai" @click.native="onLinkClick"></screen-link>

      <screen-link left="15%" top="13.75%" width="11%" height="4%"  dot-class="dot-left-center" data-tgt="reports-hr-analytics-org" @click.native="onLinkClick"></screen-link>

      <screen-link left="28%" top="28%" width="2.5%" height="4%"  dot-class="dot-left-center" data-tgt="reports-hr-analytics-attrition" @click.native="onLinkClick"></screen-link>
      <screen-link left="75%" top="26%" width="9%" height="4%"  dot-class="dot-bottom-center" data-tgt="reports-hr-analytics-exit-rate-insights" @click.native="onLinkClick"></screen-link>

      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" :active-companies="{enterprise:true,pro:true}"/>
    </div>
  </div>

  <div class="screen" data-screen="reports-hr-analytics-exit-rate-insights" :data-active="currentScreen=='reports-hr-analytics-exit-rate-insights'">
    <img class="w-full max-w-100" src="/img/screens/reports/reports-HR-analytics-understandingattrition-exit-rate-insights.png?v=12222024" />
    <div class="links">

      <screen-link left="89.5%" top="18%" width="9.25%" height="4.5%"  dot-class="dot-left-center" data-tgt="reports-hr-ai" @click.native="onLinkClick"></screen-link>

      <screen-link left="15%" top="13.75%" width="11%" height="4%"  dot-class="dot-left-center" data-tgt="reports-hr-analytics-org" @click.native="onLinkClick"></screen-link>

      <screen-link left="28%" top="28%" width="2.5%" height="4%"  dot-class="dot-left-center" data-tgt="reports-hr-analytics-attrition" @click.native="onLinkClick"></screen-link>
      <screen-link left="66%" top="26%" width="8.5%" height="4%"  dot-class="dot-bottom-center" data-tgt="reports-hr-analytics-exit-rate-details" @click.native="onLinkClick"></screen-link>
      <screen-link left="67%" top="35.5%" width="13.5%" height="4%"  dot-class="dot-bottom-center" data-tgt="reports-hr-analytics-exit-rate-insights-details" @click.native="onLinkClick"></screen-link>

      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" :active-companies="{enterprise:true,pro:true}"/>
    </div>
  </div>

  <div class="screen" data-screen="reports-hr-analytics-exit-rate-insights-details" :data-active="currentScreen=='reports-hr-analytics-exit-rate-insights-details'">
    <img class="w-full max-w-100" src="/img/screens/reports/reports-HR-analytics-understandingattrition-exit-rate-insights-details.png?v=12222024" />
    <div class="links">

      <screen-link left="89.5%" top="18%" width="9.25%" height="4.5%"  dot-class="dot-left-center" data-tgt="reports-hr-ai" @click.native="onLinkClick"></screen-link>
      <screen-link left="15%" top="13.75%" width="11%" height="4%"  dot-class="dot-left-center" data-tgt="reports-hr-analytics-org" @click.native="onLinkClick"></screen-link>

      <screen-link left="28%" top="28%" width="3%" height="5%"  dot-class="dot-top-center" data-tgt="reports-hr-analytics-exit-rate-insights" @click.native="onLinkClick"></screen-link>


      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" :active-companies="{enterprise:true,pro:true}"/>
    </div>
  </div>

  <div class="screen" data-screen="reports-hr-analytics-attrition-details" :data-active="currentScreen=='reports-hr-analytics-attrition-details'">
    <img class="w-full max-w-100" src="/img/screens/reports/reports-hr-analytics-understandingattrition-drivers-about.png?v=12222024" />
    <div class="links">

      <screen-link left="89.5%" top="18%" width="9.25%" height="4.5%"  dot-class="dot-left-center" data-tgt="reports-hr-ai" @click.native="onLinkClick"></screen-link>

      <screen-link left="15%" top="13.75%" width="11%" height="4%"  dot-class="dot-left-center" data-tgt="reports-hr-analytics-org" @click.native="onLinkClick"></screen-link>

      <screen-link left="28%" top="28%" width="2.5%" height="4%"  dot-class="dot-left-center" data-tgt="reports-hr-analytics-attrition" @click.native="onLinkClick"></screen-link>
      <screen-link left="75%" top="26%" width="9%" height="4%"  dot-class="dot-bottom-center" data-tgt="reports-hr-analytics-attrition-insights" @click.native="onLinkClick"></screen-link>

      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" :active-companies="{enterprise:true,pro:true}"/>
    </div>
  </div>

  <div class="screen" data-screen="reports-hr-analytics-attrition-insights" :data-active="currentScreen=='reports-hr-analytics-attrition-insights'">
    <img class="w-full max-w-100" src="/img/screens/reports/reports-hr-analytics-understandingattrition-drivers-insights.png?v=12222024" />
    <div class="links">

      <screen-link left="89.5%" top="18%" width="9.25%" height="4.5%"  dot-class="dot-left-center" data-tgt="reports-hr-ai" @click.native="onLinkClick"></screen-link>

      <screen-link left="15%" top="13.75%" width="11%" height="4%"  dot-class="dot-left-center" data-tgt="reports-hr-analytics-org" @click.native="onLinkClick"></screen-link>

      <screen-link left="28%" top="28%" width="2.5%" height="4%"  dot-class="dot-left-center" data-tgt="reports-hr-analytics-attrition" @click.native="onLinkClick"></screen-link>
      <screen-link left="66%" top="26%" width="8.5%" height="4%"  dot-class="dot-bottom-center" data-tgt="reports-hr-analytics-attrition-details" @click.native="onLinkClick"></screen-link>
      <screen-link left="67%" top="37.5%" width="13.5%" height="4%"  dot-class="dot-bottom-center" data-tgt="reports-hr-analytics-attrition-insights-details" @click.native="onLinkClick"></screen-link>

      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" :active-companies="{enterprise:true,pro:true}"/>
    </div>
  </div>

  <div class="screen" data-screen="reports-hr-analytics-attrition-insights-details" :data-active="currentScreen=='reports-hr-analytics-attrition-insights-details'">
    <img class="w-full max-w-100" src="/img/screens/reports/reports-hr-analytics-understandingattrition-drivers-insights-detailed.png?v=12222024" />
    <div class="links">

      <screen-link left="89.5%" top="18%" width="9.25%" height="4.5%"  dot-class="dot-left-center" data-tgt="reports-hr-ai" @click.native="onLinkClick"></screen-link>

      <screen-link left="15%" top="13.75%" width="11%" height="4%"  dot-class="dot-left-center" data-tgt="reports-hr-analytics-org" @click.native="onLinkClick"></screen-link>

      <screen-link left="27.75%" top="28%" width="3%" height="5%"  dot-class="dot-top-center" data-tgt="reports-hr-analytics-attrition-insights" @click.native="onLinkClick"></screen-link>


      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" :active-companies="{enterprise:true,pro:true}"/>
    </div>
  </div>

  <div class="screen" data-screen="reports-hr-analytics-headcount" :data-active="currentScreen=='reports-hr-analytics-headcount'">
    <img class="w-full max-w-100" src="/img/screens/reports/reports-headcount-forecasting-main.png?v=12222024" />
    <div class="links">

      <screen-link left="15%" top="13.75%" width="8%" height="4%"  dot-class="dot-right-center" data-tgt="reports-hr-analytics" @click.native="onLinkClick"></screen-link>
      <screen-link left="16%" top="26.5%" width="11%" height="4%"  dot-class="dot-left-center" data-tgt="reports-hr-analytics-org" @click.native="onLinkClick"></screen-link>

      <screen-link left="89.5%" top="19%" width="9.25%" height="4%"  dot-class="dot-left-center" data-tgt="reports-hr-ai" @click.native="onLinkClick"></screen-link>

      <screen-link left="29%" top="126%" width="56%" height="64%"  dot-class="dot-left-center" data-tgt="reports-hr-analytics-headcount-details" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
      <screen-link left="29%" top="216%" width="56%" height="64%"  dot-class="dot-left-center" data-tgt="reports-hr-analytics-employee-starts" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>

      <div class="hover-area" style="position: absolute;top: 126%; left: 29%;height: 64%; width: 56%;" @click="onLinkClick" data-tgt-scrolltop="true"  data-tgt="reports-hr-analytics-headcount-details">
        <img style="position: absolute; left: 40%; top: 15%; width: 20.12%; pointer-events: none;" src="/img/screens/reports/reports-hr-analytics-project-headcount-hover.png" />
      </div>

      <div class="hover-area" style="position: absolute;top: 216%; left: 29%;height: 64%; width: 56%;" @click="onLinkClick" data-tgt-scrolltop="true"  data-tgt="reports-hr-analytics-employee-starts-details">
        <img style="position: absolute; left: 40%; top: 15%; width: 20.12%; pointer-events: none;" src="/img/screens/reports/reports-hr-analytics-employee-starts-hover.png" />
      </div>

      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" :active-companies="{enterprise:true,pro:true}"/>

    </div>
  </div>

  <div class="screen" data-screen="reports-hr-analytics-headcount-details" :data-active="currentScreen=='reports-hr-analytics-headcount-details'">
    <img class="w-full max-w-100" src="/img/screens/reports/reports-headcount-forecasting-projected-headcount-about.png?v=12222024" />
    <div class="links">
      <screen-link left="89.5%" top="18%" width="9.25%" height="4.5%"  dot-class="dot-left-center" data-tgt="reports-hr-ai" @click.native="onLinkClick"></screen-link>
      <screen-link left="15%" top="13.75%" width="11%" height="4%"  dot-class="dot-left-center" data-tgt="reports-hr-analytics-org" @click.native="onLinkClick"></screen-link>

      <screen-link left="28%" top="28%" width="2.5%" height="4%"  dot-class="dot-left-center" data-tgt="reports-hr-analytics-headcount" @click.native="onLinkClick"></screen-link>
      <screen-link left="75%" top="26%" width="9%" height="4%"  dot-class="dot-bottom-center" data-tgt="reports-hr-analytics-headcount-insights" @click.native="onLinkClick"></screen-link>

      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" :active-companies="{enterprise:true,pro:true}"/>

    </div>
  </div>

  <div class="screen" data-screen="reports-hr-analytics-headcount-insights" :data-active="currentScreen=='reports-hr-analytics-headcount-insights'">
    <img class="w-full max-w-100" src="/img/screens/reports/reports-hr-analytics-headcount-insights-minimized.png?v=12222024" />
    <div class="links">
      <screen-link left="89.5%" top="18%" width="9.25%" height="4.5%"  dot-class="dot-left-center" data-tgt="reports-hr-ai" @click.native="onLinkClick"></screen-link>
      <screen-link left="15%" top="13.75%" width="11%" height="4%"  dot-class="dot-left-center" data-tgt="reports-hr-analytics-org" @click.native="onLinkClick"></screen-link>

      <screen-link left="28%" top="28%" width="2.5%" height="4%"  dot-class="dot-left-center" data-tgt="reports-hr-analytics-headcount" @click.native="onLinkClick"></screen-link>
      <screen-link left="66%" top="26%" width="9%" height="4%"  dot-class="dot-bottom-center" data-tgt="reports-hr-analytics-headcount-details" @click.native="onLinkClick"></screen-link>

      <screen-link left="67%" top="40.5%" width="16%" height="4%"  dot-class="dot-right-center" data-tgt="reports-hr-analytics-headcount-insights-expanded" @click.native="onLinkClick"></screen-link>

      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" :active-companies="{enterprise:true,pro:true}"/>

    </div>
  </div>

  <div class="screen" data-screen="reports-hr-analytics-headcount-insights-more-actions" :data-active="currentScreen=='reports-hr-analytics-headcount-insights-more-actions'">
    <img class="w-full max-w-100" src="/img/screens/reports/reports-hr-analytics-headcount-insights-more-actions.png?v=12222024" />
    <div class="links">
      <screen-link left="89.5%" top="18%" width="9.25%" height="4.5%"  dot-class="dot-left-center" data-tgt="reports-hr-ai" @click.native="onLinkClick"></screen-link>
      <screen-link left="15%" top="13.75%" width="11%" height="4%"  dot-class="dot-left-center" data-tgt="reports-hr-analytics-org" @click.native="onLinkClick"></screen-link>

      <screen-link left="28%" top="28%" width="2.5%" height="4%"  dot-class="dot-left-center" data-tgt="reports-hr-analytics-headcount" @click.native="onLinkClick"></screen-link>
      <screen-link left="66%" top="26%" width="9%" height="4%"  dot-class="dot-bottom-center" data-tgt="reports-hr-analytics-headcount-details" @click.native="onLinkClick"></screen-link>

      <screen-link left="77.25%" top="34.25%" width="9%" height="4%"  dot-class="dot-right-center" data-tgt="reports-hr-analytics-headcount-insights-expanded" @click.native="onLinkClick"></screen-link>

      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" :active-companies="{enterprise:true,pro:true}"/>

    </div>
  </div>

  <div class="screen" data-screen="reports-hr-analytics-headcount-insights-expanded" :data-active="currentScreen=='reports-hr-analytics-headcount-insights-expanded'">
    <img class="w-full max-w-100" src="/img/screens/reports/reports-headcount-forecasting-projected-headcount-insights.png?v=12222024" />
    <div class="links">
      <screen-link left="89.5%" top="18%" width="9.25%" height="4.5%"  dot-class="dot-left-center" data-tgt="reports-hr-ai" @click.native="onLinkClick"></screen-link>
      <screen-link left="15%" top="13.75%" width="11%" height="4%"  dot-class="dot-left-center" data-tgt="reports-hr-analytics-org" @click.native="onLinkClick"></screen-link>

      <screen-link left="28%" top="28%" width="2.5%" height="4%"  dot-class="dot-left-center" data-tgt="reports-hr-analytics-headcount" @click.native="onLinkClick"></screen-link>
      <screen-link left="66%" top="26%" width="9%" height="4%"  dot-class="dot-bottom-center" data-tgt="reports-hr-analytics-headcount-details" @click.native="onLinkClick"></screen-link>

      <screen-link left="67%" top="40.5%" width="16%" height="4%"  dot-class="dot-right-center" data-tgt="reports-hr-analytics-headcount-insights" @click.native="onLinkClick"></screen-link>

      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" :active-companies="{enterprise:true,pro:true}"/>

    </div>
  </div>

  <div class="screen" data-screen="reports-hr-analytics-employee-starts-details" :data-active="currentScreen=='reports-hr-analytics-employee-starts-details'">
    <img class="w-full max-w-100" src="/img/screens/reports/reports-headcount-forecasting-employee-starts-about.png?v=12222024" />
    <div class="links">

      <screen-link left="15%" top="13.75%" width="11%" height="4%"  dot-class="dot-left-center" data-tgt="reports-hr-analytics-org" @click.native="onLinkClick"></screen-link>

      <screen-link left="28%" top="28%" width="2.5%" height="4%"  dot-class="dot-left-center" data-tgt="reports-hr-analytics-headcount" @click.native="onLinkClick"></screen-link>
      <screen-link left="75%" top="26%" width="9%" height="4%"  dot-class="dot-bottom-center" data-tgt="reports-hr-analytics-employee-starts-insights" @click.native="onLinkClick"></screen-link>

      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" :active-companies="{enterprise:true,pro:true}"/>

    </div>
  </div>

  <div class="screen" data-screen="reports-hr-analytics-employee-starts-insights" :data-active="currentScreen=='reports-hr-analytics-employee-starts-insights'">
    <img class="w-full max-w-100" src="/img/screens/reports/reports-headcount-forecasting-employee-starts-insights.png?v=12222024" />
    <div class="links">

      <screen-link left="15%" top="13.75%" width="11%" height="4%"  dot-class="dot-left-center" data-tgt="reports-hr-analytics-org" @click.native="onLinkClick"></screen-link>

      <screen-link left="28%" top="28%" width="2.5%" height="4%"  dot-class="dot-left-center" data-tgt="reports-hr-analytics-headcount" @click.native="onLinkClick"></screen-link>
      <screen-link left="66%" top="26%" width="9%" height="4%"  dot-class="dot-bottom-center" data-tgt="reports-hr-analytics-employee-starts-details" @click.native="onLinkClick"></screen-link>

      <screen-link left="67%" top="35.5%" width="13.5%" height="4%"  dot-class="dot-bottom-center" data-tgt="reports-hr-analytics-employee-starts-insights-details" @click.native="onLinkClick"></screen-link>

      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" :active-companies="{enterprise:true,pro:true}"/>

    </div>
  </div>

  <div class="screen" data-screen="reports-hr-analytics-employee-starts-insights-details" :data-active="currentScreen=='reports-hr-analytics-employee-starts-insights-details'">
    <img class="w-full max-w-100" src="/img/screens/reports/reports-headcount-forecasting-employee-starts-insights-view-details.png?v=12222024" />
    <div class="links">

      <screen-link left="15%" top="13.75%" width="11%" height="4%"  dot-class="dot-left-center" data-tgt="reports-hr-analytics-org" @click.native="onLinkClick"></screen-link>

      <screen-link left="28%" top="28%" width="3%" height="5%"  dot-class="dot-top-center" data-tgt="reports-hr-analytics-employee-starts-insights" @click.native="onLinkClick"></screen-link>


      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" :active-companies="{enterprise:true,pro:true}"/>
    </div>
  </div>

  <div class="screen" data-screen="reports-hr-analytics-comp" :data-active="currentScreen=='reports-hr-analytics-comp'">
    <img class="w-full max-w-100" src="/img/screens/reports/reports-guidebooks-compensation.png?v=12222024" />
    <div class="links">

      <screen-link left="14.8%" top="14%" width="8.5%" height="4%"  dot-class="dot-right-center" data-tgt="reports-hr-analytics" @click.native="onLinkClick"></screen-link>
      <screen-link left="16%" top="26.5%" width="8.5%" height="4%"  dot-class="dot-left-center" data-tgt="reports-hr-insights-guidebooks" @click.native="onLinkClick"></screen-link>

      <screen-link left="90%" top="18%" width="9%" height="6%"  dot-class="dot-left-center" data-tgt="reports-hr-ai" @click.native="onLinkClick"></screen-link>

      <screen-link left="44%" top="85%" width="24%" height="16%"  dot-class="dot-left-center" data-tgt="reports-hr-analytics-actual-compensation" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>


      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" :active-companies="{enterprise:true,pro:true}"/>

    </div>
  </div>


  <div class="screen" data-screen="reports-hr-analytics-pay-equity" :data-active="currentScreen=='reports-hr-analytics-pay-equity'">
    <img class="w-full max-w-100" src="/img/screens/reports/reports-pay-equity-main-1.png?v=12222024" />
    <div class="links">
      <screen-link left="89.5%" top="18%" width="9.25%" height="4.5%"  dot-class="dot-left-center" data-tgt="reports-hr-ai" @click.native="onLinkClick"></screen-link>
      <screen-link left="15%" top="13.75%" width="11%" height="4%"  dot-class="dot-left-center" data-tgt="reports-hr-analytics-diversity" @click.native="onLinkClick"></screen-link>

      <screen-link left="36%" top="108.5%" width="3%" height="4%"  dot-class="dot-right-center" data-tgt="reports-hr-analytics-pay-equity-2" @click.native="onLinkClick"></screen-link>

      <screen-link left="29%" top="114%" width="56%" height="69%"  dot-class="dot-left-center" data-tgt="reports-hr-analytics-pay-equity-1-about" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>

      <div class="hover-area" style="position: absolute;top: 114%; left: 29%;height: 69%; width: 56%;" @click="onLinkClick" data-tgt-scrolltop="true"  data-tgt="reports-hr-analytics-pay-equity-1-about">
        <img style="position: absolute; left: 60%; top: 45%; width: 20.12%; pointer-events: none;" src="/img/screens/reports/reports-pay-equity-main-1-hover.png" />
      </div>

      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" :active-companies="{enterprise:true,pro:true}"/>

    </div>
  </div>

  <div class="screen" data-screen="reports-hr-analytics-pay-equity-1-about" :data-active="currentScreen=='reports-hr-analytics-pay-equity-1-about'">
    <img class="w-full max-w-100" src="/img/screens/reports/reports-pay-equity-breakdown-by-labor-cost-about.png?v=12222024" />
    <div class="links">
      <screen-link left="89.5%" top="18%" width="9.25%" height="4.5%"  dot-class="dot-left-center" data-tgt="reports-hr-ai" @click.native="onLinkClick"></screen-link>
      <screen-link left="15%" top="13.75%" width="11%" height="4%"  dot-class="dot-left-center" data-tgt="reports-hr-analytics-diversity" @click.native="onLinkClick"></screen-link>

      <screen-link left="28.5%" top="28.5%" width="2.5%" height="4%"  dot-class="dot-left-center" data-tgt="reports-hr-analytics-pay-equity" @click.native="onLinkClick"></screen-link>
      <screen-link left="75%" top="26%" width="9%" height="4%"  dot-class="dot-bottom-center" data-tgt="reports-hr-analytics-pay-equity-1-insights" @click.native="onLinkClick"></screen-link>

      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" :active-companies="{enterprise:true,pro:true}"/>

    </div>
  </div>

  <div class="screen" data-screen="reports-hr-analytics-pay-equity-1-insights" :data-active="currentScreen=='reports-hr-analytics-pay-equity-1-insights'">
    <img class="w-full max-w-100" src="/img/screens/reports/reports-pay-equity-breakdown-by-labor-cost-insights.png?v=12222024" />
    <div class="links">
      <screen-link left="89.5%" top="18%" width="9.25%" height="4.5%"  dot-class="dot-left-center" data-tgt="reports-hr-ai" @click.native="onLinkClick"></screen-link>
      <screen-link left="15%" top="13.75%" width="11%" height="4%"  dot-class="dot-left-center" data-tgt="reports-hr-analytics-diversity" @click.native="onLinkClick"></screen-link>

      <screen-link left="28.5%" top="28.5%" width="2.5%" height="4%"  dot-class="dot-left-center" data-tgt="reports-hr-analytics-pay-equity" @click.native="onLinkClick"></screen-link>
      <screen-link left="66%" top="26%" width="9%" height="4%"  dot-class="dot-bottom-center" data-tgt="reports-hr-analytics-pay-equity-1-about" @click.native="onLinkClick"></screen-link>

      <screen-link left="67%" top="37.5%" width="13.5%" height="4%"  dot-class="dot-bottom-center" data-tgt="reports-hr-analytics-pay-equity-1-insights-details" @click.native="onLinkClick"></screen-link>

      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" :active-companies="{enterprise:true,pro:true}"/>

    </div>
  </div>

  <div class="screen" data-screen="reports-hr-analytics-pay-equity-1-insights-details" :data-active="currentScreen=='reports-hr-analytics-pay-equity-1-insights-details'">
    <img class="w-full max-w-100" src="/img/screens/reports/reports-pay-equity-breakdown-by-labor-cost-view-details.png?v=12222024" />
    <div class="links">
      <screen-link left="89.5%" top="18%" width="9.25%" height="4.5%"  dot-class="dot-left-center" data-tgt="reports-hr-ai" @click.native="onLinkClick"></screen-link>
      <screen-link left="15%" top="13.75%" width="11%" height="4%"  dot-class="dot-left-center" data-tgt="reports-hr-analytics-diversity" @click.native="onLinkClick"></screen-link>

      <screen-link left="28%" top="28%" width="2.5%" height="4%"  dot-class="dot-left-center" data-tgt="reports-hr-analytics-pay-equity" @click.native="onLinkClick"></screen-link>




      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" :active-companies="{enterprise:true,pro:true}"/>

    </div>
  </div>

  <div class="screen" data-screen="reports-hr-analytics-pay-equity-1-insights-details-1a" :data-active="currentScreen=='reports-hr-analytics-pay-equity-1-insights-details-1a'">
    <img class="w-full max-w-100" src="/img/screens/reports/reports-pay-equity-breakdown-by-labor-cost-view-details-1a.png?v=12222024" />
    <div class="links">
      <screen-link left="89.5%" top="18%" width="9.25%" height="4.5%"  dot-class="dot-left-center" data-tgt="reports-hr-ai" @click.native="onLinkClick"></screen-link>
      <screen-link left="15%" top="13.75%" width="11%" height="4%"  dot-class="dot-left-center" data-tgt="reports-hr-analytics-diversity" @click.native="onLinkClick"></screen-link>

      <screen-link left="28%" top="28%" width="2.5%" height="4%"  dot-class="dot-left-center" data-tgt="reports-hr-analytics-pay-equity" @click.native="onLinkClick"></screen-link>

      <screen-link left="76.5%" top="26%" width="7.5%" height="4%"  dot-class="dot-bottom-center" data-tgt="reports-hr-analytics-pay-equity-1-insights-details-1c" @click.native="onLinkClick"></screen-link>
      <screen-link left="69%" top="37%" width="15%" height="3%"  dot-class="dot-right-center" data-tgt="reports-hr-analytics-pay-equity-1-insights-details-1b" @click.native="onLinkClick"></screen-link>
      <screen-link left="84%" top="40.5%" width="3%" height="3%"  dot-class="dot-right-center" data-tgt="reports-hr-analytics-pay-equity-1-insights-details-1d" @click.native="onLinkClick"></screen-link>

      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" :active-companies="{enterprise:true,pro:true}"/>

    </div>
  </div>

  <div class="screen" data-screen="reports-hr-analytics-pay-equity-1-insights-details-1b" :data-active="currentScreen=='reports-hr-analytics-pay-equity-1-insights-details-1b'">
    <img class="w-full max-w-100" src="/img/screens/reports/reports-pay-equity-breakdown-by-labor-cost-view-details-1b.png?v=12222024" />
    <div class="links">
      <screen-link left="89.5%" top="18%" width="9.25%" height="4.5%"  dot-class="dot-left-center" data-tgt="reports-hr-ai" @click.native="onLinkClick"></screen-link>
      <screen-link left="15%" top="13.75%" width="11%" height="4%"  dot-class="dot-left-center" data-tgt="reports-hr-analytics-diversity" @click.native="onLinkClick"></screen-link>

      <screen-link left="28%" top="28%" width="2.5%" height="4%"  dot-class="dot-left-center" data-tgt="reports-hr-analytics-pay-equity" @click.native="onLinkClick"></screen-link>

      <screen-link left="76.5%" top="26%" width="7.5%" height="4%"  dot-class="dot-bottom-center" data-tgt="reports-hr-analytics-pay-equity-1-insights-details-1c" @click.native="onLinkClick"></screen-link>
      <screen-link left="69%" top="37%" width="15%" height="3%"  dot-class="dot-right-center" data-tgt="reports-hr-analytics-pay-equity-1-insights-details-1a" @click.native="onLinkClick"></screen-link>
      <screen-link left="84%" top="40.5%" width="3%" height="3%"  dot-class="dot-right-center" data-tgt="reports-hr-analytics-pay-equity-1-insights-details-1d" @click.native="onLinkClick"></screen-link>

      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" :active-companies="{enterprise:true,pro:true}"/>

    </div>
  </div>

  <div class="screen" data-screen="reports-hr-analytics-pay-equity-1-insights-details-1c" :data-active="currentScreen=='reports-hr-analytics-pay-equity-1-insights-details-1c'">
    <img class="w-full max-w-100" src="/img/screens/reports/reports-pay-equity-breakdown-by-labor-cost-view-details-1c.png?v=12222024" />
    <div class="links">
      <screen-link left="89.5%" top="18%" width="9.25%" height="4.5%"  dot-class="dot-left-center" data-tgt="reports-hr-ai" @click.native="onLinkClick"></screen-link>
      <screen-link left="15%" top="13.75%" width="11%" height="4%"  dot-class="dot-left-center" data-tgt="reports-hr-analytics-diversity" @click.native="onLinkClick"></screen-link>

      <screen-link left="28%" top="28%" width="2.5%" height="4%"  dot-class="dot-left-center" data-tgt="reports-hr-analytics-pay-equity" @click.native="onLinkClick"></screen-link>

      <screen-link left="68.5%" top="26%" width="7.5%" height="4%"  dot-class="dot-bottom-center" data-tgt="reports-hr-analytics-pay-equity-1-insights-details-1a" @click.native="onLinkClick"></screen-link>
      <screen-link left="84%" top="40.5%" width="3%" height="3%"  dot-class="dot-right-center" data-tgt="reports-hr-analytics-pay-equity-1-insights-details-1d" @click.native="onLinkClick"></screen-link>

      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" :active-companies="{enterprise:true,pro:true}"/>

    </div>
  </div>

  <div class="screen" data-screen="reports-hr-analytics-pay-equity-1-insights-details-1d" :data-active="currentScreen=='reports-hr-analytics-pay-equity-1-insights-details-1d'">
    <img class="w-full max-w-100" src="/img/screens/reports/reports-pay-equity-breakdown-by-labor-cost-view-details-1d.png?v=12222024" />
    <div class="links">
      <screen-link left="89.5%" top="18%" width="9.25%" height="4.5%"  dot-class="dot-left-center" data-tgt="reports-hr-ai" @click.native="onLinkClick"></screen-link>
      <screen-link left="15%" top="13.75%" width="11%" height="4%"  dot-class="dot-left-center" data-tgt="reports-hr-analytics-diversity" @click.native="onLinkClick"></screen-link>

      <screen-link left="28%" top="28%" width="2.5%" height="4%"  dot-class="dot-left-center" data-tgt="reports-hr-analytics-pay-equity" @click.native="onLinkClick"></screen-link>

      <screen-link left="76.5%" top="26%" width="7.5%" height="4%"  dot-class="dot-bottom-center" data-tgt="reports-hr-analytics-pay-equity-1-insights-details-1c" @click.native="onLinkClick"></screen-link>
      <screen-link left="68.5%" top="46.5%" width="15%" height="3%"  dot-class="dot-left-center" data-tgt="reports-hr-analytics-pay-equity-1-insights-details-1e" @click.native="onLinkClick"></screen-link>

      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" :active-companies="{enterprise:true,pro:true}"/>

    </div>
  </div>

  <div class="screen" data-screen="reports-hr-analytics-pay-equity-1-insights-details-1e" :data-active="currentScreen=='reports-hr-analytics-pay-equity-1-insights-details-1e'">
    <img class="w-full max-w-100" src="/img/screens/reports/reports-pay-equity-breakdown-by-labor-cost-view-details-1e.png?v=12222024" />
    <div class="links">
      <screen-link left="89.5%" top="18%" width="9.25%" height="4.5%"  dot-class="dot-left-center" data-tgt="reports-hr-ai" @click.native="onLinkClick"></screen-link>
      <screen-link left="15%" top="13.75%" width="11%" height="4%"  dot-class="dot-left-center" data-tgt="reports-hr-analytics-diversity" @click.native="onLinkClick"></screen-link>

      <screen-link left="28%" top="28%" width="2.5%" height="4%"  dot-class="dot-left-center" data-tgt="reports-hr-analytics-pay-equity" @click.native="onLinkClick"></screen-link>



      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" :active-companies="{enterprise:true,pro:true}"/>

    </div>
  </div>

  <div class="screen" data-screen="reports-hr-analytics-pay-equity-2" :data-active="currentScreen=='reports-hr-analytics-pay-equity-2'">
    <img class="w-full max-w-100" src="/img/screens/reports/reports-pay-equity-main-2.png?v=12222024" />
    <div class="links">
      <screen-link left="89.5%" top="18%" width="9.25%" height="4.5%"  dot-class="dot-left-center" data-tgt="reports-hr-ai" @click.native="onLinkClick"></screen-link>
      <screen-link left="15%" top="13.75%" width="11%" height="4%"  dot-class="dot-left-center" data-tgt="reports-hr-analytics-diversity" @click.native="onLinkClick"></screen-link>

      <screen-link left="29%" top="108.5%" width="3%" height="4%"  dot-class="dot-left-center" data-tgt="reports-hr-analytics-pay-equity" @click.native="onLinkClick"></screen-link>

      <screen-link left="29%" top="114%" width="56%" height="69%"  dot-class="dot-left-center" data-tgt="reports-hr-analytics-pay-equity-2-about" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>

      <div class="hover-area" style="position: absolute;top: 114%; left: 29%;height: 69%; width: 56%;" @click="onLinkClick" data-tgt-scrolltop="true"  data-tgt="reports-hr-analytics-pay-equity-2-about">
        <img style="position: absolute; left: 55%; top: 26%; width: 20.12%; pointer-events: none;" src="/img/screens/reports/reports-pay-equity-main-2-hover.png" />
      </div>

      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" :active-companies="{enterprise:true,pro:true}"/>

    </div>
  </div>

  <div class="screen" data-screen="reports-hr-analytics-pay-equity-2-about" :data-active="currentScreen=='reports-hr-analytics-pay-equity-2-about'">
    <img class="w-full max-w-100" src="/img/screens/reports/reports-pay-equity-breakdown-by-labor-cost-about-2.png?v=12222024" />
    <div class="links">
      <screen-link left="89.5%" top="18%" width="9.25%" height="4.5%"  dot-class="dot-left-center" data-tgt="reports-hr-ai" @click.native="onLinkClick"></screen-link>
      <screen-link left="15%" top="13.75%" width="11%" height="4%"  dot-class="dot-left-center" data-tgt="reports-hr-analytics-diversity" @click.native="onLinkClick"></screen-link>

      <screen-link left="28.5%" top="28.5%" width="2.5%" height="4%"  dot-class="dot-left-center" data-tgt="reports-hr-analytics-pay-equity-2" @click.native="onLinkClick"></screen-link>
      <screen-link left="75%" top="26%" width="9%" height="4%"  dot-class="dot-bottom-center" data-tgt="reports-hr-analytics-pay-equity-2-insights" @click.native="onLinkClick"></screen-link>

      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" :active-companies="{enterprise:true,pro:true}"/>

    </div>
  </div>

  <div class="screen" data-screen="reports-hr-analytics-pay-equity-2-insights" :data-active="currentScreen=='reports-hr-analytics-pay-equity-2-insights'">
    <img class="w-full max-w-100" src="/img/screens/reports/reports-pay-equity-breakdown-by-labor-cost-insights-2.png?v=12222024" />
    <div class="links">
      <screen-link left="89.5%" top="18%" width="9.25%" height="4.5%"  dot-class="dot-left-center" data-tgt="reports-hr-ai" @click.native="onLinkClick"></screen-link>
      <screen-link left="15%" top="13.75%" width="11%" height="4%"  dot-class="dot-left-center" data-tgt="reports-hr-analytics-diversity" @click.native="onLinkClick"></screen-link>

      <screen-link left="28.5%" top="28.5%" width="2.5%" height="4%"  dot-class="dot-left-center" data-tgt="reports-hr-analytics-pay-equity-2" @click.native="onLinkClick"></screen-link>
      <screen-link left="66%" top="26%" width="9%" height="4%"  dot-class="dot-bottom-center" data-tgt="reports-hr-analytics-pay-equity-2-about" @click.native="onLinkClick"></screen-link>

      <screen-link left="67%" top="37.5%" width="13.5%" height="4%"  dot-class="dot-bottom-center" data-tgt="reports-hr-analytics-pay-equity-2-insights-details" @click.native="onLinkClick"></screen-link>

      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" :active-companies="{enterprise:true,pro:true}"/>

    </div>
  </div>

  <div class="screen" data-screen="reports-hr-analytics-pay-equity-2-insights-details" :data-active="currentScreen=='reports-hr-analytics-pay-equity-2-insights-details'">
    <img class="w-full max-w-100" src="/img/screens/reports/reports-pay-equity-breakdown-by-labor-cost-view-details-2.png?v=12222024" />
    <div class="links">
      <screen-link left="89.5%" top="18%" width="9.25%" height="4.5%"  dot-class="dot-left-center" data-tgt="reports-hr-ai" @click.native="onLinkClick"></screen-link>
      <screen-link left="15%" top="13.75%" width="11%" height="4%"  dot-class="dot-left-center" data-tgt="reports-hr-analytics-diversity" @click.native="onLinkClick"></screen-link>

      <screen-link left="28%" top="28%" width="2.5%" height="4%"  dot-class="dot-left-center" data-tgt="reports-hr-analytics-pay-equity-2" @click.native="onLinkClick"></screen-link>



      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" :active-companies="{enterprise:true,pro:true}"/>

    </div>
  </div>

  <div class="screen" data-screen="reports-hr-analytics-actual-compensation" :data-active="currentScreen=='reports-hr-analytics-actual-compensation'">
    <img class="w-full max-w-100" src="/img/screens/reports/reports-actual-compensation-main.png?v=12222024" />
    <div class="links">
      <screen-link left="89.5%" top="18%" width="9.25%" height="4.5%"  dot-class="dot-left-center" data-tgt="reports-hr-ai" @click.native="onLinkClick"></screen-link>
      <screen-link left="15%" top="13.75%" width="11%" height="4%"  dot-class="dot-left-center" data-tgt="reports-hr-analytics-comp" @click.native="onLinkClick"></screen-link>

      <screen-link left="29%" top="136%" width="56%" height="64%"  dot-class="dot-left-center" data-tgt="reports-hr-analytics-labor-costs" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
      <screen-link left="29%" top="202%" width="56%" height="47%"  dot-class="dot-left-center" data-tgt="reports-hr-analytics-direct-compensation" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>

      <div class="hover-area" style="position: absolute;top: 136%; left: 29%;height: 64%; width: 56%;" @click="onLinkClick" data-tgt-scrolltop="true"  data-tgt="reports-hr-analytics-labor-costs-details">
        <img style="position: absolute; left: 40%; top: 15%; width: 20.12%; pointer-events: none;" src="/img/screens/reports/reports-compensation-breakdown-hover.png" />
      </div>

      <div class="hover-area" style="position: absolute;top: 202%; left: 29%;height: 47%; width: 56%;" @click="onLinkClick" data-tgt-scrolltop="true"  data-tgt="reports-hr-analytics-direct-compensation-details">
        <img style="position: absolute; left: 40%; top: 15%; width: 20.12%; pointer-events: none;" src="/img/screens/reports/reports-trend-breakdown-hover.png" />
      </div>

      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" :active-companies="{enterprise:true,pro:true}"/>

    </div>
  </div>

  <div class="screen" data-screen="reports-hr-analytics-labor-costs-details" :data-active="currentScreen=='reports-hr-analytics-labor-costs-details'">
    <img class="w-full max-w-100" src="/img/screens/reports/reports-actual-compensation-breakdown-by-labor-cost-about.png?v=12222024" />
    <div class="links">
      <screen-link left="89.5%" top="18%" width="9.25%" height="4.5%"  dot-class="dot-left-center" data-tgt="reports-hr-ai" @click.native="onLinkClick"></screen-link>
      <screen-link left="15%" top="13.75%" width="11%" height="4%"  dot-class="dot-left-center" data-tgt="reports-hr-analytics-comp" @click.native="onLinkClick"></screen-link>

      <screen-link left="28%" top="28%" width="2.5%" height="5%"  dot-class="dot-left-center" data-tgt="reports-hr-analytics-actual-compensation" @click.native="onLinkClick"></screen-link>
      <screen-link left="75%" top="26%" width="9%" height="4%"  dot-class="dot-bottom-center" data-tgt="reports-hr-analytics-labor-costs-insights" @click.native="onLinkClick"></screen-link>

      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" :active-companies="{enterprise:true,pro:true}"/>
    </div>
  </div>

  <div class="screen" data-screen="reports-hr-analytics-labor-costs-insights" :data-active="currentScreen=='reports-hr-analytics-labor-costs-insights'">
    <img class="w-full max-w-100" src="/img/screens/reports/reports-actual-compensation-breakdown-by-labor-cost-insights.png?v=12222024" />
    <div class="links">
      <screen-link left="89.5%" top="18%" width="9.25%" height="4.5%"  dot-class="dot-left-center" data-tgt="reports-hr-ai" @click.native="onLinkClick"></screen-link>
      <screen-link left="15%" top="13.75%" width="11%" height="4%"  dot-class="dot-left-center" data-tgt="reports-hr-analytics-comp" @click.native="onLinkClick"></screen-link>

      <screen-link left="28%" top="28%" width="2.5%" height="5%"  dot-class="dot-left-center" data-tgt="reports-hr-analytics-actual-compensation" @click.native="onLinkClick"></screen-link>
      <screen-link left="66%" top="26%" width="8.5%" height="4%"  dot-class="dot-bottom-center" data-tgt="reports-hr-analytics-labor-costs-details" @click.native="onLinkClick"></screen-link>
      <screen-link left="67%" top="37.5%" width="13.5%" height="4%"  dot-class="dot-bottom-center" data-tgt="reports-hr-analytics-labor-costs-insights-details" @click.native="onLinkClick"></screen-link>

      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" :active-companies="{enterprise:true,pro:true}"/>
    </div>
  </div>

  <div class="screen" data-screen="reports-hr-analytics-labor-costs-insights-details" :data-active="currentScreen=='reports-hr-analytics-labor-costs-insights-details'">
    <img class="w-full max-w-100" src="/img/screens/reports/reports-actual-compensation-breakdown-by-labor-cost-view-details.png?v=12222024" />
    <div class="links">
      <screen-link left="89.5%" top="18%" width="9.25%" height="4.5%"  dot-class="dot-left-center" data-tgt="reports-hr-ai" @click.native="onLinkClick"></screen-link>
      <screen-link left="15%" top="13.75%" width="11%" height="4%"  dot-class="dot-left-center" data-tgt="reports-hr-analytics-comp" @click.native="onLinkClick"></screen-link>

      <screen-link left="28%" top="28%" width="2.5%" height="6%"  dot-class="dot-top-center" data-tgt="reports-hr-analytics-labor-costs-insights" @click.native="onLinkClick"></screen-link>


      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" :active-companies="{enterprise:true,pro:true}"/>
    </div>
  </div>

  <div class="screen" data-screen="reports-hr-analytics-labor-costs-details" :data-active="currentScreen=='reports-hr-analytics-direct-compensation-details'">
    <img class="w-full max-w-100" src="/img/screens/reports/reports-actual-compensation-Direct-compensation-about.png?v=12222024" />
    <div class="links">
      <screen-link left="89.5%" top="18%" width="9.25%" height="4.5%"  dot-class="dot-left-center" data-tgt="reports-hr-ai" @click.native="onLinkClick"></screen-link>
      <screen-link left="15%" top="13.75%" width="11%" height="4%"  dot-class="dot-left-center" data-tgt="reports-hr-analytics-comp" @click.native="onLinkClick"></screen-link>

      <screen-link left="28%" top="28%" width="2.5%" height="5%"  dot-class="dot-left-center" data-tgt="reports-hr-analytics-actual-compensation" @click.native="onLinkClick"></screen-link>
      <screen-link left="75%" top="26%" width="9%" height="4%"  dot-class="dot-bottom-center" data-tgt="reports-hr-analytics-direct-compensation-insights" @click.native="onLinkClick"></screen-link>

      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" :active-companies="{enterprise:true,pro:true}"/>
    </div>
  </div>

  <div class="screen" data-screen="reports-hr-analytics-labor-costs-insights" :data-active="currentScreen=='reports-hr-analytics-direct-compensation-insights'">
    <img class="w-full max-w-100" src="/img/screens/reports/reports-actual-compensation-Direct-compensation-insight.png?v=12222024" />
    <div class="links">
      <screen-link left="89.5%" top="18%" width="9.25%" height="4.5%"  dot-class="dot-left-center" data-tgt="reports-hr-ai" @click.native="onLinkClick"></screen-link>
      <screen-link left="15%" top="13.75%" width="11%" height="4%"  dot-class="dot-left-center" data-tgt="reports-hr-analytics-comp" @click.native="onLinkClick"></screen-link>

      <screen-link left="28%" top="28%" width="2.5%" height="5%"  dot-class="dot-left-center" data-tgt="reports-hr-analytics-actual-compensation" @click.native="onLinkClick"></screen-link>
      <screen-link left="66%" top="26%" width="8.5%" height="4%"  dot-class="dot-bottom-center" data-tgt="reports-hr-analytics-direct-compensation-details" @click.native="onLinkClick"></screen-link>
      <screen-link left="67%" top="36%" width="13.5%" height="4%"  dot-class="dot-bottom-center" data-tgt="reports-hr-analytics-direct-compensation-insights-details" @click.native="onLinkClick"></screen-link>

      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" :active-companies="{enterprise:true,pro:true}"/>
    </div>
  </div>

  <div class="screen" data-screen="reports-hr-analytics-labor-costs-insights-details" :data-active="currentScreen=='reports-hr-analytics-direct-compensation-insights-details'">
    <img class="w-full max-w-100" src="/img/screens/reports/reports-actual-compensation-Direct-compensation-view-details.png?v=12222024" />
    <div class="links">
      <screen-link left="89.5%" top="18%" width="9.25%" height="4.5%"  dot-class="dot-left-center" data-tgt="reports-hr-ai" @click.native="onLinkClick"></screen-link>
      <screen-link left="15%" top="13.75%" width="11%" height="4%"  dot-class="dot-left-center" data-tgt="reports-hr-analytics-comp" @click.native="onLinkClick"></screen-link>

      <screen-link left="28%" top="28%" width="2.5%" height="6%"  dot-class="dot-top-center" data-tgt="reports-hr-analytics-direct-compensation-insights" @click.native="onLinkClick"></screen-link>


      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" :active-companies="{enterprise:true,pro:true}"/>
    </div>
  </div>

  <div class="screen" data-screen="reports-analytics" :data-active="currentScreen=='reports-analytics'">
    <img class="w-full max-w-100" src="/img/screens/reports/reports-analytics.png?v=12062023" />
    <div class="links">
      <screen-link left="14.8%" top="18%" width="5%" height="4%"  dot-class="dot-bottom-center" data-tgt="reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="49%" top="55%" width="4.5%" height="4%"  dot-class="dot-left-center" data-tgt="reports-labor-cost" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>

      <screen-link left="20%" top="18%" width="5%" height="4%"  dot-class="dot-bottom-center" data-tgt="reports-analytics" @click.native="onLinkClick"></screen-link>
      <screen-link left="25.5%" top="18%" width="6.5%" height="4%"  dot-class="dot-bottom-center" data-tgt="reports-hr-analytics" @click.native="onLinkClick"></screen-link>
      <screen-link left="53.5%" top="55%" width="2%" height="4%"  dot-class="dot-right-center" data-tgt="reports-analytics-edit-panel" @click.native="onOverlayClick"></screen-link>





     <report-analytics-edit-panel style="position:absolute;top: 52%;left: 50%;width: 6%;" v-if="$store.state.overlayPanel === 'reports-analytics-edit-panel'" @pullout="showPullout" @close="closeOverlay"></report-analytics-edit-panel>
      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" :active-companies="{enterprise:true,pro:true}"/>

    </div>
  </div>

  <div class="screen" data-screen="reports-analytics-total-labor-all" :data-active="currentScreen=='reports-analytics-total-labor-corporate'">
    <img class="w-full max-w-100" src="/img/screens/reports/admin-reports-analytics-total-labor-all.png?v=12062023" />
    <div class="links">
      <screen-link left="14.8%" top="18%" width="5%" height="4%"  dot-class="dot-bottom-center" data-tgt="reports" @click.native="onLinkClick"></screen-link>

      <screen-link left="20%" top="18%" width="5%" height="4%"  dot-class="dot-bottom-center" data-tgt="reports-analytics" @click.native="onLinkClick"></screen-link>
      <screen-link left="25.5%" top="18%" width="6.5%" height="4%"  dot-class="dot-bottom-center" data-tgt="reports-hr-analytics" @click.native="onLinkClick"></screen-link>
      <screen-link left="49%" top="55%" width="4.5%" height="4%"  dot-class="dot-left-center" data-tgt="reports-labor-cost" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
      <screen-link left="53.5%" top="55%" width="2%" height="4%"  dot-class="dot-right-center" data-tgt="reports-analytics-edit-panel" @click.native="onOverlayClick"></screen-link>



      <report-analytics-edit-panel style="position:absolute;top: 52%;left: 50%;width: 6%;" v-if="$store.state.overlayPanel === 'reports-analytics-edit-panel'" @pullout="showPullout" @close="closeOverlay"></report-analytics-edit-panel>

      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" :active-companies="{enterprise:true,pro:true}"/>
    </div>
  </div>



  <div class="screen" data-screen="reports-general-ledger" :data-active="currentScreen=='reports-general-ledger'">
    <img class="w-full max-w-100" src="/img/screens/reports/admin-reports-general-ledger-current-year.png?v=12222024" />
    <div class="links">
      <screen-link left="83%" top="19.5%" width="8%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="reportTemplate" @click.native="onOverlayClick"></screen-link>
      <screen-link left="91%" top="19.5%" width="8%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="downloadOptions" @click.native="onOverlayClick"></screen-link>

      <screen-link left="15%" top="37.5%" width="25%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="reportGeneralLedgerDateRange" @click.native="onOverlayClick"></screen-link>

      <screen-link left="15%" top="14%" width="11%" height="4%"  dot-class="dot-right-center" data-tgt="reports" @click.native="onLinkClick"></screen-link>

      <report-template-panel v-if="$store.state.overlayPanel === 'reportTemplate'" left="79%" top="16%" width="20%" @close="closeOverlay" @link="onLinkClick"></report-template-panel>
      <download-options-panel v-if="$store.state.overlayPanel === 'downloadOptions'" left="79%" top="16%" width="20%" @close="closeOverlay" @link="onLinkClick"></download-options-panel>

      <report-general-ledger-date-range-panel v-if="$store.state.overlayPanel === 'reportGeneralLedgerDateRange'" left="21%" top="38%" width="20%" @close="closeOverlay" @link="onLinkClick" selection="currentYear"></report-general-ledger-date-range-panel>

      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" />

    </div>
  </div>

  <div class="screen" data-screen="reports-general-ledger-previous-year" :data-active="currentScreen=='reports-general-ledger-previous-year'">
    <img class="w-full max-w-100" src="/img/screens/reports/admin-reports-general-ledger-previous-year.png?v=10122022" />
    <div class="links">
      <screen-link left="83%" top="19.5%" width="8%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="reportTemplate" @click.native="onOverlayClick"></screen-link>
      <screen-link left="91%" top="19.5%" width="8%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="downloadOptions" @click.native="onOverlayClick"></screen-link>

      <screen-link left="15%" top="37.5%" width="25%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="reportGeneralLedgerDateRange" @click.native="onOverlayClick"></screen-link>

      <screen-link left="15%" top="14%" width="11%" height="4%"  dot-class="dot-right-center" data-tgt="reports" @click.native="onLinkClick"></screen-link>

      <report-template-panel v-if="$store.state.overlayPanel === 'reportTemplate'" left="79%" top="16%" width="20%" @close="closeOverlay" @link="onLinkClick"></report-template-panel>
      <download-options-panel v-if="$store.state.overlayPanel === 'downloadOptions'" left="79%" top="16%" width="20%" @close="closeOverlay" @link="onLinkClick"></download-options-panel>

      <report-general-ledger-date-range-panel v-if="$store.state.overlayPanel === 'reportGeneralLedgerDateRange'" left="21%" top="38%" width="20%" @close="closeOverlay" @link="onLinkClick" selection="previousYear"></report-general-ledger-date-range-panel>

      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" />
    </div>
  </div>

  <div class="screen" data-screen="reports-labor-cost" :data-active="currentScreen=='reports-labor-cost'">
    <img class="w-full max-w-100" src="/img/screens/reports/admin-reports-labor-cost.png?v=10122022" />
    <div class="links">
      <screen-link left="77.1%" top="19.5%" width="6%" height="5%"  dot-class="dot-top-center" data-tgt="pullout-reports-share" @click.native="showPullout"></screen-link>
      <screen-link left="15%" top="14%" width="11%" height="4%"  dot-class="dot-right-center" data-tgt="reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="41%" top="73%" width="2%" height="4%"  dot-class="dot-right-center" data-tgt="overtimeOptions" @click.native="onOverlayClick"></screen-link>

      <screen-link left="83%" top="19.5%" width="8%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="reportTemplate" @click.native="onOverlayClick"></screen-link>
      <screen-link left="91%" top="19.5%" width="8%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="downloadOptions" @click.native="onOverlayClick"></screen-link>

      <screen-link left="83%" top="130%" width="6%" height="4%"  dot-class="dot-top-center" data-tgt="pullout-reports-columns" @click.native="showPullout"></screen-link>
      <report-template-panel v-if="$store.state.overlayPanel === 'reportTemplate'" left="79%" top="16%" width="20%" @close="closeOverlay" @link="onLinkClick"></report-template-panel>
      <download-options-panel v-if="$store.state.overlayPanel === 'downloadOptions'" left="79%" top="16%" width="20%" @close="closeOverlay" @link="onLinkClick"></download-options-panel>

      <overtime-options-panel v-if="$store.state.overlayPanel === 'overtimeOptions'" left="41%" top="71%" width="20%" @close="closeOverlay" @pullout="showPullout" @link="onLinkClick"></overtime-options-panel>
      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" />
    </div>
  </div>

  <div class="screen" data-screen="reports-labor-cost-overtime-by-supervisor" :data-active="currentScreen=='reports-labor-cost-overtime-by-supervisor'">
    <img class="w-full max-w-100" src="/img/screens/reports/admin-reports-labor-cost-overtime-by-supervisor.png?v=10122022" />
    <div class="links">
      <screen-link left="77.1%" top="19.5%" width="6%" height="5%"  dot-class="dot-top-center" data-tgt="pullout-reports-share" @click.native="showPullout"></screen-link>
      <screen-link left="15%" top="14%" width="11%" height="4%"  dot-class="dot-right-center" data-tgt="reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="41%" top="73%" width="2%" height="4%"  dot-class="dot-right-center" data-tgt="overtimeOptions" @click.native="onOverlayClick"></screen-link>
      <screen-link left="83%" top="19.5%" width="8%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="reportTemplate" @click.native="onOverlayClick"></screen-link>
      <screen-link left="91%" top="19.5%" width="8%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="downloadOptions" @click.native="onOverlayClick"></screen-link>
      <screen-link left="83%" top="130%" width="6%" height="4%"  dot-class="dot-top-center" data-tgt="pullout-reports-columns" @click.native="showPullout"></screen-link>
      <report-template-panel v-if="$store.state.overlayPanel === 'reportTemplate'" left="79%" top="16%" width="20%" @close="closeOverlay" @link="onLinkClick"></report-template-panel>
      <download-options-panel v-if="$store.state.overlayPanel === 'downloadOptions'" left="79%" top="16%" width="20%" @close="closeOverlay" @link="onLinkClick"></download-options-panel>

      <overtime-options-panel v-if="$store.state.overlayPanel === 'overtimeOptions'" left="41%" top="71%" width="20%" @close="closeOverlay" @pullout="showPullout" @link="onLinkClick" selection="Top supervisors"></overtime-options-panel>
      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" />
    </div>
  </div>

  <div class="screen" data-screen="reports-labor-cost-with-threshold" :data-active="currentScreen=='reports-labor-cost-with-threshold'">
    <img class="w-full max-w-100" src="/img/screens/reports/admin-reports-labor-cost-with-threshold.png?v=10122022" />

    <div class="links">
      <screen-link left="21.5%" top="92%" width="4%" height="8%" style="display:none"  dot-class="dot-top-center" data-tgt=""></screen-link>
      <screen-link left="77.1%" top="19.5%" width="6%" height="5%"  dot-class="dot-top-center" data-tgt="pullout-reports-share" @click.native="showPullout"></screen-link>
      <screen-link left="15%" top="14%" width="11%" height="4%"  dot-class="dot-right-center" data-tgt="reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="41%" top="73%" width="2%" height="4%"  dot-class="dot-right-center" data-tgt="overtimeOptions" @click.native="onOverlayClick"></screen-link>
      <screen-link left="83%" top="19.5%" width="8%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="reportTemplate" @click.native="onOverlayClick"></screen-link>
      <screen-link left="91%" top="19.5%" width="8%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="downloadOptions" @click.native="onOverlayClick"></screen-link>
      <screen-link left="83%" top="130%" width="6%" height="4%"  dot-class="dot-top-center" data-tgt="pullout-reports-columns" @click.native="showPullout"></screen-link>
      <report-template-panel v-if="$store.state.overlayPanel === 'reportTemplate'" left="79%" top="16%" width="20%" @close="closeOverlay" @link="onLinkClick"></report-template-panel>
      <download-options-panel v-if="$store.state.overlayPanel === 'downloadOptions'" left="79%" top="16%" width="20%" @close="closeOverlay" @link="onLinkClick"></download-options-panel>

      <overtime-options-panel v-if="$store.state.overlayPanel === 'overtimeOptions'" left="41%" top="71%" width="20%" @close="closeOverlay" @pullout="showPullout" @link="onLinkClick"></overtime-options-panel>
      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" />
    </div>
    <img class="reports-labor-cost-threshold-chart" style="display:none" src="/img/screens/reports/admin-reports-labor-cost-with-threshold-chart-small.png?v=10122022" />
  </div>

  <div class="screen" data-screen="reports-labor-cost-with-supervisors-threshold" :data-active="currentScreen=='reports-labor-cost-with-supervisors-threshold'">
    <img class="w-full max-w-100" src="/img/screens/reports/admin-reports-labor-cost-with-supervisors-threshold.png?v=10122022" />

    <div class="links">
      <screen-link left="21.5%" top="92%" width="4%" height="8%"  style="display:none"  dot-class="dot-top-center" data-tgt=""></screen-link>
      <screen-link left="77.1%" top="19.5%" width="6%" height="5%"  dot-class="dot-top-center" data-tgt="pullout-reports-share" @click.native="showPullout"></screen-link>
      <screen-link left="15%" top="14%" width="11%" height="4%"  dot-class="dot-right-center" data-tgt="reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="41%" top="73%" width="2%" height="4%"  dot-class="dot-right-center" data-tgt="overtimeOptions" @click.native="onOverlayClick"></screen-link>
      <screen-link left="83%" top="19.5%" width="8%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="reportTemplate" @click.native="onOverlayClick"></screen-link>
      <screen-link left="91%" top="19.5%" width="8%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="downloadOptions" @click.native="onOverlayClick"></screen-link>
      <screen-link left="83%" top="130%" width="6%" height="4%"  dot-class="dot-top-center" data-tgt="pullout-reports-columns" @click.native="showPullout"></screen-link>
      <report-template-panel v-if="$store.state.overlayPanel === 'reportTemplate'" left="79%" top="16%" width="20%" @close="closeOverlay" @link="onLinkClick"></report-template-panel>
      <download-options-panel v-if="$store.state.overlayPanel === 'downloadOptions'" left="79%" top="16%" width="20%" @close="closeOverlay" @link="onLinkClick"></download-options-panel>

      <overtime-options-panel v-if="$store.state.overlayPanel === 'overtimeOptions'" left="41%" top="71%" width="20%" @close="closeOverlay" @pullout="showPullout" @link="onLinkClick"></overtime-options-panel>
      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" />
    </div>
    <img class="reports-labor-cost-threshold-chart" style="display:none"  src="/img/screens/reports/admin-reports-labor-cost-with-supervisors-threshold-chart-small.png?v=10122022" />
  </div>


  <div class="screen" data-screen="reports-live-reports-document-management-expiration" :data-active="currentScreen=='reports-live-reports-document-management-expiration'">
    <img class="w-full max-w-100" src="/img/screens/reports/admin-reports-live-reports-document-management-expiration.png?v=10122022" />
    <div class="links">
      <screen-link left="15%" top="14%" width="11%" height="4%"  dot-class="dot-right-center" data-tgt="reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="94%" top="55.5%" width="3%" height="4%"  dot-class="dot-right-center" data-tgt="documentCountOptions" @click.native="onOverlayClick"></screen-link>

      <screen-link left="83%" top="19.5%" width="8%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="reportTemplate" @click.native="onOverlayClick"></screen-link>
      <screen-link left="91%" top="19.5%" width="8%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="downloadOptions" @click.native="onOverlayClick"></screen-link>

      <screen-link left="82.5%" top="98%" width="7%" height="4%"  dot-class="dot-top-center" data-tgt="pullout-reports-columns" @click.native="showPullout"></screen-link>
      <report-template-panel v-if="$store.state.overlayPanel === 'reportTemplate'" left="79%" top="16%" width="20%" @close="closeOverlay" @link="onLinkClick"></report-template-panel>
      <download-options-panel v-if="$store.state.overlayPanel === 'downloadOptions'" left="79%" top="16%" width="20%" @close="closeOverlay" @link="onLinkClick"></download-options-panel>





      <document-count-options-panel  v-if="$store.state.overlayPanel === 'documentCountOptions'"  left="79%" top="60%" width="20%" @close="closeOverlay" @link="onLinkClick"></document-count-options-panel>
      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" />
    </div>
  </div>

  <div class="screen" data-screen="reports-live-reports-document-management-expiration-location" :data-active="currentScreen=='reports-live-reports-document-management-expiration-location'">
    <img class="w-full max-w-100" src="/img/screens/reports/admin-reports-live-reports-document-management-expiration-location.png?v=10122022" />

    <div class="links">

        <img class="labor-cost-location-zoom" :style={opacity:(showLaborCostLocationZoom?1.0:0)} src="/img/screens/reports/admin-reports-live-reports-document-management-expiration-location-cleveland.png?v=10122022" />


      <screen-link left="15%" top="14%" width="11%" height="4%"  dot-class="dot-right-center" data-tgt="reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="94%" top="55.5%" width="3%" height="4%"  dot-class="dot-right-center" data-tgt="documentCountOptions" @click.native="onOverlayClick" ></screen-link>
      <screen-link left="83%" top="19.5%" width="8%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="reportTemplate" @click.native="onOverlayClick"></screen-link>
      <screen-link left="91%" top="19.5%" width="8%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="downloadOptions" @click.native="onOverlayClick"></screen-link>

      <screen-link left="17%" top="66%" width="60%" height="21%"  dot-class="dot-top-center" data-tgt="pullout-reports-columns" @click.native="(showLaborCostLocationZoom = !showLaborCostLocationZoom)"></screen-link>

      <screen-link left="82.5%" top="98%" width="7%" height="4%"  dot-class="dot-top-center" data-tgt="pullout-reports-columns" @click.native="showPullout"></screen-link>
      <report-template-panel v-if="$store.state.overlayPanel === 'reportTemplate'" left="79%" top="16%" width="20%" @close="closeOverlay" @link="onLinkClick"></report-template-panel>
      <download-options-panel v-if="$store.state.overlayPanel === 'downloadOptions'" left="79%" top="16%" width="20%" @close="closeOverlay" @link="onLinkClick"></download-options-panel>

      <document-count-options-panel  v-if="$store.state.overlayPanel === 'documentCountOptions'" left="79%" top="60%" width="20%" @close="closeOverlay" @link="onLinkClick" selection="Worker location"></document-count-options-panel>
      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" />
    </div>
  </div>

  <div class="screen" data-screen="reports-pay-benchmarking" :data-active="currentScreen=='reports-pay-benchmarking'">
    <img class="w-full max-w-100" src="/img/screens/reports/admin-reports-pay-benchmarking.png?v=10122022" />
    <div class="links">
      <screen-link left="15%" top="13.5%" width="11%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="reports" @click.native="onLinkClick"></screen-link>
      <!--                  <screen-link left="15%" top="37.5%" width="25%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="reports-turnover-dates" @click.native="onLinkClick"></screen-link>-->


      <screen-link left="83%" top="19%" width="8%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="reportTemplate" @click.native="onOverlayClick"></screen-link>
      <screen-link left="91%" top="19%" width="8%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="downloadOptions" @click.native="onOverlayClick"></screen-link>
      <report-template-panel v-if="$store.state.overlayPanel === 'reportTemplate'" left="79%" top="16%" width="20%" @close="closeOverlay" @link="onLinkClick"></report-template-panel>
      <download-options-panel v-if="$store.state.overlayPanel === 'downloadOptions'" left="79%" top="16%" width="20%" @close="closeOverlay" @link="onLinkClick"></download-options-panel>
      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" />
    </div>
  </div>

  <div class="screen" data-screen="reports-pay-range" :data-active="currentScreen=='reports-pay-range'">
    <img class="w-full max-w-100" src="/img/screens/reports/admin-reports-pay-range.png?v=10022023" />
    <div class="links">
      <screen-link left="15%" top="13.5%" width="11%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="reports" @click.native="onLinkClick"></screen-link>
      <!--                  <screen-link left="15%" top="37.5%" width="25%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="reports-turnover-dates" @click.native="onLinkClick"></screen-link>-->


      <screen-link left="83%" top="19%" width="8%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="reportTemplate" @click.native="onOverlayClick"></screen-link>
      <screen-link left="91%" top="19%" width="8%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="downloadOptions" @click.native="onOverlayClick"></screen-link>
      <report-template-panel v-if="$store.state.overlayPanel === 'reportTemplate'" left="79%" top="16%" width="20%" @close="closeOverlay" @link="onLinkClick"></report-template-panel>
      <download-options-panel v-if="$store.state.overlayPanel === 'downloadOptions'" left="79%" top="16%" width="20%" @close="closeOverlay" @link="onLinkClick"></download-options-panel>
      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" />
    </div>
  </div>

  <div class="screen" data-screen="reports-demographics" :data-active="currentScreen=='reports-demographics'">
    <img class="w-full max-w-100" src="/img/screens/reports/admin-reports-demographics.png?v=10122022" />
    <div class="links">
      <screen-link left="15%" top="13.5%" width="11%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr" @click.native="onLinkClick"></screen-link>
      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" />
    </div>
  </div>



  <div class="screen" data-screen="reports-pay-discrepancy" :data-active="currentScreen=='reports-pay-discrepancy'">
    <img class="w-full max-w-100" src="/img/screens/reports/admin-reports-pay-discrepancy-30days.png?v=10122022" />
    <coming-soon></coming-soon>
    <div class="links">
      <screen-link left="15%" top="13.5%" width="11%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="reports" @click.native="onLinkClick"></screen-link>
      <!--                  <screen-link left="15%" top="37.5%" width="25%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="reports-turnover-dates" @click.native="onLinkClick"></screen-link>-->
      <screen-link left="15%" top="37.5%" width="25%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="reportPayDiscrepancyDateRange" @click.native="onOverlayClick"></screen-link>

      <screen-link left="83%" top="19%" width="7.8%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="reportTemplate" @click.native="onOverlayClick"></screen-link>
      <screen-link left="91%" top="19%" width="8%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="downloadOptions" @click.native="onOverlayClick"></screen-link>
      <report-template-panel v-if="$store.state.overlayPanel === 'reportTemplate'" left="79%" top="16%" width="20%" @close="closeOverlay" @link="onLinkClick"></report-template-panel>
      <download-options-panel v-if="$store.state.overlayPanel === 'downloadOptions'" left="79%" top="16%" width="20%" @close="closeOverlay" @link="onLinkClick"></download-options-panel>

      <report-pay-discrepancy-date-range-panel v-if="$store.state.overlayPanel === 'reportPayDiscrepancyDateRange'" left="21%" top="38%" width="20%" @close="closeOverlay" @link="onLinkClick" selection="30days"></report-pay-discrepancy-date-range-panel>

      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" />


    </div>
  </div>

  <div class="screen" data-screen="reports-pay-discrepancy-year" :data-active="currentScreen=='reports-pay-discrepancy-year'">
    <img class="w-full max-w-100" src="/img/screens/reports/admin-reports-pay-discrepancy-current-year.png?v=10122022" />
    <coming-soon></coming-soon>
    <div class="links">
      <screen-link left="15%" top="13.5%" width="11%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="reports" @click.native="onLinkClick"></screen-link>
      <!--                  <screen-link left="15%" top="37.5%" width="25%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="reports-turnover-dates" @click.native="onLinkClick"></screen-link>-->
      <screen-link left="15%" top="37.5%" width="25%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="reportPayDiscrepancyDateRange" @click.native="onOverlayClick"></screen-link>

      <screen-link left="83%" top="19%" width="7.8%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="reportTemplate" @click.native="onOverlayClick"></screen-link>
      <screen-link left="91%" top="19%" width="8%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="downloadOptions" @click.native="onOverlayClick"></screen-link>
      <report-template-panel v-if="$store.state.overlayPanel === 'reportTemplate'" left="79%" top="16%" width="20%" @close="closeOverlay" @link="onLinkClick"></report-template-panel>
      <download-options-panel v-if="$store.state.overlayPanel === 'downloadOptions'" left="79%" top="16%" width="20%" @close="closeOverlay" @link="onLinkClick"></download-options-panel>

      <report-pay-discrepancy-date-range-panel v-if="$store.state.overlayPanel === 'reportPayDiscrepancyDateRange'" left="21%" top="38%" width="20%" @close="closeOverlay" @link="onLinkClick" selection="30days"></report-pay-discrepancy-date-range-panel>

      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" />


    </div>
  </div>




  <div class="screen" data-screen="reports-turnover" :data-active="currentScreen=='reports-turnover'">
    <img class="w-full max-w-100" src="/img/screens/reports/admin-reports-turnover-90days.png?v=10122022" />
    <div class="links">
      <screen-link left="15%" top="13.5%" width="11%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr" @click.native="onLinkClick"></screen-link>
      <!--                  <screen-link left="15%" top="37.5%" width="25%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="reports-turnover-dates" @click.native="onLinkClick"></screen-link>-->
      <screen-link left="15%" top="37.5%" width="25%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="reportDateRange" @click.native="onOverlayClick"></screen-link>

      <screen-link left="83%" top="19%" width="8%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="reportTemplate" @click.native="onOverlayClick"></screen-link>
      <screen-link left="91%" top="19%" width="8%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="downloadOptions" @click.native="onOverlayClick"></screen-link>
      <report-template-panel v-if="$store.state.overlayPanel === 'reportTemplate'" left="79%" top="16%" width="20%" @close="closeOverlay" @link="onLinkClick"></report-template-panel>
      <download-options-panel v-if="$store.state.overlayPanel === 'downloadOptions'" left="79%" top="16%" width="20%" @close="closeOverlay" @link="onLinkClick"></download-options-panel>

      <report-date-range-panel v-if="$store.state.overlayPanel === 'reportDateRange'" left="21%" top="38%" width="20%" @close="closeOverlay" @link="onLinkClick" selection="90days"></report-date-range-panel>
      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" />
    </div>
  </div>


  <div class="screen" data-screen="reports-turnover-12months" :data-active="currentScreen=='reports-turnover-12months'">
    <img class="w-full max-w-100" src="/img/screens/reports/admin-reports-turnover-12months.png?v=10122022" />
    <div class="links">
      <screen-link left="15%" top="13.5%" width="11%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr" @click.native="onLinkClick"></screen-link>

      <screen-link left="15%" top="37.5%" width="25%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="reportDateRange" @click.native="onOverlayClick"></screen-link>
      <screen-link left="18%" top="62.5%" width="25%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="reportTurnoverOptions" @click.native="onOverlayClick"></screen-link>
      <screen-link left="83%" top="19%" width="8%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="reportTemplate" @click.native="onOverlayClick"></screen-link>
      <screen-link left="91%" top="19%" width="8%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="downloadOptions" @click.native="onOverlayClick"></screen-link>
      <screen-link left="83%" top="115%" width="6%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="pullout-reports-columns" @click.native="showPullout"></screen-link>
      <report-date-range-panel v-if="$store.state.overlayPanel === 'reportDateRange'" left="21%" top="38%" width="20%" @close="closeOverlay" @link="onLinkClick" selection="12months"></report-date-range-panel>
      <report-turnover-options-panel v-if="$store.state.overlayPanel === 'reportTurnoverOptions'" left="30%" top="62%" width="20%" @close="closeOverlay" @link="onLinkClick" selection="Date" @pullout="showPullout"></report-turnover-options-panel>
      <report-template-panel v-if="$store.state.overlayPanel === 'reportTemplate'" left="79%" top="16%" width="20%" @close="closeOverlay" @link="onLinkClick"></report-template-panel>
      <download-options-panel v-if="$store.state.overlayPanel === 'downloadOptions'" left="79%" top="16%" width="20%" @close="closeOverlay" @link="onLinkClick"></download-options-panel>
      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" />
    </div>


  </div>

  <div class="screen" data-screen="reports-turnover-12months-compare-date" :data-active="currentScreen=='reports-turnover-12months-compare-date'">
    <img class="w-full max-w-100" src="/img/screens/reports/admin-reports-turnover-12months-compare.png?v=10122022" />
    <div class="links">
      <screen-link left="15%" top="13.5%" width="11%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr" @click.native="onLinkClick"></screen-link>

      <screen-link left="15%" top="37.5%" width="25%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="reportDateRange" @click.native="onOverlayClick"></screen-link>
      <screen-link left="18%" top="62.5%" width="25%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="reportTurnoverOptions" @click.native="onOverlayClick"></screen-link>
      <screen-link left="83%" top="19%" width="8%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="reportTemplate" @click.native="onOverlayClick"></screen-link>
      <screen-link left="91%" top="19%" width="8%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="downloadOptions" @click.native="onOverlayClick"></screen-link>
      <screen-link left="83%" top="115%" width="6%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="pullout-reports-columns" @click.native="showPullout"></screen-link>
      <report-date-range-panel v-if="$store.state.overlayPanel === 'reportDateRange'" left="21%" top="38%" width="20%" @close="closeOverlay" @link="onLinkClick" selection="12months"></report-date-range-panel>
      <report-turnover-options-panel v-if="$store.state.overlayPanel === 'reportTurnoverOptions'" left="30%" top="62%" width="20%" @close="closeOverlay" @link="onLinkClick" selection="Date" @pullout="showPullout"></report-turnover-options-panel>
      <report-template-panel v-if="$store.state.overlayPanel === 'reportTemplate'" left="79%" top="16%" width="20%" @close="closeOverlay" @link="onLinkClick"></report-template-panel>
      <download-options-panel v-if="$store.state.overlayPanel === 'downloadOptions'" left="79%" top="16%" width="20%" @close="closeOverlay" @link="onLinkClick"></download-options-panel>
      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" />
    </div>


  </div>

  <div class="screen" data-screen="reports-turnover-12months-compare-position" :data-active="currentScreen=='reports-turnover-12months-compare-position'">
    <img class="w-full max-w-100" src="/img/screens/reports/admin-reports-turnover-12months-positions.png?v=10122022" />
    <div class="links">
      <screen-link left="15%" top="13.5%" width="11%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr" @click.native="onLinkClick"></screen-link>

      <screen-link left="15%" top="37.5%" width="25%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="reportDateRange" @click.native="onOverlayClick"></screen-link>
      <screen-link left="18%" top="62.5%" width="25%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="reportTurnoverOptions" @click.native="onOverlayClick"></screen-link>
      <screen-link left="83%" top="19%" width="8%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="reportTemplate" @click.native="onOverlayClick"></screen-link>
      <screen-link left="91%" top="19%" width="8%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="downloadOptions" @click.native="onOverlayClick"></screen-link>
      <screen-link left="83%" top="115%" width="6%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="pullout-reports-columns" @click.native="showPullout"></screen-link>
      <report-date-range-panel v-if="$store.state.overlayPanel === 'reportDateRange'" left="21%" top="38%" width="20%" @close="closeOverlay" @link="onLinkClick" selection="12months"></report-date-range-panel>
      <report-turnover-options-panel v-if="$store.state.overlayPanel === 'reportTurnoverOptions'" left="30%" top="62%" width="20%" @close="closeOverlay" @link="onLinkClick" selection="Top positions" @pullout="showPullout"></report-turnover-options-panel>
      <report-template-panel v-if="$store.state.overlayPanel === 'reportTemplate'" left="79%" top="16%" width="20%" @close="closeOverlay" @link="onLinkClick"></report-template-panel>
      <download-options-panel v-if="$store.state.overlayPanel === 'downloadOptions'" left="79%" top="16%" width="20%" @close="closeOverlay" @link="onLinkClick"></download-options-panel>
      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" />
    </div>


  </div>

  <div class="screen" data-screen="reports-turnover-12months-columns-modified" :data-active="currentScreen=='reports-turnover-12months-columns-modified'">
    <img class="w-full max-w-100" src="/img/screens/admin-reports-turnover-12months-columns-modified.png?v=10122022" />
    <div class="links">
      <screen-link left="15%" top="13.5%" width="11%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr" @click.native="onLinkClick"></screen-link>

      <screen-link left="15%" top="37.5%" width="25%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="reportDateRange" @click.native="onOverlayClick"></screen-link>
      <screen-link left="18%" top="70.5%" width="25%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="reportTurnoverOptions" @click.native="onOverlayClick"></screen-link>
      <screen-link left="83%" top="19%" width="8%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="reportTemplate" @click.native="onOverlayClick"></screen-link>
      <screen-link left="92%" top="19%" width="8%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="downloadOptions" @click.native="onOverlayClick"></screen-link>
      <screen-link left="83%" top="123%" width="6%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="pullout-reports-columns" @click.native="showPullout"></screen-link>
      <report-date-range-panel v-if="$store.state.overlayPanel === 'reportDateRange'" left="21%" top="38%" width="20%" @close="closeOverlay" @link="onLinkClick" selection="12months"></report-date-range-panel>
      <report-turnover-options-panel v-if="$store.state.overlayPanel === 'reportTurnoverOptions'" left="30%" top="70%" width="20%" @close="closeOverlay" @link="onLinkClick" selection="Top positions" @pullout="showPullout"></report-turnover-options-panel>
      <report-template-panel v-if="$store.state.overlayPanel === 'reportTemplate'" left="79%" top="16%" width="20%" @close="closeOverlay" @link="onLinkClick"></report-template-panel>
      <download-options-panel v-if="$store.state.overlayPanel === 'downloadOptions'" left="79%" top="16%" width="20%" @close="closeOverlay" @link="onLinkClick"></download-options-panel>
      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" />
    </div>


  </div>

  <div class="screen" data-screen="reports-turnover-12months-groups" :data-active="currentScreen=='reports-turnover-12months-groups'">
    <img class="w-full max-w-100" src="/img/screens/admin-reports-turnover-groups.png?v=10122022" />
    <div class="links">
      <screen-link left="15%" top="13.5%" width="11%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr" @click.native="onLinkClick"></screen-link>
      <screen-link left="96%" top="122.5%" width="4%" height="4.5%"  dot-class="dot-top-center" data-tgt="reports-turnover-12months" @click.native="onLinkClick"></screen-link>
      <screen-link left="80%" top="127%" width="7%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="reports-turnover-12months-groupby" @click.native="onLinkClick" @pullout="showPullout"></screen-link>
      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" />
    </div>
  </div>

  <div class="screen" data-screen="reports-turnover-12months-groupby" :data-active="currentScreen=='reports-turnover-12months-groupby'">
    <img class="w-full max-w-100" src="/img/screens/admin-reports-turnover-groupby.png?v=10122022" />
    <div class="links">


      <screen-link left="62%" top="89%" width="6.5%" height="5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="reports-turnover-12months" @click.native="onLinkClick"></screen-link>
      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" />
    </div>
  </div>

  <div class="screen" data-screen="reports-performance" :data-active="currentScreen=='reports-performance'">
    <img class="w-full max-w-100" src="/img/screens/reports/admin-reports-performance.png?v=10122022" />
    <div class="links">
      <screen-link left="15%" top="13.5%" width="11%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr-reviews" @click.native="onLinkClick"></screen-link>
      <screen-link left="16%" top="127.5%" width="8%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="pullout-profile" data-init-info="profile" @click.native="showPullout"></screen-link>
      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" />
    </div>
  </div>



  <div class="screen" data-screen="reports-retention" :data-active="currentScreen=='reports-retention'">
    <img class="w-full max-w-100" src="/img/screens/reports/admin-reports-retention.png?v=10122022" />
    <div class="links">
      <screen-link left="15%" top="13.5%" width="11%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="reports" @click.native="onLinkClick"></screen-link>
      <!--                  <screen-link left="15%" top="37.5%" width="25%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="reports-turnover-dates" @click.native="onLinkClick"></screen-link>-->


      <screen-link left="83%" top="19%" width="8%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="reportTemplate" @click.native="onOverlayClick"></screen-link>
      <screen-link left="91%" top="19%" width="8%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="downloadOptions" @click.native="onOverlayClick"></screen-link>
      <screen-link left="95%" top="83%" width="4%" height="7%" dot-class="dot-right-center" data-tgt="reports-retention-score" @click.native="onLinkClick"></screen-link>


      <report-template-panel v-if="$store.state.overlayPanel === 'reportTemplate'" left="79%" top="16%" width="20%" @close="closeOverlay" @link="onLinkClick"></report-template-panel>
      <download-options-panel v-if="$store.state.overlayPanel === 'downloadOptions'" left="79%" top="16%" width="20%" @close="closeOverlay" @link="onLinkClick"></download-options-panel>
      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" />
    </div>
  </div>

  <div class="screen" data-screen="reports-retention-score" :data-active="currentScreen=='reports-retention-score'">
    <img class="w-full max-w-100" src="/img/screens/reports/admin-reports-retention-score.png?v=10122022" />
    <div class="links">
      <screen-link left="15%" top="13.5%" width="11%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="hr" @click.native="onLinkClick"></screen-link>
      <!--                  <screen-link left="15%" top="37.5%" width="25%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="reports-turnover-dates" @click.native="onLinkClick"></screen-link>-->


      <screen-link left="83%" top="19%" width="8%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="reportTemplate" @click.native="onOverlayClick"></screen-link>
      <screen-link left="91%" top="19%" width="8%" height="5%" data-tgt-scrolltop="true" dot-class="dot-top-center" data-tgt="downloadOptions" @click.native="onOverlayClick"></screen-link>
      <screen-link left="15.5%" top="83%" width="4%" height="7%" dot-class="dot-left-center" data-tgt="reports-retention" @click.native="onLinkClick"></screen-link>


      <report-template-panel v-if="$store.state.overlayPanel === 'reportTemplate'" left="79%" top="16%" width="20%" @close="closeOverlay" @link="onLinkClick"></report-template-panel>
      <download-options-panel v-if="$store.state.overlayPanel === 'downloadOptions'" left="79%" top="16%" width="20%" @close="closeOverlay" @link="onLinkClick"></download-options-panel>
      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" />
    </div>
  </div>


  <!-- COMP BENCHMARKING -->

  <div class="screen" data-screen="reports-hr-analytics-comp-benchmarking" :data-active="currentScreen=='reports-hr-analytics-comp-benchmarking'">
    <img class="w-full max-w-100" src="/img/screens/reports/comp-benchmarking.png?v=12222024" />
    <div class="links">
      <screen-link left="21.5%" top="51%" width="22.5%" height="25%"  dot-class="dot-top-center" data-tgt="reports-comp-benchmarking-job-matching" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
      <screen-link left="21.5%" top="84.75%" width="22.5%" height="22%"  dot-class="dot-top-center" data-tgt="reports-comp-benchmarking-compare" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
      <screen-link left="45.5%" top="84.75%" width="22.5%" height="22%"  dot-class="dot-top-center" data-tgt="reports-comp-benchmarking-explore" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" :active-companies="{enterprise:true,pro:true}"/>
    </div>
  </div>

  <div class="screen" data-screen="reports-comp-benchmarking-job-matching" :data-active="currentScreen=='reports-comp-benchmarking-job-matching'">
    <img class="w-full max-w-100" src="/img/screens/reports/comp-benchmarking-not-matched.png?v=12222024" />
    <div class="links">

      <screen-link left="15%" top="14%" width="8%" height="4%"  dot-class="dot-right-center" data-tgt="reports-hr-analytics" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
      <screen-link left="33%" top="57%" width="13.5%" height="4.5%"  dot-class="dot-top-center" data-tgt="reports-comp-benchmarking-job-matching-select" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>

      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" :active-companies="{enterprise:true,pro:true}"/>
    </div>
  </div>

  <div class="screen" data-screen="reports-comp-benchmarking-job-matching-select" :data-active="currentScreen=='reports-comp-benchmarking-job-matching-select'">
    <img class="w-full max-w-100" src="/img/screens/reports/comp-benchmarking-match.png?v=12222024" />
    <div class="links">

      <screen-link left="15%" top="14%" width="8%" height="4%"  dot-class="dot-right-center" data-tgt="reports-hr-analytics" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
      <screen-link left="26.5%" top="57%" width="6%" height="4.5%"  dot-class="dot-top-center" data-tgt="reports-comp-benchmarking-job-match-confirm" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>

      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" :active-companies="{enterprise:true,pro:true}"/>
    </div>
  </div>

  <div class="screen" data-screen="reports-comp-benchmarking-job-match-confirm" :data-active="currentScreen=='reports-comp-benchmarking-job-match-confirm'">
    <img class="w-full max-w-100" src="/img/screens/reports/comp-benchmarking-match-confirm.png?v=12222024" />
    <div class="links">


      <screen-link left="87.75%" top="93.5%" width="6%" height="4.5%"  dot-class="dot-top-center" data-tgt="reports-comp-benchmarking-job-match-matched" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>

      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" :active-companies="{enterprise:true,pro:true}"/>
    </div>
  </div>

  <div class="screen" data-screen="reports-comp-benchmarking-job-match-matched" :data-active="currentScreen=='reports-comp-benchmarking-job-match-matched'">
    <img class="w-full max-w-100" src="/img/screens/reports/comp-benchmarking-match-confirmed.png?v=12222024" />
    <div class="links">

      <screen-link left="16%" top="22.5%" width="15%" height="4%"  dot-class="dot-bottom-center" data-tgt="reports-hr-analytics-comp-benchmarking" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" :active-companies="{enterprise:true,pro:true}"/>
    </div>
  </div>

  <div class="screen" data-screen="reports-comp-benchmarking-compare" :data-active="currentScreen=='reports-comp-benchmarking-compare'">
    <img class="w-full max-w-100" src="/img/screens/reports/comp-benchmarking-compare.png?v=12222024" />
    <div class="links">

      <screen-link left="16%" top="22.5%" width="15%" height="4%"  dot-class="dot-bottom-center" data-tgt="reports-hr-analytics-comp-benchmarking" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
      <screen-link left="35.5%" top="81%" width="9%" height="4.5%"  dot-class="dot-right-center" data-tgt="reports-comp-benchmarking-compare-marketing" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>

      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" :active-companies="{enterprise:true,pro:true}"/>
    </div>
  </div>

  <div class="screen" data-screen="reports-comp-benchmarking-compare-marketing" :data-active="currentScreen=='reports-comp-benchmarking-compare-marketing'">
    <img class="w-full max-w-100" src="/img/screens/reports/comp-benchmarking-compare-strategist.png?v=12222024" />
    <div class="links">

      <screen-link left="16%" top="22.5%" width="15%" height="4%"  dot-class="dot-bottom-center" data-tgt="reports-hr-analytics-comp-benchmarking" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
      <screen-link left="54%" top="52%" width="9%" height="4.5%"  dot-class="dot-right-center" data-tgt="reports-comp-benchmarking-compare-insights" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>

      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" :active-companies="{enterprise:true,pro:true}"/>
    </div>
  </div>
  <div class="screen" data-screen="reports-comp-benchmarking-compare-insights" :data-active="currentScreen=='reports-comp-benchmarking-compare-insights'">
    <img class="w-full max-w-100" src="/img/screens/reports/comp-benchmarking-compare-person.png?v=12222024" />
    <div class="links">

      <screen-link left="74%" top="34%" width="3%" height="4%"  dot-class="dot-left-center" data-tgt="reports-comp-benchmarking-compare-marketing" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" :active-companies="{enterprise:true,pro:true}"/>
    </div>
  </div>

  <div class="screen" data-screen="reports-comp-benchmarking-explore" :data-active="currentScreen=='reports-comp-benchmarking-explore'">
    <img class="w-full max-w-100" src="/img/screens/reports/comp-benchmarking-explore.png?v=12222024" />
    <div class="links">

      <screen-link left="15%" top="14%" width="8%" height="4%"  dot-class="dot-right-center" data-tgt="reports-hr-analytics" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
      <screen-link left="17%" top="55%" width="25%" height="6.5%"  dot-class="dot-right-center" data-tgt="reports-hr-analytics-comp-benchmarking" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
      <company-tab :company="$store.state.company" :select-tgt="companyStartScreen" :active-companies="{enterprise:true,pro:true}"/>
    </div>
  </div>


</div>
</template>

<script>
import ScreenLink from "@/components/ScreenLink";
import OvertimeOptionsPanel from "@/components/OvertimeOptionsPanel";
import ReportTemplatePanel from "@/components/ReportTemplatePanel";
import DownloadOptionsPanel from "@/components/DownloadOptionsPanel";
import ReportDateRangePanel from "@/components/ReportDateRangePanel";
import EqualPayPanel from "@/components/EqualPayPanel";
import ReportTurnoverOptionsPanel from "@/components/ReportTurnoverOptionsPanel";

import InfoBox from "@/components/InfoBox";
import PayrollLaborCostPanel from "@/components/PayrollLaborCostPanel";
import PayrollItemDetailsPanel from "@/components/PayItemDetailsPanel";
import ReportAnalyticsEditPanel from "@/components/ReportAnalyticsEditPanel";
import DocumentCountOptionsPanel from "@/components/DocumentCountOptionsPanel";
import ReportPayDiscrepancyDateRangePanel from "@/components/ReportPayDiscrepancyDateRangePanel";
import ComingSoon from "@/components/ComingSoon";
import CompanyTab from "@/components/CompanyTab";
import ReportGeneralLedgerDateRangePanel from "@/components/ReportGeneralLedgerDateRangePanel";


export default {
    name: "admin-reports",
  components: {
    ReportGeneralLedgerDateRangePanel,
    CompanyTab,
    ComingSoon,
    ReportPayDiscrepancyDateRangePanel,
    DocumentCountOptionsPanel,

    ReportAnalyticsEditPanel,
    PayrollItemDetailsPanel,
    PayrollLaborCostPanel,
    InfoBox,
    ReportTurnoverOptionsPanel,
    EqualPayPanel,
    ReportDateRangePanel, DownloadOptionsPanel, ReportTemplatePanel, OvertimeOptionsPanel, ScreenLink},
  props: {
      currentScreen: {
      },
  },
    data: function(){
        return {
          companyStartScreen: "reports",
          showLaborCostLocationZoom: true
        }
        },
    methods: {
      onLinkClick: function(e){
        this.showLaborCostLocationZoom = false;
        this.$screens.onLinkClick(e)
      },

      showPullout: function(e){
        this.$screens.showPullout(e)
      },

      onOverlayClick: function(e){
        this.$screens.onOverlayPanel(e)
      },

      closeOverlay: function(){
        this.$screens.setOverlayPanel()
      },

      closePullout: function(){
        this.$screens.closePullout();
      }
    },
    computed: {}
}
</script>

<style scoped>


.admin-reports {

}

.reports-labor-cost-threshold-chart {
  position: absolute;
  width: calc((366/1580) * 100%);
  left: calc((306.5/1580)* 100%);
  top: calc((812.5/1830) * 100%);
  opacity: 0;
  cursor: pointer;

}

.reports-labor-cost-threshold-chart:hover {
  opacity: 1.0;
}

.labor-cost-location-zoom {
  position: absolute;
  width: calc((1264/1580) * 100%);
  left: 17.15%;
  top: 55.15%;
}

.hover-area img {
  opacity: 0;
  transition: opacity 0.3s;
}

.hover-area:hover img {
  opacity: 1;
}



</style>
