import Vue from 'vue'
import Vuex from 'vuex'
import router from "@/router";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    clockin: "",
    guidedMode: true,
    showLinks: (process.env.VUE_APP_DEMO_MODE === 'development')?true:false,
    // showLinks: false,
    candidateExitRoute: {
      target: {
        dataset: {
          tgt: "hiring-v2-candidates",
          tgtMode: "admin"
        }
      }
    },

    onboardingType: "enterprise",
    demo: "",
    showMenu: false,
    hasShownMenuOnce: false,
    version: "12.23.2024.0",
    fullscreen: false,
    accessid: "FLEXsalesdemo2023",
    loggedIn: (process.env.VUE_APP_DEMO_MODE === 'development'),
    showFlexLogin: (process.env.VUE_APP_LOGIN_MODE === 'flex'),
    sso: (process.env.VUE_APP_LOGIN_MODE === 'sso'),
    account: null,
    uploadedVaccination: false,
    prodMode: (process.env.VUE_APP_DEMO_MODE === 'production'),
    devMode: (process.env.VUE_APP_DEMO_MODE === 'development'),
    feedbackEnabled: (process.env.VUE_APP_FEEDBACK_MODE),
    feedbackMode: false,
    feedbackInput: false,
    flexUsername: "",

    helpPanel: "",
    pulloutPanel: "",
    panelInitInfo: "",
    overlayPanel: "",
    offerEmailBody: "Hi Andrea, \n\nAcme Inc Sample wants you to be their new Fulfillment associate. It’s time to decide if you accept the terms of their offer. They want a decision by 8/04/2023.\n" +
        "\n" +
        "If you have questions, contact Acme Inc Sample.\n" +
        "\n" +
        "Ready to see their offer?",

    screenScrollRef: undefined,
    company: "enterprise",
    hiringCompany: "enterprise",
    offerStatus: "", //sent, viewed, responded
    tasksCompleted: {
      approveTimeOff: false,
      fixTimePunch: false,
      reviewException: false
    },

    printOnsite: false,

    jobPosted: false,

    easySetup: {
      getStarted: {
        completed: false,
        agreed: false
      },
      priorPayroll: {
        completed: false
      },
      businessInfo: {
        completed: false
      },
      bankingInfo: {
        completed: false
      },
      paySchedule: {
        completed: false
      },
      taxInfo: {
        completed: false
      },
      addEmployees: {
        completed: false,
        progress: "",
      },
      documents: {
        completed: false
      },
      submitInfo: {
        completed: false
      }
    },

    taa: {
      shiftDuplicated: false,
      shiftAdded: false,
      shiftOverlap: false,
      baleyRangeApproved: false
    }

  },

  mutations: {




    approveTimeOff (state, value){
      state.tasksCompleted.approveTimeOff = value;
    },

    fixTimePunch (state, value){
      state.tasksCompleted.fixTimePunch = value;
    },

    reviewException (state, value){
      state.tasksCompleted.reviewException = value;
    },

    setCompany (state, value){
      state.company = value;
      //setting company should always reset hiring
      state.hiringCompany = 'enterprise';
    },
    setHiringCompany (state, value){
      state.hiringCompany = value;

      //seting hiring company should only carry over to company in pro & enterprise
      if((value === 'pro') || (value === 'enterprise')){
        state.company = value;
      }
    },
    guidedMode (state, value){

      console.log('guided mode',value, !!value);
      // if(value){
      //   state.guidedMode = true;
      // }
      state.guidedMode = value;
    },

    setShowLinks (state, value){
      state.showLinks = value;
    },

    showMenu (state, value){
      if(value){
        state.feedbackMode = false;
      }
      state.showMenu = value;
    },

    showFeedback (state, value){
      state.feedbackMode = value;
    },

    setFlexUsername (state, value){
      state.flexUsername = value;

    },
    fullscreen (state, value){
      state.fullscreen = value;
    },
    adminGroup (state, value){
      state.adminGroup = value;
    },
    login (state, value){
      state.loggedIn =  value;
      if(value){
        window.localStorage.setItem("lastLogin", `${Date.now()}`);
      }
    },

    clearPanels (state){
      state.overlayPanel = "";
      state.pulloutPanel = "";
      state.panelInitInfo = "";
      state.helpPanel = "";
      // router.replace({ name: router.currentRoute.name, params: router.currentRoute.params });
    },

    setOverlayPanel (state,overlay){
      //console.log("store setOverlayPanel");
      state.overlayPanel = overlay;
      let query = { ...router.query }
      if(overlay){
        query.tab = overlay;
      }else{
        if(query.tab){
          delete query.tab
        }
      }
      router.replace({ name: router.currentRoute.name, params: router.currentRoute.params, query: query});
    },
  },
  actions: {

    launchDemo ({commit,dispatch,state}, demoName){

      if(demoName !== state.demo){
        state.demo = demoName;

        if(demoName === "flex"){
          dispatch("linkClick",{
            target: {
              dataset: {
                tgt: "dashboard",
                tgtMode: "admin",
                tgtScrollTop: true
              }
            }
          })
        }else{

          state.easySetup = {
            getStarted: {
              completed: false,
              agreed: false
            },
            priorPayroll: {
              completed: false
            },
            businessInfo: {
              completed: false
            },
            bankingInfo: {
              completed: false
            },
            paySchedule: {
              completed: false
            },
            taxInfo: {
              completed: false
            },
            addEmployees: {
              completed: false,
              progress: "",
            },
            documents: {
              completed: false
            },
            submitInfo: {
              completed: false
            }
          }


          dispatch("linkClick",{
            target: {
              dataset: {
                tgt: "easy-setup-start",
                tgtMode: "easysetup",
                tgtScrollTop: true
              }
            }
          })
        }


      }

      commit("showMenu",false)

    },

    linkClick({commit,dispatch,state}, link){
      console.log("onLinkClick", link.target.dataset);

      if(link.target.dataset.commit){
        commit(link.target.dataset.commit, link.target.dataset.commitValue);
      }

      if(!link.target.dataset.tgt && state.pulloutPanel) {
        commit("clearPanels");
      }else if(link.target.dataset.isOverlay){
        state.panelInitInfo = link.target.dataset.initInfo
        commit("setOverlayPanel",link.target.dataset.tgt);
      }else{

        state.panelInitInfo = "";

        console.log(link.target.dataset.tgtMode);

        dispatch("setScreen",{
          id:link.target.dataset.tgt,
          mode:link.target.dataset.tgtMode,
          scrollToTop:link.target.dataset.tgtScrolltop,
          addPullout: link.target.dataset.addPullout,
          pulloutScreen: link.target.dataset.pulloutScreen,
          scrollY: link.target.dataset.tgtScrollY
        });


      }
    },

    overlayClick({commit,state}, link){
      //console.log("store overlayClick");
      state.panelInitInfo = link.target.dataset.initInfo
      commit("setOverlayPanel", link.target.dataset.tgt);
    },

    async showPullout({state}, link){
      //console.log("store showPullout", link.target)
      state.panelInitInfo = link.target.dataset.initInfo;
      state.pulloutPanel = link.target.dataset.tgt;

      if(state.panelInitInfo){
        await router.replace({ name: router.currentRoute.name, params: router.currentRoute.params, query: {pullout: link.target.dataset.tgt, level: state.panelInitInfo}});
      }else{
        await router.replace({ name: router.currentRoute.name, params: router.currentRoute.params, query: {pullout: link.target.dataset.tgt} });
      }
    },

    async showHelp({state}, link){
      if (state.helpPanel !== link.target.dataset.tgt){
        state.helpPanel = link.target.dataset.tgt;

        let query = { ...router.currentRoute.query};
        query.help = link.target.dataset.tgt;

        await router.replace({ name: router.currentRoute.name, params: router.currentRoute.params, query: query});
      }

    },

    async closeHelp({state}){

        state.helpPanel = "";

        let query = { ...router.currentRoute.query};
        delete query.help

        await router.replace({ name: router.currentRoute.name, params: router.currentRoute.params, query: query});

    },

    closePullout ({commit}){
      commit("clearPanels");
      router.replace({ name: router.currentRoute.name, params: router.currentRoute.params});
    },

    setScreen ({commit,state}, {id, mode, scrollToTop, addPullout, pulloutScreen,scrollY}){
      console.log("setScreen", mode, id);

      commit("clearPanels");
      console.log(id,addPullout,pulloutScreen);

      if(!mode){
        mode = "admin"
      }

      console.log("Scroll ref",state.screenScrollRef)

      if(id !== router.currentRoute.params.screen){

        if(!addPullout){
          router.push({name: mode, params: { screen: id}});
        }else{
          state.panelInitInfo = pulloutScreen;
          state.pulloutPanel = addPullout;
          router.push({name: mode, params: { screen: id}, query: {pullout: addPullout, level: pulloutScreen}});
        }



        if(scrollY){


          if(scrollY === "bottom"){
            let tgtY = state.screenScrollRef.scrollHeight;
            console.log("scroll to",scrollY,state.screenScrollRef,tgtY)

            state.screenScrollRef?.scrollTo(0,state.screenScrollRef.scrollHeight);


          }if(scrollY === "top"){
            state.screenScrollRef?.scrollTo(0,0);
          }else{
            state.screenScrollRef?.scrollTo(0,parseInt(scrollY));
          }


          //state.screenScrollRef?.scrollTo(0,scrollToY);
        } else if(scrollToTop){
          console.log("scroll to top")
          state.screenScrollRef?.scrollTo(0,0);
        }
      }
    },

    setGuided({commit},guided){
      commit("guidedMode", guided);
      commit("showMenu", false);
    },

    showExternalPage({state},link){
      let tgtUrl = link.target.dataset.tgt;
      let tgtTab = link.target.dataset.tgtTab?link.target.dataset.tgtTab:"_blank";
      if(link.target.dataset.tgtQuery){

        let queries = [];

        if(state.guidedMode){
          queries.push(`guided=true`)
        }else{
          queries.push(`guided=false`)
        }

        if(state.feedbackMode){
          queries.push('comments=1')
        }

        if(queries.length){
          tgtUrl += (`?${queries.join('&')}`)
        }

      }
      window.open(tgtUrl, tgtTab);
    },

    toggleOverlay({state}, link){
      //used primarily for toggling top nav pullouts (people, notifications, etc)
      //console.log("store toggleOverlay", link)
      console.log("Toggle overlay")

      if(!link || (state.overlayPanel === link.target.dataset.tgt)){
        state.overlayPanel = "";
        state.panelInitInfo = undefined
      }else{
        state.overlayPanel = link.target.dataset.tgt;
        state.panelInitInfo = link.target.dataset.initInfo;
      }
    },

    topNavLink: function({state, commit},link){
      if(link){
        let linkParams = link.split(":");
        if(linkParams[0] == "pullout"){
          if(linkParams.length > 2){
            state.panelInitInfo = linkParams[2];
          }else{
            state.panelInitInfo = ""
          }

          commit("setOverlayPanel",linkParams[1]);

        }
      }
    }

  },
  modules: {
  }
})
