import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    redirect:'/admin/dashboard',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    //component: () => import(/* webpackChunkName: "admin" */ '../views/AdminScreensDeprecated.vue'),
    component: () => import(/* webpackChunkName: "admin" */ '../views/AdminExperience.vue'),
    meta: {
      title: "Flex Admin",
      requiresAuth: true,
    }

  },
    {
      path: '/admin/:screen',
      name: 'admin',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      //component: () => import(/* webpackChunkName: "admin" */ '../views/AdminScreensDeprecated.vue'),
      component: () => import(/* webpackChunkName: "admin" */ '../views/AdminExperience.vue'),
      meta: {
        title: "Flex Admin",
        requiresAuth: true,
      }
    },
    {
      path: '/applicant/:screen',
      name: 'applicant',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "admin" */ '../views/ApplicantExperience.vue'),
      meta: {
        title: "Applicant Experience",
        requiresAuth: true,
      }
    },
    {
      path: '/mobile/:screen',
      name: 'mobile',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "admin" */ '../views/MobileExperience.vue'),
      meta: {
        title: "Mobile Employee Experience",
        requiresAuth: true,
      }
    },
    {
      path: '/candidate/:screen',
      name: 'candidate',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "admin" */ '../views/CandidateExperience.vue'),
      meta: {
        title: "Candidate Experience",
        requiresAuth: true,
      }
    },
    {
      path: '/employeeflock/:screen',
      name: 'employeeflock',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "admin" */ '../views/EmployeeFlockExperience.vue'),
      meta: {
        title: "Employee Flock Experience",
        requiresAuth: true,
      }
    },

    {
      path: '/employee/:screen',
      name: 'employee',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "employee" */ '../views/EmployeeExperience.vue'),
      meta: {
        title: "Flex Employee",
        requiresAuth: true,

      }
    },
    {
      path: '/training/:screen',
      meta: {
        title: 'Training',
      },

      name: 'training',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "admin" */ '../views/TrainingScreens.vue'),
    },
    {
      path: '/guided-choice/:screen',
      meta: {
        title: 'Guided Choice',
      },

      name: 'guided-choice',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "admin" */ '../views/GuidedChoiceScreens.vue'),
    },
    {
      path: '/hiretech/:screen',
      meta: {
        title: 'Hiretech',
      },

      name: 'hiretech',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "admin" */ '../views/HiretechScreens.vue'),
    },
    {
      path: '/engage/:screen',
      meta: {
        title: 'Paychex Flex Engage',
      },

      name: 'engage',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "admin" */ '../views/EngageScreens.vue'),
    },
    {
      path: '/corestream/:screen',
      meta: {
        title: 'Corestream',
      },

      name: 'corestream',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "admin" */ '../views/CorestreamScreens.vue'),
    },

    {
      path: '/payactiv/:screen',
      meta: {
        title: 'Payactiv',
      },

      name: 'payactiv',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "admin" */ '../views/PayactivScreens.vue'),
    },
    {
      path: '/fin-fit-perks/:screen',
      meta: {
        title: 'FinFit Perks',
      },

      name: 'finfitperks',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "admin" */ '../views/PayactivScreens.vue'),
    },

    {
      path: '/mypay/:screen',
      meta: {
        title: 'My Pay',
      },

      name: 'mypay',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "admin" */ '../views/MyPayPerksScreens.vue'),
    },

    {
      path: '/expense-wire/:screen',
      meta: {
        title: 'ExpenseWire',
      },

      name: 'expense-wire',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "admin" */ '../views/ExpenseWireScreens.vue'),
    },
    {
      path: '/expense-wire-mobile/:screen',
      name: 'expense-wire-mobile',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "admin" */ '../views/EmployeeExpenseWireExperience.vue'),
      meta: {
        title: "Employee ExpenseWire Experience",
        requiresAuth: true,
      }
    },
    {
      path: '/easysetup/:screen',
      meta: {
        title: 'Easy Setup',
      },

      name: 'easysetup',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "admin" */ '../views/EasySetupScreens.vue'),
    },

    {
      path: '/onlinehr/:screen',
      meta: {
        title: 'Online Library',
      },

      name: 'onlinehr',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "admin" */ '../views/OnlineLibraryScreens.vue')
    },

    {
      path: '/onboarding/:screen',
      meta: {
        title: 'Onboarding',
      },

      name: 'onboarding',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "employee" */ '../views/OnboardingScreens.vue')
    },
    {
      path: '/onboardingess/:screen',
      meta: {
        title: 'Onboarding Essentials',
      },

      name: 'onboardingess',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "employee" */ '../views/OnboardingEssentialsScreens.vue')
    },

    {
      path: '/copilot/:screen',
      meta: {
        title: 'Hiring Copilot',
      },

      name: 'copilot',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "employee" */ '../views/HiringCopilotScreens.vue')
    },

    {
      path: '/flock/:screen',
      meta: {
        title: 'Benefits Admin',
        requiresAuth: true,
      },

      name: 'flock',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "employee" */ '../views/FlockAdminExperience.vue')
    },


    {
      path: '/peo/:screen',
      meta: {
        title: 'PEO Onboarding',
      },

      name: 'peo',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "employee" */ '../views/PeoOnboardingScreens.vue')
    },
    {
      path: '/finfit/:screen',
      meta: {
        title: 'Finfit',
      },

      name: 'finfit',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "employee" */ '../views/FinfitScreens.vue')
    },
    {
      path: '/thinkhr/:screen',
      meta: {
        title: 'ThinkHR',
      },

      name: 'thinkhr',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "hr" */ '../views/HandbookBuilderScreens.vue')
    },
    {
      path: '/indeed/:screen',
      meta: {
        title: 'Indeed',
      },

      name: 'indeed',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "hr" */ '../views/IndeedScreens.vue')
    },
    {
      path: '/login',
      name: "Login",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "admin" */ '../views/Login.vue')
    },
]



const router = new VueRouter({
  routes
})

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  //const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) document.title = nearestWithTitle.meta.title;

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create, so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
      // Add the meta tags to the document head.
      .forEach(tag => document.head.appendChild(tag));

  next();
});

export default router
