<template>
  <div class="company-tab" :style="tabStyle">
      <img class="tab-image" :src="tabImage" />
      <div v-if="showLinks">
        <screen-link left="0%" top="0%" :width="linkWidth" height="100%" dot-class="dot-right-center" data-tgt="company-select" @click.native="$screens.toggleOverlay" />
        <company-select-hiring style="zIndex:100" v-if="$store.state.overlayPanel === 'company-select'" :activeCompanies="activeCompanies" @select="onSelect" />
      </div>

  </div>
</template>

<script>



import CompanySelectHiring from "@/components/admin/CompanySelectHiring";
export default {
  name: "company-tab-hiring",
  components: {CompanySelectHiring},
  props: {
    company: {
      type: String,
      default: "enterprise"
    },
    showLinks: {
      type: Boolean,
      default: true
    },

    activeCompanies: {
      type: Object,
      default: function() {
        return {
          enterprise: true,
          pro: true,
          select: true,
          peo:true,
          initech:true,
          sandysport: true
        }
      }
    },
    selectTgt: {
      type: String,
      default: ""
    },
    left: {
      type: String,
      default: "14.7%"
    },
    width: {
      type: String,
      default: "20%"
    },
    height: {
      type: String,
      default: "5%"
    },
    top: {
      type: String,
      default: "7.2%"
    },

  },

  methods: {
    onSelect: function(val){
     this.$emit('select', val);
    }
  },

  computed: {
    tabStyle: function(){
      return {
        top: this.top,
        left: this.left,
        width: this.width,
        height: this.height,
      }
    },
    tabImage: function(){
      switch (this.company){
        case "enterprise":
          return "/img/screens/hiring/company-select-acme-hiring.png"
        case "pro":
          return "/img/screens/hiring/company-select-polarson-hiring.png"
        case "select":
          return "/img/screens/hiring/company-select-irish-hiring.png"
        case "initech":
          return "/img/screens/hiring/company-select-initech-hiring.png"
        case "peo":
          return "/img/screens/hiring/company-select-peo-hiring.png"
        case "sandysport":
          return "/img/screens/hiring/company-select-sandy-sport.png"
        default:
          return "/img/screens/hiring/company-select-acme-hiring.png"
      }
    },
    linkWidth: function(){

      switch (this.company){
        case 'enterprise':
          return "87%";
        case 'pro':
          return "87%";
        case 'initech':
          return "87%";
        case 'peo':
          return "87%";
        default:
          return "87%";
      }


    }
  }
}
</script>

<style scoped>
  .company-tab {
    position: absolute;
    background-color: white;
  }

  .tab-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
</style>