


<template>
<div class="">
  <div v-if="company === 'select'" class="screen" data-screen="hiring-home" :data-active="currentScreen=='hiring-home'">
    <img class="w-full max-w-100" src="/img/screens/admin-hiring.png?v=09142023" />
    <div class="links">
      <screen-link left="15.5%" top="17.5%" width="5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="20.5%" top="17.5%" width="6%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-applicants" @click.native="onLinkClick"></screen-link>
      <screen-link left="26.5%" top="17.5%" width="7%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-requisitions" @click.native="onLinkClick"></screen-link>
      <screen-link left="33.5%" top="17.5%" width="5.5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="39%" top="17.5%" width="7%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-admin" @click.native="onLinkClick"></screen-link>

      <screen-link left="47%" top="62%" width="8%" height="30%" dot-class="dot-right-center" data-tgt-scrolltop="true" data-tgt="hiring-applicants-jobboard" @click.native="onLinkClick"></screen-link>
      <screen-link left="79.75%" top="73%" width="9%" height="4.5%" dot-class="dot-right-center" data-tgt-scrolltop="true" data-tgt="hiring-hiretech" @click.native="onLinkClick"></screen-link>

      <screen-link left="58.5%" top="155.8%" width="11%" height="4%" dot-class="dot-right-center" data-tgt-scrolltop="true" data-tgt="hiring-requisitions-details" @click.native="onLinkClick"></screen-link>

      <company-tab-hiring :company="company" @select="onCompanyChange" />





      <info-box v-if="!$store.state.pulloutPanel && !$store.state.overlayPanel" left="97.5%" top="10.5%" width="40em" height="4.5%" >

        <p>This module is where Hiring and Onboarding are managed. The hiring dashboard allows clients to see and search applicants, job openings and hiring statuses. By navigating through the top tabs, you can explore application information, view reports that you can use for analysis and manage organizational items.</p>
        <p>Our Onboarding systems, accessed through the Applicants tab, allow you to start a paperless process — a few simple clicks sends necessary paperwork to you new hire, where they can complete and store it electronically. This includes tax forms, direct deposit, handbook acknowledgement and more.</p>


      </info-box>

    </div>
  </div>

  <div v-else>

    <div v-if="$store.state.jobPosted" class="screen" data-screen="hiring-home" :data-active="currentScreen=='hiring-home'">
      <img class="w-full max-w-100" src="/img/screens/hiring/hiring-home-2024-w-posted.png?v=09142023" />
      <div class="links">
        <screen-link left="28.5%" top="67.5%" width="38.5%" height="6%" dot-class="dot-right-center" data-tgt="hiring-v2-candidates" @click.native="onLinkClick"></screen-link>
        <screen-link left="77%" top="22%" width="9.5%" height="4%" dot-class="dot-left-center" data-tgt="hiring-v2-post-job" @click.native="onLinkClick"></screen-link>
        <screen-link left="91%" top="14.5%" width="8.5%" height="5%" dot-class="dot-left-center" data-tgt="pullout-candidate" data-init-info="prefs" @click.native="showPullout"></screen-link>
        <screen-link left="68.5%" top="40.5%" width="17.5%" height="9.5%" dot-class="dot-right-center" data-tgt="candidate-job-portal" data-tgt-mode="candidate" @click.native="onLinkClick"></screen-link>
        <screen-link left="68.5%" top="28%" width="17.5%" height="10%" dot-class="dot-right-center" data-tgt="hiring-all-candidates"  @click.native="onLinkClick"></screen-link>
        <screen-link left="68.5%" top="52%" width="17.5%" height="10.5%" dot-class="dot-right-center" data-tgt="pullout-candidate" data-init-info="reports" @click.native="showPullout"></screen-link>


        <company-tab-hiring :company="company" @select="onCompanyChange" />
      </div>
    </div>

    <div v-else class="screen" data-screen="hiring-home" :data-active="currentScreen=='hiring-home'">
      <img class="w-full max-w-100" src="/img/screens/hiring/hiring-home-2024.png?v=09142023" />
      <div class="links">
        <screen-link left="28.5%" top="60.25%" width="38.5%" height="6%" dot-class="dot-right-center" data-tgt="hiring-v2-candidates" @click.native="onLinkClick"></screen-link>
        <screen-link left="77%" top="22%" width="9.5%" height="4%" dot-class="dot-left-center" data-tgt="hiring-v2-post-job" @click.native="onLinkClick"></screen-link>
        <screen-link left="91%" top="14.5%" width="8.5%" height="5%" dot-class="dot-left-center" data-tgt="pullout-candidate" data-init-info="prefs" @click.native="showPullout"></screen-link>
        <screen-link left="68.5%" top="40.5%" width="17.5%" height="9.5%" dot-class="dot-right-center" data-tgt="candidate-job-portal" data-tgt-mode="candidate" @click.native="onLinkClick"></screen-link>
        <screen-link left="68.5%" top="28%" width="17.5%" height="10%" dot-class="dot-right-center" data-tgt="hiring-all-candidates"  @click.native="onLinkClick"></screen-link>
        <screen-link left="68.5%" top="52%" width="17.5%" height="10.5%" dot-class="dot-right-center" data-tgt="pullout-candidate" data-init-info="reports" @click.native="showPullout"></screen-link>


        <company-tab-hiring :company="company" @select="onCompanyChange" />
      </div>
    </div>

  </div>




  <!-- START: Hiring 2024 -->



  <!-- END: Hiring 2024 -->


  <div class="screen" data-screen="hiring-v2-candidates-report" :data-active="currentScreen=='hiring-v2-candidates-report'">
    <img class="w-full max-w-100" src="/img/screens/hiring/hiring-reports-job-postings.png?v=09142023" />
    <div class="links">

      <screen-link left="15%" top="13.5%" width="6%" height="4.5%" dot-class="dot-right-center" data-tgt="hiring-home" @click.native="onLinkClick"></screen-link>
      <company-tab-hiring :company="company" @select="onCompanyChange" />

      

    </div>
  </div>

  <div class="screen z-max"  data-screen="hiring-hire-right" :data-active="currentScreen=='hiring-hire-right'">
    <img class="w-full max-w-100" src="/img/screens/hiring/candidate-bkgcheck-hire-right.png?v=06102024" />
    <div class="links">
      <screen-link left="87%" top="143%" width="9%" height="5%" dot-class="dot-left-center" data-tgt="hiring-home" data-add-pullout="pullout-candidate" data-pullout-screen="profile-tools" @click.native="onLinkClick"></screen-link>
      <screen-link left="92%" top="5%" width="5%" height="5%" dot-class="dot-left-center" data-tgt="hiring-home" data-add-pullout="pullout-candidate" data-pullout-screen="profile-tools" @click.native="onLinkClick"></screen-link>
    </div>


  </div>

  <div class="screen" data-screen="hiring-v2-post-job" :data-active="currentScreen=='hiring-v2-post-job'">
    <img class="w-full max-w-100" src="/img/screens/hiring/hiring-post-job.png?v=09142023" />
    <div class="links">

      <screen-link left="15%" top="13.5%" width="6%" height="4.5%" dot-class="dot-right-center" data-tgt="hiring-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="38%" top="61%" width="38%" height="4.5%" dot-class="dot-left-center" data-tgt="hiring-v2-post-job-details" @click.native="onLinkClick"></screen-link>
      <company-tab-hiring :company="company" @select="onCompanyChange" />

      

    </div>
  </div>

  <div class="screen" data-screen="hiring-v2-post-job-details" :data-active="currentScreen=='hiring-v2-post-job-details'">
    <img class="w-full max-w-100" src="/img/screens/hiring/hiring-post-job-details.png?v=09142023" />
    <div class="links">

      <screen-link left="15%" top="13.5%" width="6%" height="4.5%" dot-class="dot-right-center" data-tgt="hiring-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="70%" top="137%" width="7%" height="4.5%" dot-class="dot-left-center" data-tgt="hiring-v2-post-job-workflow" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
      <company-tab-hiring :company="company" @select="onCompanyChange" />

      

    </div>
  </div>

  <div class="screen" data-screen="hiring-v2-post-job-workflow" :data-active="currentScreen=='hiring-v2-post-job-workflow'">
    <img class="w-full max-w-100" src="/img/screens/hiring/hiring-post-job-workflow.png?v=09142023" />
    <div class="links">

      <screen-link left="15%" top="13.5%" width="6%" height="4.5%" dot-class="dot-right-center" data-tgt="hiring-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="38%" top="60%" width="38%" height="7%" dot-class="dot-left-center" data-tgt="hiring-v2-post-job-workflow-select" @click.native="onLinkClick"></screen-link>
      <screen-link left="37%" top="110%" width="6%" height="5%" dot-class="dot-left-center" data-tgt="hiring-v2-post-job-details" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
      <company-tab-hiring :company="company" @select="onCompanyChange" />

      

    </div>
  </div>

  <div class="screen" data-screen="hiring-v2-post-job-workflow-select" :data-active="currentScreen=='hiring-v2-post-job-workflow-select'">
    <img class="w-full max-w-100" src="/img/screens/hiring/hiring-post-job-workflow-templates.png?v=09142023" />
    <div class="links">

      <screen-link left="15%" top="13.5%" width="6%" height="4.5%" dot-class="dot-right-center" data-tgt="hiring-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="38%" top="64%" width="36%" height="7%" dot-class="dot-left-center" data-tgt="hiring-v2-post-job-workflow-hourly" @click.native="onLinkClick"></screen-link>
      <screen-link left="37%" top="110%" width="6%" height="5%" dot-class="dot-left-center" data-tgt="hiring-v2-post-job-details" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
      <company-tab-hiring :company="company" @select="onCompanyChange" />

      

    </div>
  </div>

  <div class="screen" data-screen="hiring-v2-post-job-workflow-hourly" :data-active="currentScreen=='hiring-v2-post-job-workflow-hourly'">
    <img class="w-full max-w-100" src="/img/screens/hiring/hiring-post-job-workflow-templates-hourly.png?v=09142023" />
    <div class="links">

      <screen-link left="15%" top="13.5%" width="6%" height="4.5%" dot-class="dot-right-center" data-tgt="hiring-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="38%" top="83.5%" width="38%" height="7%" dot-class="dot-left-center" data-tgt-scrollTop="true" data-tgt="hiring-v2-post-job-workflow-managers" @click.native="onLinkClick"></screen-link>
      <screen-link left="37%" top="110%" width="6%" height="5%" dot-class="dot-left-center" data-tgt="hiring-v2-post-job-details" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
      <company-tab-hiring :company="company" @select="onCompanyChange" />

      

    </div>
  </div>

  <div class="screen" data-screen="hiring-v2-post-job-workflow-managers" :data-active="currentScreen=='hiring-v2-post-job-workflow-managers'">
    <img class="w-full max-w-100" src="/img/screens/hiring/hiring-post-job-workflow-manager-select.png?v=09142023" />
    <div class="links">
      <screen-link left="61%" top="76.5%" width="6%" height="5.5%" dot-class="dot-top-center" data-tgt="hiring-v2-post-job-workflow-filled" @click.native="onLinkClick"></screen-link>
      
    </div>
  </div>

  <div class="screen" data-screen="hiring-v2-post-job-workflow-filled" :data-active="currentScreen=='hiring-v2-post-job-workflow-filled'">
    <img class="w-full max-w-100" src="/img/screens/hiring/hiring-post-job-workflow-manager-selected.png?v=09142023" />
    <div class="links">

      <screen-link left="15%" top="13.5%" width="6%" height="4.5%" dot-class="dot-right-center" data-tgt="hiring-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="39%" top="107%" width="37%" height="5%" dot-class="dot-right-center" data-tgt="hiring-v2-post-job-workflow-questionnaire" @click.native="onLinkClick"></screen-link>
      <screen-link left="37%" top="115%" width="6%" height="5%" dot-class="dot-left-center" data-tgt="hiring-v2-post-job-details" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
      <company-tab-hiring :company="company" @select="onCompanyChange" />
      

    </div>
  </div>

  <div class="screen" data-screen="hiring-v2-post-job-workflow-questionnaire" :data-active="currentScreen=='hiring-v2-post-job-workflow-questionnaire'">
    <img class="w-full max-w-100" src="/img/screens/hiring/hiring-post-job-workflow-questionnaire.png?v=09142023" />
    <div class="links">

      <screen-link left="15%" top="13.5%" width="6%" height="4.5%" dot-class="dot-right-center" data-tgt="hiring-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="38%" top="112%" width="12%" height="5%" dot-class="dot-left-center" data-tgt="hiring-v2-post-job-workflow-questionnaire-hourly" @click.native="onLinkClick"></screen-link>

      <company-tab-hiring :company="company" @select="onCompanyChange" />


    </div>
  </div>

  <div class="screen" data-screen="hiring-v2-post-job-workflow-questionnaire-hourly" :data-active="currentScreen=='hiring-v2-post-job-workflow-questionnaire-hourly'">
    <img class="w-full max-w-100" src="/img/screens/hiring/hiring-post-job-workflow-questionnaire-hourly.png?v=09142023" />
    <div class="links">

      <screen-link left="15%" top="13.5%" width="6%" height="4.5%" dot-class="dot-right-center" data-tgt="hiring-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="38%" top="114.75%" width="9%" height="5%" dot-class="dot-left-center" data-tgt="hiring-v2-post-job-workflow-questionnaire-preview" @click.native="onLinkClick"></screen-link>
      <screen-link left="71%" top="123%" width="6%" height="5%" dot-class="dot-left-center" data-tgt="hiring-v2-post-job-boards" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
      <screen-link left="37%" top="123%" width="6%" height="5%" dot-class="dot-left-center" data-tgt="hiring-v2-post-job-details" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
      <company-tab-hiring :company="company" @select="onCompanyChange" />


    </div>
  </div>

  <div class="screen" data-screen="hiring-v2-post-job-workflow-questionnaire-preview" :data-active="currentScreen=='hiring-v2-post-job-workflow-questionnaire-preview'">
    <img class="w-full max-w-100" src="/img/screens/hiring/hiring-post-job-workflow-questionnaire-preview.png?v=09142023" />
    <div class="links">
      <screen-link left="34.5%" top="56%" width="26%" height="5%" dot-class="dot-left-center" data-tgt="hiring-v2-post-job-workflow-questionnaire-preview-question" @click.native="onLinkClick"></screen-link>
      <screen-link left="61%" top="102%" width="4%" height="5%" dot-class="dot-left-center" data-tgt="hiring-v2-post-job-workflow-questionnaire-hourly"  @click.native="onLinkClick"></screen-link>
    </div>
  </div>

  <div class="screen" data-screen="hiring-v2-post-job-workflow-questionnaire-preview-question" :data-active="currentScreen=='hiring-v2-post-job-workflow-questionnaire-preview-question'">
    <img class="w-full max-w-100" src="/img/screens/hiring/hiring-post-job-workflow-questionnaire-preview-expand.png?v=09142023" />
    <div class="links">

      <screen-link left="61%" top="103%" width="4%" height="5%" dot-class="dot-left-center" data-tgt="hiring-v2-post-job-workflow-questionnaire-hourly" @click.native="onLinkClick"></screen-link>
    </div>
  </div>

  <div class="screen" data-screen="hiring-v2-post-job-boards" :data-active="currentScreen=='hiring-v2-post-job-boards'">
    <img class="w-full max-w-100" src="/img/screens/hiring/hiring-post-job-workflow-boards.png?v=09142023" />
    <div class="links">

      <screen-link left="15%" top="13.5%" width="6%" height="4.5%" dot-class="dot-right-center" data-tgt="hiring-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="71%" top="118.25%" width="6%" height="5%" dot-class="dot-left-center" data-tgt="hiring-v2-post-job-publish" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
      <screen-link left="37%" top="118.25%" width="6%" height="5%" dot-class="dot-left-center" data-tgt="hiring-v2-post-job-workflow-filled" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
      <company-tab-hiring :company="company" @select="onCompanyChange" />
      

    </div>
  </div>

  <div class="screen" data-screen="hiring-v2-post-job-publish" :data-active="currentScreen=='hiring-v2-post-job-publish'">
    <img class="w-full max-w-100" src="/img/screens/hiring/hiring-post-job-review.png?v=09142023" />
    <div class="links">

      <screen-link left="15%" top="13.5%" width="6%" height="4.5%" dot-class="dot-right-center" data-tgt="hiring-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="71%" top="150%" width="6%" height="5%" dot-class="dot-left-center" data-tgt="hiring-v2-post-job-posted" data-tgt-scrolltop="true" @click.native="postJob"></screen-link>
      <screen-link left="37%" top="150%" width="6%" height="5%" dot-class="dot-left-center" data-tgt="hiring-v2-post-job-boards" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
      <company-tab-hiring :company="company" @select="onCompanyChange" />
      

    </div>
  </div>

  <div class="screen" data-screen="hiring-v2-post-job-posted" :data-active="currentScreen=='hiring-v2-post-job-posted'">
    <img class="w-full max-w-100" src="/img/screens/hiring/hiring-post-job-posted.png?v=09142023" />
    <div class="links">

      <screen-link left="15%" top="13.5%" width="6%" height="4.5%" dot-class="dot-right-center" data-tgt="hiring-home" @click.native="onLinkClick"></screen-link>
      <company-tab-hiring :company="company" @select="onCompanyChange" />


    </div>
  </div>


  <div class="screen" data-screen="hiring-all-candidates" :data-active="currentScreen=='hiring-all-candidates'">
    <img class="w-full max-w-100" src="/img/screens/hiring/hiring-candidates.png?v=12222024" />
    <div class="links">
      <screen-link left="15%" top="14.5%" width="3.5%" height="3.5%" dot-class="dot-bottom-center" data-tgt="hiring-home" @click.native="onLinkClick"></screen-link>

      <screen-link left="17%" top="30%" width="10%" height="5.5%" dot-class="dot-bottom-center" data-tgt="candidate-options" @click.native="$screens.onOverlayPanel"></screen-link>
      <BasicOptionsPanel v-if="$store.state.overlayPanel==='candidate-options'" top="36%" left="17%" width="10%" :items="candidateSearchOptions" :on-select="onCandidateOptionClick" />

      <screen-link left="75%" top="48%" width="5%" height="3.5%" dot-class="dot-right-center" data-tgt="hiring-candidates-status" @click.native="onLinkClick"></screen-link>
      <screen-link left="86.5%" top="48%" width="7%" height="3.5%" dot-class="dot-right-center" data-tgt="hiring-candidates-rating" @click.native="onLinkClick"></screen-link>
      <screen-link left="16%" top="81.5%" width="82.5%" height="5%" dot-class="dot-right-center" data-tgt="pullout-candidate" data-init-info="profile" @click.native="showPullout"></screen-link>

      <company-tab-hiring :company="company" @select="onCompanyChange" />
    </div>
  </div>

  <div class="screen" data-screen="hiring-candidates-search-resume" :data-active="currentScreen=='hiring-candidates-search-resume'">
    <img class="w-full max-w-100" src="/img/screens/hiring/hiring-candidates-search-resume.png?v=12222024" />
    <div class="links">
      <screen-link left="15%" top="14.5%" width="3.5%" height="3.5%" dot-class="dot-bottom-center" data-tgt="hiring-home" @click.native="onLinkClick"></screen-link>

      <screen-link left="17%" top="30%" width="10%" height="5.5%" dot-class="dot-bottom-center" data-tgt="candidate-options" @click.native="$screens.onOverlayPanel"></screen-link>
      <BasicOptionsPanel v-if="$store.state.overlayPanel==='candidate-options'" top="36%" left="17%" width="10%" :items="candidateSearchOptions" :on-select="onCandidateOptionClick" />

      <screen-link left="75%" top="48%" width="5%" height="3.5%" dot-class="dot-right-center" data-tgt="hiring-candidates-status" @click.native="onLinkClick"></screen-link>
      <screen-link left="86.5%" top="48%" width="7%" height="3.5%" dot-class="dot-right-center" data-tgt="hiring-candidates-rating" @click.native="onLinkClick"></screen-link>
      <screen-link left="16%" top="81.5%" width="82.5%" height="5%" dot-class="dot-right-center" data-tgt="pullout-candidate" data-init-info="profile" @click.native="showPullout"></screen-link>

      <company-tab-hiring :company="company" @select="onCompanyChange" />
    </div>
  </div>

  <div class="screen" data-screen="hiring-candidates-status" :data-active="currentScreen=='hiring-candidates-status'">
    <img class="w-full max-w-100" src="/img/screens/hiring/hiring-candidates-sort-status.png?v=12222024" />
    <div class="links">
      <screen-link left="15%" top="14.5%" width="3.5%" height="3.5%" dot-class="dot-bottom-center" data-tgt="hiring-home" @click.native="onLinkClick"></screen-link>

      <screen-link left="17%" top="30%" width="10%" height="5.5%" dot-class="dot-bottom-center" data-tgt="candidate-options" @click.native="$screens.onOverlayPanel"></screen-link>
      <BasicOptionsPanel v-if="$store.state.overlayPanel==='candidate-options'" top="36%" left="17%" width="10%" :items="candidateSearchOptions" :on-select="onCandidateOptionClick" />

      <screen-link left="16%" top="48%" width="8%" height="3.5%" dot-class="dot-right-center" data-tgt="hiring-all-candidates" @click.native="onLinkClick"></screen-link>
      <screen-link left="86.5%" top="48%" width="7%" height="3.5%" dot-class="dot-right-center" data-tgt="hiring-candidates-rating" @click.native="onLinkClick"></screen-link>
      <screen-link left="16%" top="53.25%" width="82.5%" height="5%" dot-class="dot-right-center" data-tgt="pullout-candidate" data-init-info="profile" @click.native="showPullout"></screen-link>

      <company-tab-hiring :company="company" @select="onCompanyChange" />
    </div>
  </div>

  <div class="screen" data-screen="hiring-candidates-rating" :data-active="currentScreen=='hiring-candidates-rating'">
    <img class="w-full max-w-100" src="/img/screens/hiring/hiring-candidates-sort-rating.png?v=12222024" />
    <div class="links">
      <screen-link left="15%" top="14.5%" width="3.5%" height="3.5%" dot-class="dot-bottom-center" data-tgt="hiring-home" @click.native="onLinkClick"></screen-link>

      <screen-link left="17%" top="30%" width="10%" height="5.5%" dot-class="dot-bottom-center" data-tgt="candidate-options" @click.native="$screens.onOverlayPanel"></screen-link>
      <BasicOptionsPanel v-if="$store.state.overlayPanel==='candidate-options'" top="36%" left="17%" width="10%" :items="candidateSearchOptions" :on-select="onCandidateOptionClick" />

      <screen-link left="16%" top="48%" width="8%" height="3.5%" dot-class="dot-right-center" data-tgt="hiring-all-candidates" @click.native="onLinkClick"></screen-link>
      <screen-link left="75%" top="48%" width="5%" height="3.5%" dot-class="dot-right-center" data-tgt="hiring-candidates-status" @click.native="onLinkClick"></screen-link>
      <screen-link left="16%" top="159.25%" width="82.5%" height="5%" dot-class="dot-right-center" data-tgt="pullout-candidate" data-init-info="profile" @click.native="showPullout"></screen-link>

      <company-tab-hiring :company="company" @select="onCompanyChange" />
    </div>
  </div>



  <div class="screen" data-screen="hiring-v2-candidates" :data-active="currentScreen=='hiring-v2-candidates'">
    <img class="w-full max-w-100" src="/img/screens/hiring/hiring-job2-candidates.png?v=12222024" />
    <div class="links">
      <screen-link left="15%" top="14%" width="7%" height="3.5%" dot-class="dot-right-center" data-tgt="hiring-home" @click.native="onLinkClick"></screen-link>

      <screen-link left="14.5%" top="24%" width="7.5%" height="5.5%" dot-class="dot-bottom-center" data-tgt="hiring-v2-candidates" @click.native="onLinkClick"></screen-link>
      <screen-link left="22%" top="24%" width="4.5%" height="5.5%" dot-class="dot-bottom-center" data-tgt="hiring-v2-promote" @click.native="onLinkClick"></screen-link>
      <screen-link left="26.5%" top="24%" width="6%" height="5.5%" dot-class="dot-bottom-center" data-tgt="hiring-v2-job-details" @click.native="onLinkClick"></screen-link>

      <screen-link left="91.25%" top="19.5%" width="8%" height="5.5%" dot-class="dot-bottom-center" data-tgt="candidate-options" @click.native="$screens.onOverlayPanel"></screen-link>
      <BasicOptionsPanel v-if="$store.state.overlayPanel==='candidate-options'" top="21%" left="88%" width="10%" :items="candidateOptions" :on-select="onCandidateOptionClick" />
      <company-tab-hiring :company="company" @select="onCompanyChange" />

      <screen-link left="35%" top="56.25%" width="61.5%" height="5%" dot-class="dot-right-center" data-tgt="pullout-candidate" data-init-info="profile" @click.native="showPullout"></screen-link>
      
    </div>
  </div>

  <div class="screen" data-screen="hiring-v2-candidates-rated" :data-active="currentScreen=='hiring-v2-candidates-rated'">
    <img class="w-full max-w-100" src="/img/screens/hiring/hiring-candidate-list-rated.png?v=09142023" />
    <div class="links">
      <screen-link left="15%" top="14%" width="7%" height="3.5%" dot-class="dot-right-center" data-tgt="hiring-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="14.5%" top="24%" width="7.5%" height="5.5%" dot-class="dot-bottom-center" data-tgt="hiring-v2-candidates" @click.native="onLinkClick"></screen-link>
      <screen-link left="22%" top="24%" width="6%" height="5.5%" dot-class="dot-bottom-center" data-tgt="hiring-v2-job-details" @click.native="onLinkClick"></screen-link>
      <screen-link left="70%" top="42.5%" width="8%" height="5%" dot-class="dot-top-center" data-tgt="hiring-v2-candidates-rated" @click.native="onLinkClick"></screen-link>
      <screen-link left="48%" top="42.5%" width="8%" height="5%" dot-class="dot-top-center" data-tgt="hiring-v2-candidates" @click.native="onLinkClick"></screen-link>
      <screen-link left="35%" top="55.75%" width="59%" height="5%" dot-class="dot-right-center" data-tgt="pullout-candidate" data-init-info="profile" @click.native="showPullout"></screen-link>

      <screen-link left="91.25%" top="23.5%" width="8%" height="5.5%" dot-class="dot-bottom-center" data-tgt="candidate-options" @click.native="$screens.onOverlayPanel"></screen-link>
      <BasicOptionsPanel v-if="$store.state.overlayPanel==='candidate-options'" top="25%" left="88%" width="10%" :items="candidateOptions" :on-select="onCandidateOptionClick" />
      <company-tab-hiring :company="company" @select="onCompanyChange" />
      
    </div>
  </div>

  <div class="screen" data-screen="hiring-v2-job-details" :data-active="currentScreen=='hiring-v2-job-details'">
    <img class="w-full max-w-100" src="/img/screens/hiring/hiring-job2-details.png?v=12222024" />
    <div class="links">
      <screen-link left="15%" top="14%" width="7%" height="3.5%" dot-class="dot-right-center" data-tgt="hiring-home" @click.native="onLinkClick"></screen-link>

      <screen-link left="14.5%" top="24%" width="7.5%" height="5.5%" dot-class="dot-bottom-center" data-tgt="hiring-v2-candidates" @click.native="onLinkClick"></screen-link>
      <screen-link left="22%" top="24%" width="4.5%" height="5.5%" dot-class="dot-bottom-center" data-tgt="hiring-v2-promote" @click.native="onLinkClick"></screen-link>
      <screen-link left="26.5%" top="24%" width="6%" height="5.5%" dot-class="dot-bottom-center" data-tgt="hiring-v2-job-details" @click.native="onLinkClick"></screen-link>

      <screen-link left="91.25%" top="19.5%" width="8%" height="5.5%" dot-class="dot-bottom-center" data-tgt="candidate-options" @click.native="$screens.onOverlayPanel"></screen-link>
      <BasicOptionsPanel v-if="$store.state.overlayPanel==='candidate-options'" top="21%" left="88%" width="10%" :items="candidateOptions" :on-select="onCandidateOptionClick" />
      <company-tab-hiring :company="company" @select="onCompanyChange" />

      <company-tab-hiring :company="company" @select="onCompanyChange" />

    </div>
  </div>

  <div class="screen" data-screen="hiring-v2-promote" :data-active="currentScreen=='hiring-v2-promote'">
    <img class="w-full max-w-100" src="/img/screens/hiring/hiring-job2-promote.png?v=12222024" />
    <div class="links">
      <screen-link left="15%" top="14%" width="7%" height="3.5%" dot-class="dot-right-center" data-tgt="hiring-home" @click.native="onLinkClick"></screen-link>

      <screen-link left="14.5%" top="24%" width="7.5%" height="5.5%" dot-class="dot-bottom-center" data-tgt="hiring-v2-candidates" @click.native="onLinkClick"></screen-link>
      <screen-link left="22%" top="24%" width="4.5%" height="5.5%" dot-class="dot-bottom-center" data-tgt="hiring-v2-promote" @click.native="onLinkClick"></screen-link>
      <screen-link left="26.5%" top="24%" width="6%" height="5.5%" dot-class="dot-bottom-center" data-tgt="hiring-v2-job-details" @click.native="onLinkClick"></screen-link>


      <screen-link left="49.5%" top="104.5%" width="15%" height="5.5%" dot-class="dot-top-center" data-tgt="hiring-jobtarget" @click.native="onLinkClick"></screen-link>

      <screen-link left="91.25%" top="19.5%" width="8%" height="5.5%" dot-class="dot-bottom-center" data-tgt="candidate-options" @click.native="$screens.onOverlayPanel"></screen-link>
      <BasicOptionsPanel v-if="$store.state.overlayPanel==='candidate-options'" top="21%" left="88%" width="10%" :items="candidateOptions" :on-select="onCandidateOptionClick" />
      <company-tab-hiring :company="company" @select="onCompanyChange" />

      <company-tab-hiring :company="company" @select="onCompanyChange" />

    </div>
  </div>

  <div class="screen z-max" data-screen="hiring-jobtarget" :data-active="currentScreen=='hiring-jobtarget'">
    <img class="w-full max-w-100" src="/img/screens/hiring/hiring-job2-jobtarget.png?v=09142023" />
    <div class="links">
      <screen-link left="1%" top="1%" width="98%" height="150%" data-tgt="hiring-v2-promote"  @click.native="onLinkClick"></screen-link>
    </div>
  </div>




  <div class="screen z-max" data-screen="hiring-hiretech" :data-active="currentScreen=='hiring-hiretech'">
    <img class="w-full max-w-100" src="/img/screens/admin-hiring-hiretech.png?v=09142023" />
    <div class="links">
      <screen-link left="1%" top="1%" width="98%" height="98%" data-tgt="hiring-home"  @click.native="onLinkClick"></screen-link>
    </div>
  </div>

  <div class="screen" data-screen="hiring-applicants" :data-active="currentScreen=='hiring-applicants'">
    <img class="w-full max-w-100" src="/img/screens/admin-hiring-applicants.png?v=09142023" />
    <div class="links">
      <screen-link left="15.5%" top="17.5%" width="5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="20.5%" top="17.5%" width="6%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-applicants" @click.native="onLinkClick"></screen-link>
      <screen-link left="26.5%" top="17.5%" width="7%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-requisitions" @click.native="onLinkClick"></screen-link>
      <screen-link left="33.5%" top="17.5%" width="5.5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="39%" top="17.5%" width="7%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-admin" @click.native="onLinkClick"></screen-link>

      <screen-link left="17%" top="38.25%" width="8%" height="3%" dot-class="dot-right-center" data-tgt="hiring-applicants-jobboard" @click.native="onLinkClick"></screen-link>

      <company-tab-hiring :company="company" @select="onCompanyChange" />

    </div>
  </div>

  <div class="screen" data-screen="hiring-applicants-jobboard" :data-active="currentScreen=='hiring-applicants-jobboard'">
    <img class="w-full max-w-100" src="/img/screens/admin-hiring-applicants-jobboard.png?v=09142023" />
    <div class="links">
      <screen-link left="15.5%" top="17.5%" width="5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="20.5%" top="17.5%" width="6%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-applicants" @click.native="onLinkClick"></screen-link>
      <screen-link left="26.5%" top="17.5%" width="7%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-requisitions" @click.native="onLinkClick"></screen-link>
      <screen-link left="33.5%" top="17.5%" width="5.5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="39%" top="17.5%" width="7%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-admin" @click.native="onLinkClick"></screen-link>

      <screen-link left="67%" top="51%" width="10%" height="4.5%" dot-class="dot-right-center" data-tgt="hiring-applicants-jobboard-status" @click.native="onLinkClick"></screen-link>
      <screen-link left="87.25%" top="51%" width="2%" height="4.75%" dot-class="dot-bottom-center" data-tgt="hiring-applicants-jobboard-info-popup" @click.native="onLinkClick"></screen-link>
      <screen-link left="89.25%" top="51%" width="1.5%" height="4.75%" dot-class="dot-bottom-center" data-tgt="hiring-applicants-jobboard-reqs-popup" @click.native="onLinkClick"></screen-link>
      <screen-link left="90.75%" top="51%" width="1.75%" height="4.75%" dot-class="dot-bottom-center" data-tgt="hiring-applicants-jobboard-documents" @click.native="onLinkClick"></screen-link>

      <screen-link left="43%" top="51%" width="6.5%" height="4.75%" dot-class="dot-right-center" data-tgt="hiring-applicants-requisition" @click.native="onLinkClick"></screen-link>

      <company-tab-hiring :company="company" @select="onCompanyChange" />
    </div>
  </div>

  <div class="screen" data-screen="hiring-applicants-requisition" :data-active="currentScreen=='hiring-applicants-requisition'">
    <img class="w-full max-w-100" src="/img/screens/admin-hiring-applicants-requisition.png?v=09142023" />
    <div class="links">
      <screen-link left="15.5%" top="17.5%" width="5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="20.5%" top="17.5%" width="6%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-applicants" @click.native="onLinkClick"></screen-link>
      <screen-link left="26.5%" top="17.5%" width="7%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-requisitions" @click.native="onLinkClick"></screen-link>
      <screen-link left="33.5%" top="17.5%" width="5.5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="39%" top="17.5%" width="7%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-admin" @click.native="onLinkClick"></screen-link>

      <screen-link left="87%" top="46%" width="4.5%" height="4%" dot-class="dot-right-center" data-tgt="hiring-applicants-requisition-edit" @click.native="onLinkClick"></screen-link>
      <screen-link left="23%" top="23%" width="6%" height="4%" dot-class="dot-bottom-center" data-tgt="hiring-applicants-requisition" @click.native="onLinkClick"></screen-link>
      <screen-link left="35%" top="23%" width="5%" height="4%" dot-class="dot-bottom-center" data-tgt="hiring-applicants-documents" @click.native="onLinkClick"></screen-link>
      <company-tab-hiring :company="company" @select="onCompanyChange" />
    </div>
  </div>

  <div class="screen" data-screen="hiring-applicants-documents" :data-active="currentScreen=='hiring-applicants-documents'">
    <img class="w-full max-w-100" src="/img/screens/admin-hiring-applicants-documents.png?v=09142023" />
    <div class="links">
      <screen-link left="15.5%" top="17.5%" width="5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="20.5%" top="17.5%" width="6%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-applicants" @click.native="onLinkClick"></screen-link>
      <screen-link left="26.5%" top="17.5%" width="7%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-requisitions" @click.native="onLinkClick"></screen-link>
      <screen-link left="33.5%" top="17.5%" width="5.5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="39%" top="17.5%" width="7%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-admin" @click.native="onLinkClick"></screen-link>

      <screen-link left="23.2%" top="24.5%" width="6%" height="4%" dot-class="dot-bottom-center" data-tgt="hiring-applicants-requisition" @click.native="onLinkClick"></screen-link>
      <screen-link left="35.2%" top="24.5%" width="5%" height="4%" dot-class="dot-bottom-center" data-tgt="hiring-applicants-documents" @click.native="onLinkClick"></screen-link>
      <company-tab-hiring :company="company" @select="onCompanyChange" />
    </div>
  </div>

  <div class="screen" data-screen="hiring-applicants-requisition-edit" :data-active="currentScreen=='hiring-applicants-requisition-edit'">
    <img class="w-full max-w-100" src="/img/screens/admin-hiring-applicants-requisition-edit.png?v=09142023" />
    <div class="links">
      <screen-link left="15.5%" top="17.5%" width="5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="20.5%" top="17.5%" width="6%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-applicants" @click.native="onLinkClick"></screen-link>
      <screen-link left="26.5%" top="17.5%" width="7%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-requisitions" @click.native="onLinkClick"></screen-link>
      <screen-link left="33.5%" top="17.5%" width="5.5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="39%" top="17.5%" width="7%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-admin" @click.native="onLinkClick"></screen-link>

      <screen-link left="18%" top="108%" width="3.5%" height="4%" dot-class="dot-bottom-center" data-tgt="hiring-applicants-requisition-email" @click.native="onLinkClick"></screen-link>
      <screen-link left="22%" top="108%" width="3.5%" height="4%" dot-class="dot-bottom-center" data-tgt="hiring-applicants-requisition" @click.native="onLinkClick"></screen-link>
      <company-tab-hiring :company="company" @select="onCompanyChange" />
    </div>
  </div>

  <div class="screen" data-screen="hiring-applicants-requisition-email" :data-active="currentScreen=='hiring-applicants-requisition-email'">
    <img class="w-full max-w-100" src="/img/screens/admin-hiring-applicants-requisition-email.png?v=09142023" />
    <div class="links">
      <screen-link left="15.5%" top="17.5%" width="5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="20.5%" top="17.5%" width="6%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-applicants" @click.native="onLinkClick"></screen-link>
      <screen-link left="26.5%" top="17.5%" width="7%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-requisitions" @click.native="onLinkClick"></screen-link>
      <screen-link left="33.5%" top="17.5%" width="5.5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="39%" top="17.5%" width="7%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-admin" @click.native="onLinkClick"></screen-link>

      <screen-link left="17.75%" top="62%" width="5.5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="onboarding-email" data-tgt-mode="onboarding" @click.native="$screens.onLinkClick"></screen-link>
      <company-tab-hiring :company="company" @select="onCompanyChange" />
    </div>
  </div>

  <div class="screen" data-screen="hiring-applicants-jobboard-status" :data-active="currentScreen=='hiring-applicants-jobboard-status'">
    <img class="w-full max-w-100" src="/img/screens/admin-hiring-applicants-jobboard-status.png?v=09142023" />
    <div class="links">
      <screen-link left="15.5%" top="17.5%" width="5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="20.5%" top="17.5%" width="6%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-applicants" @click.native="onLinkClick"></screen-link>
      <screen-link left="26.5%" top="17.5%" width="7%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-requisitions" @click.native="onLinkClick"></screen-link>
      <screen-link left="33.5%" top="17.5%" width="5.5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="39%" top="17.5%" width="7%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-admin" @click.native="onLinkClick"></screen-link>

      <screen-link left="87.25%" top="51%" width="2%" height="4.75%" dot-class="dot-bottom-center" data-tgt="hiring-applicants-jobboard-info-popup" @click.native="onLinkClick"></screen-link>
      <screen-link left="89.25%" top="51%" width="1.5%" height="4.75%" dot-class="dot-bottom-center" data-tgt="hiring-applicants-jobboard-reqs-popup" @click.native="onLinkClick"></screen-link>
      <screen-link left="90.75%" top="51%" width="1.75%" height="4.75%" dot-class="dot-bottom-center" data-tgt="hiring-applicants-jobboard-documents" @click.native="onLinkClick"></screen-link>

      <screen-link left="53%" top="63.2%" width="18%" height="3.25%" dot-class="dot-right-center" data-tgt="hiring-applicants-jobboard-status-dropdown" @click.native="onLinkClick"></screen-link>
      <screen-link left="62.5%" top="79%" width="8%" height="3.25%" dot-class="dot-right-center" data-tgt="hiring-applicants-jobboard" @click.native="onLinkClick"></screen-link>

      <screen-link left="43%" top="51%" width="6.5%" height="4.75%" dot-class="dot-right-center" data-tgt="hiring-applicants-requisition" @click.native="onLinkClick"></screen-link>
      <company-tab-hiring :company="company" @select="onCompanyChange" />

    </div>
  </div>

  <div class="screen" data-screen="hiring-applicants-jobboard-status-signed" :data-active="currentScreen=='hiring-applicants-jobboard-status-signed'">
    <img class="w-full max-w-100" src="/img/screens/admin-hiring-applicants-jobboard-status-signed.png?v=09142023" />
    <div class="links">
      <screen-link left="15.5%" top="17.5%" width="5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="20.5%" top="17.5%" width="6%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-applicants" @click.native="onLinkClick"></screen-link>
      <screen-link left="26.5%" top="17.5%" width="7%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-requisitions" @click.native="onLinkClick"></screen-link>
      <screen-link left="33.5%" top="17.5%" width="5.5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="39%" top="17.5%" width="7%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-admin" @click.native="onLinkClick"></screen-link>

      <screen-link left="87.25%" top="51%" width="2%" height="4.75%" dot-class="dot-bottom-center" data-tgt="hiring-applicants-jobboard-info-popup" @click.native="onLinkClick"></screen-link>
      <screen-link left="89.25%" top="51%" width="1.5%" height="4.75%" dot-class="dot-bottom-center" data-tgt="hiring-applicants-jobboard-reqs-popup" @click.native="onLinkClick"></screen-link>
      <screen-link left="90.75%" top="51%" width="1.75%" height="4.75%" dot-class="dot-bottom-center" data-tgt="hiring-applicants-jobboard-documents" @click.native="onLinkClick"></screen-link>


      <screen-link left="65%" top="79%" width="3.7%" height="3.25%" dot-class="dot-bottom-center" data-tgt="hiring-applicants-requisition-email" @click.native="onLinkClick"></screen-link>

      <screen-link left="43%" top="51%" width="6.5%" height="4.75%" dot-class="dot-right-center" data-tgt="hiring-applicants-requisition" @click.native="onLinkClick"></screen-link>
      <company-tab-hiring :company="company" @select="onCompanyChange" />

    </div>
  </div>

  <div class="screen" data-screen="hiring-applicants-jobboard-status-dropdown" :data-active="currentScreen=='hiring-applicants-jobboard-status-dropdown'">
    <img class="w-full max-w-100" src="/img/screens/admin-hiring-applicants-jobboard-status-dropdown.png?v=09142023" />
    <div class="links">
      <screen-link left="15.5%" top="17.5%" width="5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="20.5%" top="17.5%" width="6%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-applicants" @click.native="onLinkClick"></screen-link>
      <screen-link left="26.5%" top="17.5%" width="7%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-requisitions" @click.native="onLinkClick"></screen-link>
      <screen-link left="33.5%" top="17.5%" width="5.5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="39%" top="17.5%" width="7%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-admin" @click.native="onLinkClick"></screen-link>

      <screen-link left="87.25%" top="51%" width="2%" height="4.75%" dot-class="dot-bottom-center" data-tgt="hiring-applicants-jobboard-info-popup" @click.native="onLinkClick"></screen-link>
      <screen-link left="89.25%" top="51%" width="1.5%" height="4.75%" dot-class="dot-bottom-center" data-tgt="hiring-applicants-jobboard-reqs-popup" @click.native="onLinkClick"></screen-link>
      <screen-link left="90.75%" top="51%" width="1.75%" height="4.75%" dot-class="dot-bottom-center" data-tgt="hiring-applicants-jobboard-documents" @click.native="onLinkClick"></screen-link>


      <screen-link left="49%" top="71%" width="10%" height="3.25%" dot-class="dot-right-center" data-tgt="hiring-applicants-jobboard-status-signed" @click.native="onLinkClick"></screen-link>

      <screen-link left="42.5%" top="51%" width="6%" height="4.75%" dot-class="dot-left-center" data-tgt="hiring-applicants-requisition" @click.native="onLinkClick"></screen-link>
      <company-tab-hiring :company="company" @select="onCompanyChange" />

    </div>
  </div>

  <!-- Manager Sign -->
  <div class="screen" data-screen="hiring-applicants-jobboard-manager-sign" :data-active="currentScreen=='hiring-applicants-jobboard-manager-sign'">
    <img class="w-full max-w-100" src="/img/screens/admin-hiring-applicants-jobboard-manager-sign.png?v=09142023" />
    <div class="links">
      <screen-link left="15.5%" top="17.5%" width="5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="20.5%" top="17.5%" width="6%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-applicants" @click.native="onLinkClick"></screen-link>
      <screen-link left="26.5%" top="17.5%" width="7%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-requisitions" @click.native="onLinkClick"></screen-link>
      <screen-link left="33.5%" top="17.5%" width="5.5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="39%" top="17.5%" width="7%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-admin" @click.native="onLinkClick"></screen-link>



      <screen-link left="66.5%" top="51%" width="8%" height="4.75%" dot-class="dot-right-center" data-tgt="hiring-applicants-jobboard-manager-sign-status" @click.native="onLinkClick"></screen-link>


      <screen-link left="43%" top="51%" width="6.5%" height="4.75%" dot-class="dot-right-center" data-tgt="hiring-applicants-requisition" @click.native="onLinkClick"></screen-link>
      <company-tab-hiring :company="company" @select="onCompanyChange" />

    </div>
  </div>

  <!--- FINAL DESTINATION OF MANAGER ONBOARDING FLOW -->

  <div class="screen" data-screen="hiring-applicants-jobboard-sign-complete" :data-active="currentScreen=='hiring-applicants-jobboard-sign-complete'">
    <img class="w-full max-w-100" src="/img/screens/admin-hiring-applicants-jobboard-sign-complete.png?v=09142023" />
    <div class="links">
      <screen-link left="15.5%" top="17.5%" width="5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="20.5%" top="17.5%" width="6%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-applicants" @click.native="onLinkClick"></screen-link>
      <screen-link left="26.5%" top="17.5%" width="7%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-requisitions" @click.native="onLinkClick"></screen-link>
      <screen-link left="33.5%" top="17.5%" width="5.5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="39%" top="17.5%" width="7%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-admin" @click.native="onLinkClick"></screen-link>






      <screen-link left="43%" top="51%" width="6.5%" height="4.75%" dot-class="dot-right-center" data-tgt="hiring-applicants-requisition" @click.native="onLinkClick"></screen-link>
      <company-tab-hiring :company="company" @select="onCompanyChange" />

    </div>
  </div>

  <div class="screen" data-screen="hiring-applicants-jobboard-manager-sign-status" :data-active="currentScreen=='hiring-applicants-jobboard-manager-sign-status'">
    <img class="w-full max-w-100" src="/img/screens/admin-hiring-applicants-jobboard-manager-sign-status.png?v=09142023" />
    <div class="links">
      <screen-link left="15.5%" top="17.5%" width="5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="20.5%" top="17.5%" width="6%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-applicants" @click.native="onLinkClick"></screen-link>
      <screen-link left="26.5%" top="17.5%" width="7%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-requisitions" @click.native="onLinkClick"></screen-link>
      <screen-link left="33.5%" top="17.5%" width="5.5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="39%" top="17.5%" width="7%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-admin" @click.native="onLinkClick"></screen-link>

      <screen-link left="43%" top="51%" width="6.5%" height="4.75%" dot-class="dot-right-center" data-tgt="hiring-applicants-requisition" @click.native="onLinkClick"></screen-link>
      <screen-link left="59.5%" top="78%" width="4%" height="4.75%" dot-class="dot-right-center" data-tgt="hiring-applicants-jobboard-manager-sign-launch" @click.native="onLinkClick"></screen-link>
      <company-tab-hiring :company="company" @select="onCompanyChange" />

    </div>
  </div>

  <div class="screen" data-screen="hiring-applicants-jobboard-manager-sign-launch" :data-active="currentScreen=='hiring-applicants-jobboard-manager-sign-launch'">
    <img class="w-full max-w-100" src="/img/screens/admin-hiring-applicants-jobboard-manager-sign-launch.png?v=09142023" />
    <div class="links">
      <screen-link left="15.5%" top="17.5%" width="5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="20.5%" top="17.5%" width="6%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-applicants" @click.native="onLinkClick"></screen-link>
      <screen-link left="26.5%" top="17.5%" width="7%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-requisitions" @click.native="onLinkClick"></screen-link>
      <screen-link left="33.5%" top="17.5%" width="5.5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="39%" top="17.5%" width="7%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-admin" @click.native="onLinkClick"></screen-link>

      <screen-link left="18%" top="36%" width="5%" height="4.75%" dot-class="dot-right-center" data-tgt="onboarding-manager-begin" data-tgt-mode="onboarding" @click.native="onLinkClick"></screen-link>
      <company-tab-hiring :company="company" @select="onCompanyChange" />

    </div>
  </div>


  <div class="screen" data-screen="hiring-applicants-jobboard-info-popup" :data-active="currentScreen=='hiring-applicants-jobboard-info-popup'">
    <img class="w-full max-w-100" src="/img/screens/admin-hiring-applicants-jobboard-info-popup.png?v=09142023" />
    <div class="links">
      <screen-link left="15.5%" top="17.5%" width="5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="20.5%" top="17.5%" width="6%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-applicants" @click.native="onLinkClick"></screen-link>
      <screen-link left="26.5%" top="17.5%" width="7%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-requisitions" @click.native="onLinkClick"></screen-link>
      <screen-link left="33.5%" top="17.5%" width="5.5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="39%" top="17.5%" width="7%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-admin" @click.native="onLinkClick"></screen-link>

      <screen-link left="67%" top="51%" width="10%" height="4.5%" dot-class="dot-right-center" data-tgt="hiring-applicants-jobboard-status" @click.native="onLinkClick"></screen-link>

      <screen-link left="89.25%" top="51%" width="1.5%" height="4.75%" dot-class="dot-bottom-center" data-tgt="hiring-applicants-jobboard-reqs-popup" @click.native="onLinkClick"></screen-link>
      <screen-link left="90.75%" top="51%" width="1.75%" height="4.75%" dot-class="dot-bottom-center" data-tgt="hiring-applicants-jobboard-documents" @click.native="onLinkClick"></screen-link>

      <screen-link left="43%" top="51%" width="6.5%" height="4.75%" dot-class="dot-right-center" data-tgt="hiring-applicants-requisition" @click.native="onLinkClick"></screen-link>

      <screen-link left="85.5%" top="57%" width="4.5%" height="3.25%" dot-class="dot-bottom-center" data-tgt="hiring-applicants-jobboard" @click.native="onLinkClick"></screen-link>

      <company-tab-hiring :company="company" @select="onCompanyChange" />

    </div>
  </div>

  <div class="screen" data-screen="hiring-applicants-jobboard-reqs-popup" :data-active="currentScreen=='hiring-applicants-jobboard-reqs-popup'">
    <img class="w-full max-w-100" src="/img/screens/admin-hiring-applicants-jobboard-reqs-popup.png?v=09142023" />
    <div class="links">
      <screen-link left="15.5%" top="17.5%" width="5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="20.5%" top="17.5%" width="6%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-applicants" @click.native="onLinkClick"></screen-link>
      <screen-link left="26.5%" top="17.5%" width="7%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-requisitions" @click.native="onLinkClick"></screen-link>
      <screen-link left="33.5%" top="17.5%" width="5.5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="39%" top="17.5%" width="7%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-admin" @click.native="onLinkClick"></screen-link>

      <screen-link left="67%" top="51%" width="10%" height="4.5%" dot-class="dot-right-center" data-tgt="hiring-applicants-jobboard-status" @click.native="onLinkClick"></screen-link>
      <screen-link left="87.25%" top="51%" width="2%" height="4.75%" dot-class="dot-bottom-center" data-tgt="hiring-applicants-jobboard-info-popup" @click.native="onLinkClick"></screen-link>

      <screen-link left="90.75%" top="51%" width="1.75%" height="4.75%" dot-class="dot-bottom-center" data-tgt="hiring-applicants-jobboard-documents" @click.native="onLinkClick"></screen-link>

      <screen-link left="43%" top="51%" width="6.5%" height="4.75%" dot-class="dot-right-center" data-tgt="hiring-applicants-requisition" @click.native="onLinkClick"></screen-link>

      <screen-link left="87%" top="57%" width="4.5%" height="3.25%" dot-class="dot-bottom-center" data-tgt="hiring-applicants-jobboard" @click.native="onLinkClick"></screen-link>

      <company-tab-hiring :company="company" @select="onCompanyChange" />


    </div>
  </div>

  <div class="screen" data-screen="hiring-applicants-jobboard-documents" :data-active="currentScreen=='hiring-applicants-jobboard-documents'">
    <img class="w-full max-w-100" src="/img/screens/admin-hiring-applicants-jobboard-documents.png?v=09142023" />
    <div class="links">
      <screen-link left="15.5%" top="17.5%" width="5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="20.5%" top="17.5%" width="6%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-applicants" @click.native="onLinkClick"></screen-link>
      <screen-link left="26.5%" top="17.5%" width="7%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-requisitions" @click.native="onLinkClick"></screen-link>
      <screen-link left="33.5%" top="17.5%" width="5.5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="39%" top="17.5%" width="7%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-admin" @click.native="onLinkClick"></screen-link>
      <company-tab-hiring :company="company" @select="onCompanyChange" />



    </div>
  </div>



  <div class="screen" data-screen="hiring-requisitions" :data-active="currentScreen=='hiring-requisitions'">
    <img class="w-full max-w-100" src="/img/screens/admin-hiring-requisitions.png?v=09142023" />
    <div class="links">
      <screen-link left="15.5%" top="17.5%" width="5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="20.5%" top="17.5%" width="6%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-applicants" @click.native="onLinkClick"></screen-link>
      <screen-link left="26.5%" top="17.5%" width="7%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-requisitions" @click.native="onLinkClick"></screen-link>
      <screen-link left="33.5%" top="17.5%" width="5.5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="39%" top="17.5%" width="7%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-admin" @click.native="onLinkClick"></screen-link>

      <screen-link left="89.75%" top="30.5%" width="6.75%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-requisitions-add" @click.native="onLinkClick"></screen-link>
      <company-tab-hiring :company="company" @select="onCompanyChange" />
    </div>
  </div>

  <div class="screen" data-screen="hiring-requisitions-add" :data-active="currentScreen=='hiring-requisitions-add'">
    <img class="w-full max-w-100" src="/img/screens/admin-hiring-requisitions-add.png?v=09142023" />
    <div class="links">
      <screen-link left="15.5%" top="17.5%" width="5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="20.5%" top="17.5%" width="6%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-applicants" @click.native="onLinkClick"></screen-link>
      <screen-link left="26.5%" top="17.5%" width="7%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-requisitions" @click.native="onLinkClick"></screen-link>
      <screen-link left="33.5%" top="17.5%" width="5.5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="39%" top="17.5%" width="7%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-admin" @click.native="onLinkClick"></screen-link>

      <screen-link left="89.5%" top="26%" width="6%" height="3.5%" dot-class="dot-left-center" data-tgt="hiring-requisitions" @click.native="onLinkClick"></screen-link>
      <company-tab-hiring :company="company" @select="onCompanyChange" />
    </div>
  </div>

  <div class="screen" data-screen="hiring-requisitions-details" :data-active="currentScreen=='hiring-requisitions-details'">
    <img class="w-full max-w-100" src="/img/screens/admin-hiring-requisitions-details.png?v=09142023" />
    <div class="links">
      <screen-link left="15.5%" top="17.5%" width="5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="20.5%" top="17.5%" width="6%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-applicants" @click.native="onLinkClick"></screen-link>
      <screen-link left="26.5%" top="17.5%" width="7%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-requisitions" @click.native="onLinkClick"></screen-link>
      <screen-link left="33.5%" top="17.5%" width="5.5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="39%" top="17.5%" width="7%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-admin" @click.native="onLinkClick"></screen-link>

      <screen-link left="70.6%" top="30.5%" width="7%" height="4%" dot-class="dot-top-center" data-tgt="hiring-applicants-jobboard" @click.native="onLinkClick"></screen-link>
      <screen-link left="25%" top="91.5%" width="24%" height="4%" dot-class="dot-right-center" data-tgt-mode="applicant" data-tgt="applicant-posting" @click.native="onLinkClick"></screen-link>
      <company-tab-hiring :company="company" @select="onCompanyChange" />
    </div>
  </div>

  <div class="screen" data-screen="hiring-reports" :data-active="currentScreen=='hiring-reports'">
    <img class="w-full max-w-100" src="/img/screens/admin-hiring-reports.png?v=09142023" />
    <div class="links">
      <screen-link left="15.5%" top="17.5%" width="5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="20.5%" top="17.5%" width="6%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-applicants" @click.native="onLinkClick"></screen-link>
      <screen-link left="26.5%" top="17.5%" width="7%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-requisitions" @click.native="onLinkClick"></screen-link>
      <screen-link left="33.5%" top="17.5%" width="5.5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="39%" top="17.5%" width="7%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-admin" @click.native="onLinkClick"></screen-link>

      <screen-link left="16.75%" top="23%" width="7%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="24%" top="23%" width="8.5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-reports-mysaved" @click.native="onLinkClick"></screen-link>
      <screen-link left="32.75%" top="23%" width="6.5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-reports-adhoc" @click.native="onLinkClick"></screen-link>
      <screen-link left="39.5%" top="23%" width="8%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-reports-sourcing" @click.native="onLinkClick"></screen-link>
      <screen-link left="48%" top="23%" width="6%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-reports-metrics" @click.native="onLinkClick"></screen-link>

      <screen-link left="17.5%" top="34%" width="10%" height="3%" data-tgt="hiring-reports-applicant-spreadsheet" @click.native="onLinkClick"></screen-link>
      <company-tab-hiring :company="company" @select="onCompanyChange" />
    </div>
  </div>

  <div class="screen" data-screen="hiring-reports-applicant-spreadsheet" :data-active="currentScreen=='hiring-reports-applicant-spreadsheet'">
    <img class="w-full max-w-100" src="/img/screens/admin-hiring-reports-applicant-spreadsheet.png?v=09142023" />
    <div class="links">
      <screen-link left="15.5%" top="17.5%" width="5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="20.5%" top="17.5%" width="6%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-applicants" @click.native="onLinkClick"></screen-link>
      <screen-link left="26.5%" top="17.5%" width="7%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-requisitions" @click.native="onLinkClick"></screen-link>
      <screen-link left="33.5%" top="17.5%" width="5.5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="39%" top="17.5%" width="7%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-admin" @click.native="onLinkClick"></screen-link>

      <screen-link left="92.5%" top="29%" width="4%" height="4.5%" data-tgt="hiring-reports" @click.native="onLinkClick"></screen-link>
      <company-tab-hiring :company="company" @select="onCompanyChange" />
    </div>
  </div>

  <div class="screen" data-screen="hiring-reports-mysaved" :data-active="currentScreen=='hiring-reports-mysaved'">
    <img class="w-full max-w-100" src="/img/screens/admin-hiring-reports-mysaved.png?v=09142023" />
    <div class="links">
      <screen-link left="15.5%" top="17.5%" width="5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="20.5%" top="17.5%" width="6%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-applicants" @click.native="onLinkClick"></screen-link>
      <screen-link left="26.5%" top="17.5%" width="7%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-requisitions" @click.native="onLinkClick"></screen-link>
      <screen-link left="33.5%" top="17.5%" width="5.5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="39%" top="17.5%" width="7%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-admin" @click.native="onLinkClick"></screen-link>

      <screen-link left="16.75%" top="23%" width="7%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="24%" top="23%" width="8.5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-reports-mysaved" @click.native="onLinkClick"></screen-link>
      <screen-link left="32.75%" top="23%" width="6.5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-reports-adhoc" @click.native="onLinkClick"></screen-link>
      <screen-link left="39.5%" top="23%" width="8%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-reports-sourcing" @click.native="onLinkClick"></screen-link>
      <screen-link left="48%" top="23%" width="6%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-reports-metrics" @click.native="onLinkClick"></screen-link>
      <company-tab-hiring :company="company" @select="onCompanyChange" />
    </div>
  </div>

  <div class="screen" data-screen="hiring-reports-adhoc" :data-active="currentScreen=='hiring-reports-adhoc'">
    <img class="w-full max-w-100" src="/img/screens/admin-hiring-reports-adhoc.png?v=09142023" />
    <div class="links">
      <screen-link left="15.5%" top="17.5%" width="5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="20.5%" top="17.5%" width="6%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-applicants" @click.native="onLinkClick"></screen-link>
      <screen-link left="26.5%" top="17.5%" width="7%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-requisitions" @click.native="onLinkClick"></screen-link>
      <screen-link left="33.5%" top="17.5%" width="5.5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="39%" top="17.5%" width="7%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-admin" @click.native="onLinkClick"></screen-link>

      <screen-link left="16.75%" top="23%" width="7%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="24%" top="23%" width="8.5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-reports-mysaved" @click.native="onLinkClick"></screen-link>
      <screen-link left="32.75%" top="23%" width="6.5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-reports-adhoc" @click.native="onLinkClick"></screen-link>
      <screen-link left="39.5%" top="23%" width="8%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-reports-sourcing" @click.native="onLinkClick"></screen-link>
      <screen-link left="48%" top="23%" width="6%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-reports-metrics" @click.native="onLinkClick"></screen-link>
      <company-tab-hiring :company="company" @select="onCompanyChange" />
    </div>
  </div>

  <div class="screen" data-screen="hiring-reports-sourcing" :data-active="currentScreen=='hiring-reports-sourcing'">
    <img class="w-full max-w-100" src="/img/screens/admin-hiring-reports-sourcing.png?v=09142023" />
    <div class="links">
      <screen-link left="15.5%" top="17.5%" width="5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="20.5%" top="17.5%" width="6%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-applicants" @click.native="onLinkClick"></screen-link>
      <screen-link left="26.5%" top="17.5%" width="7%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-requisitions" @click.native="onLinkClick"></screen-link>
      <screen-link left="33.5%" top="17.5%" width="5.5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="39%" top="17.5%" width="7%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-admin" @click.native="onLinkClick"></screen-link>

      <screen-link left="16.75%" top="23%" width="7%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="24%" top="23%" width="8.5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-reports-mysaved" @click.native="onLinkClick"></screen-link>
      <screen-link left="32.75%" top="23%" width="6.5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-reports-adhoc" @click.native="onLinkClick"></screen-link>
      <screen-link left="39.5%" top="23%" width="8%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-reports-sourcing" @click.native="onLinkClick"></screen-link>
      <screen-link left="48%" top="23%" width="6%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-reports-metrics" @click.native="onLinkClick"></screen-link>
      <company-tab-hiring :company="company" @select="onCompanyChange" />
    </div>
  </div>

  <div class="screen" data-screen="hiring-reports-metrics" :data-active="currentScreen=='hiring-reports-metrics'">
    <img class="w-full max-w-100" src="/img/screens/admin-hiring-reports-metrics.png?v=09142023" />
    <div class="links">
      <screen-link left="15.5%" top="17.5%" width="5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="20.5%" top="17.5%" width="6%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-applicants" @click.native="onLinkClick"></screen-link>
      <screen-link left="26.5%" top="17.5%" width="7%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-requisitions" @click.native="onLinkClick"></screen-link>
      <screen-link left="33.5%" top="17.5%" width="5.5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="39%" top="17.5%" width="7%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-admin" @click.native="onLinkClick"></screen-link>

      <screen-link left="16.75%" top="23%" width="7%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="24%" top="23%" width="8.5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-reports-mysaved" @click.native="onLinkClick"></screen-link>
      <screen-link left="32.75%" top="23%" width="6.5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-reports-adhoc" @click.native="onLinkClick"></screen-link>
      <screen-link left="39.5%" top="23%" width="8%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-reports-sourcing" @click.native="onLinkClick"></screen-link>
      <screen-link left="48%" top="23%" width="6%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-reports-metrics" @click.native="onLinkClick"></screen-link>
      <company-tab-hiring :company="company" @select="onCompanyChange" />
    </div>
  </div>

  <div class="screen" data-screen="hiring-admin" :data-active="currentScreen=='hiring-admin'">
    <img class="w-full max-w-100" src="/img/screens/admin-hiring-admin.png?v=09142023" />
    <div class="links">
      <screen-link left="15.5%" top="17.5%" width="5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-home" @click.native="onLinkClick"></screen-link>
      <screen-link left="20.5%" top="17.5%" width="6%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-applicants" @click.native="onLinkClick"></screen-link>
      <screen-link left="26.5%" top="17.5%" width="7%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-requisitions" @click.native="onLinkClick"></screen-link>
      <screen-link left="33.5%" top="17.5%" width="5.5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-reports" @click.native="onLinkClick"></screen-link>
      <screen-link left="39%" top="17.5%" width="7%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-admin" @click.native="onLinkClick"></screen-link>
      <company-tab-hiring :company="company" @select="onCompanyChange" />
    </div>
  </div>

  <!--- NO RECRUITING -->
  <div class="screen" data-screen="hiring-norecruit" :data-active="currentScreen=='hiring-norecruit'">
    <img class="w-full max-w-100" src="/img/screens/admin-hiring-norecruit.png?v=09142023" />
    <div class="links">
      <screen-link left="15.5%" top="17.5%" width="5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-norecruit" @click.native="onLinkClick"></screen-link>
      <screen-link left="20.5%" top="17.5%" width="6%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-norecruit-workers" @click.native="onLinkClick"></screen-link>


      <company-tab-hiring :company="company" @select="onCompanyChange" />


    </div>
  </div>

  <div class="screen" data-screen="hiring-norecruit-workers" :data-active="currentScreen=='hiring-norecruit-workers'">
    <img class="w-full max-w-100" src="/img/screens/admin-hiring-norecruit-workers.png?v=09142023" />
    <div class="links">
      <screen-link left="15.5%" top="17.5%" width="5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-norecruit" @click.native="onLinkClick"></screen-link>
      <screen-link left="20.5%" top="17.5%" width="6%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-norecruit-workers" @click.native="onLinkClick"></screen-link>

      <screen-link left="17%" top="33%" width="12%" height="5.5%" dot-class="dot-left-center" data-tgt="hiring-norecruit-workers-add" @click.native="onLinkClick"></screen-link>


      <company-tab-hiring :company="company" @select="onCompanyChange" />


    </div>
  </div>

  <div class="screen" data-screen="hiring-norecruit-workers-add" :data-active="currentScreen=='hiring-norecruit-workers-add'">
    <img class="w-full max-w-100" src="/img/screens/admin-hiring-norecruit-workers-add.png?v=09142023" />
    <div class="links">
      <screen-link left="15.5%" top="17.5%" width="5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-norecruit" @click.native="onLinkClick"></screen-link>
      <screen-link left="20.5%" top="17.5%" width="6%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-norecruit-workers" @click.native="onLinkClick"></screen-link>

      <screen-link left="17%" top="40.5%" width="36%" height="4.5%" dot-class="dot-left-center" data-tgt="hiring-norecruit-workers-add-details" @click.native="onLinkClick"></screen-link>


      <company-tab-hiring :company="company" @select="onCompanyChange" />


    </div>
  </div>

  <div class="screen" data-screen="hiring-norecruit-workers-add-details" :data-active="currentScreen=='hiring-norecruit-workers-add-details'">
    <img class="w-full max-w-100" src="/img/screens/admin-hiring-norecruit-workers-add-details.png?v=09142023" />
    <div class="links">
      <screen-link left="15.5%" top="17.5%" width="5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-norecruit" @click.native="onLinkClick"></screen-link>
      <screen-link left="20.5%" top="17.5%" width="6%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-norecruit-workers" @click.native="onLinkClick"></screen-link>

      <screen-link left="22%" top="126%" width="7%" height="4.5%" dot-class="dot-right-center" data-tgt="onboarding-email-norecruit" data-tgt-mode="onboarding" @click.native="$screens.onLinkClick"></screen-link>


      <company-tab-hiring :company="company" @select="onCompanyChange" />


    </div>
  </div>

  <!--- ONBOARDING ESSENTIALS -->

  <div class="screen" data-screen="hiring-essentials" :data-active="currentScreen=='hiring-essentials'">
    <img class="w-full max-w-100" src="/img/screens/admin-hiring-essentials.png?v=09142023" />
    <div class="links">
      <screen-link left="17%" top="19.5%" width="19.5%" height="11%" dot-class="dot-left-center" data-tgt="hiring-essentials-add" @click.native="onLinkClick"></screen-link>

      <company-tab-hiring :company="company" @select="onCompanyChange" />



    </div>
  </div>

  <div class="screen" data-screen="hiring-essentials-add" :data-active="currentScreen=='hiring-essentials-add'">
    <img class="w-full max-w-100" src="/img/screens/admin-hiring-essentials-add.png?v=09142023" />
    <div class="links">
      <screen-link left="17%" top="41%" width="19.5%" height="5%" dot-class="dot-left-center" data-tgt="hiring-essentials-add-details" @click.native="onLinkClick"></screen-link>

      <company-tab-hiring :company="company" @select="onCompanyChange" />

    </div>
  </div>

  <div class="screen" data-screen="hiring-essentials-add-details" :data-active="currentScreen=='hiring-essentials-add-details'">
    <img class="w-full max-w-100" src="/img/screens/admin-hiring-essentials-add-details.png?v=09142023" />
    <div class="links">
      <screen-link left="80%" top="86%" width="9%" height="5%" dot-class="dot-left-center" data-tgt="onboarding-essentials-email" data-tgt-mode="onboardingess" @click.native="onLinkClick"></screen-link>

      <company-tab-hiring :company="company" @select="onCompanyChange" />

    </div>
  </div>

  <div class="screen" data-screen="hiring-essentials-worker-added" :data-active="currentScreen=='hiring-essentials-worker-added'">
    <img class="w-full max-w-100" src="/img/screens/admin-hiring-essentials-worker-added.png?v=09142023" />
    <div class="links">
      <screen-link left="17%" top="19.5%" width="19.5%" height="11%" dot-class="dot-left-center" data-tgt="hiring-essentials-add" @click.native="onLinkClick"></screen-link>
      <screen-link left="75%" top="48%" width="21.5%" height="5%" dot-class="dot-right-center" data-tgt="onboarding-essentials-email" data-tgt-mode="onboardingess" @click.native="onLinkClick"></screen-link>

      <company-tab-hiring :company="company" @select="onCompanyChange" />

    </div>
  </div>

  <div class="screen" data-screen="hiring-essentials-worker-i9-review" :data-active="currentScreen=='hiring-essentials-worker-i9-review'">
    <img class="w-full max-w-100" src="/img/screens/admin-hiring-essentials-worker-i9-review.png?v=09142023" />
    <div class="links">
      <screen-link left="17%" top="19.5%" width="19.5%" height="11%" dot-class="dot-left-center" data-tgt="hiring-essentials-add" @click.native="onLinkClick"></screen-link>
      <screen-link left="75%" top="48%" width="21.5%" height="5%" dot-class="dot-right-center" data-tgt="onboarding-essentials-i9-review" data-tgt-mode="onboardingess" @click.native="onLinkClick"></screen-link>

      <company-tab-hiring :company="company" @select="onCompanyChange" />

    </div>
  </div>

  <div class="screen" data-screen="hiring-essentials-worker-final-review" :data-active="currentScreen=='hiring-essentials-worker-final-review'">
    <img class="w-full max-w-100" src="/img/screens/admin-hiring-essentials-worker-final-review.png?v=09142023" />
    <div class="links">
      <screen-link left="17%" top="19.5%" width="19.5%" height="11%" dot-class="dot-left-center" data-tgt="hiring-essentials-add" @click.native="onLinkClick"></screen-link>
      <screen-link left="75%" top="48%" width="21.5%" height="5%" dot-class="dot-right-center" data-tgt="pullout-worker-final-review" @click.native="showPullout"></screen-link>

      <company-tab-hiring :company="company" @select="onCompanyChange" />

    </div>
  </div>

  <div class="screen" data-screen="hiring-essentials-worker-final-review-completed" :data-active="currentScreen=='hiring-essentials-worker-final-review-completed'">
    <img class="w-full max-w-100" src="/img/screens/admin-hiring-essentials-worker-final-review-completed.png?v=09142023" />
    <div class="links">
      <screen-link left="17%" top="19.5%" width="19.5%" height="11%" dot-class="dot-left-center" data-tgt="hiring-essentials-add" @click.native="onLinkClick"></screen-link>


      <company-tab-hiring :company="company" @select="onCompanyChange" />

    </div>
  </div>

  <!--- PEO --->
  <div class="screen" data-screen="hiring-peo" :data-active="currentScreen=='hiring-peo'">
    <img class="w-full max-w-100" src="/img/screens/peo/admin-hiring-peo.png?v=09142023" />
    <div class="links">
      <screen-link left="15.5%" top="17.5%" width="5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-peo" @click.native="onLinkClick"></screen-link>
      <screen-link left="20.5%" top="17.5%" width="6%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-peo-workers" @click.native="onLinkClick"></screen-link>


      <company-tab-hiring :company="company" @select="onCompanyChange" />



    </div>
  </div>

  <div class="screen" data-screen="hiring-peo-workers" :data-active="currentScreen=='hiring-peo-workers'">
    <img class="w-full max-w-100" src="/img/screens/peo/admin-hiring-peo-workers.png?v=09142023" />
    <div class="links">
      <screen-link left="15.5%" top="17.5%" width="5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-peo" @click.native="onLinkClick"></screen-link>
      <screen-link left="20.5%" top="17.5%" width="6%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-peo-workers" @click.native="onLinkClick"></screen-link>

      <screen-link left="17%" top="33%" width="12%" height="5.5%" dot-class="dot-left-center" data-tgt="hiring-peo-workers-add" @click.native="onLinkClick"></screen-link>


      <company-tab-hiring :company="company" @select="onCompanyChange" />


    </div>
  </div>

  <div class="screen" data-screen="hiring-peo-workers-add" :data-active="currentScreen=='hiring-peo-workers-add'">
    <img class="w-full max-w-100" src="/img/screens/peo/admin-hiring-peo-workers-add.png?v=09142023" />
    <div class="links">
      <screen-link left="15.5%" top="17.5%" width="5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-peo" @click.native="onLinkClick"></screen-link>
      <screen-link left="20.5%" top="17.5%" width="6%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-peo-workers" @click.native="onLinkClick"></screen-link>

      <screen-link left="17%" top="40.5%" width="36%" height="4.5%" dot-class="dot-left-center" data-tgt="hiring-peo-workers-add-details" @click.native="onLinkClick"></screen-link>


      <company-tab-hiring :company="company" @select="onCompanyChange" />


    </div>
  </div>

  <div class="screen" data-screen="hiring-peo-workers-add-details" :data-active="currentScreen=='hiring-peo-workers-add-details'">
    <img class="w-full max-w-100" src="/img/screens/peo/admin-hiring-peo-workers-add-details.png?v=09142023" />
    <div class="links">
      <screen-link left="15.5%" top="17.5%" width="5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-peo" @click.native="onLinkClick"></screen-link>
      <screen-link left="20.5%" top="17.5%" width="6%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-peo-workers" @click.native="onLinkClick"></screen-link>

      <screen-link left="22%" top="118.5%" width="7%" height="4.5%" dot-class="dot-right-center" data-tgt="hiring-peo-launch" data-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>


      <company-tab-hiring :company="company" @select="onCompanyChange" />


    </div>
  </div>

  <div class="screen" data-screen="hiring-peo-launch" :data-active="currentScreen=='hiring-peo-launch'">
    <img class="w-full max-w-100" src="/img/screens/peo/admin-hiring-peo-launch.png?v=09142023" />
    <div class="links">
      <screen-link left="15.5%" top="17.5%" width="5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-peo" @click.native="onLinkClick"></screen-link>
      <screen-link left="20.5%" top="17.5%" width="6%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-peo-workers" @click.native="onLinkClick"></screen-link>

      <screen-link left="17.5%" top="35%" width="5%" height="4.5%" dot-class="dot-right-center" data-tgt="onboarding-employee-info" data-tgt-mode="peo" @click.native="$screens.onLinkClick"></screen-link>


      <company-tab-hiring :company="company" @select="onCompanyChange" />


    </div>
  </div>


  <div class="screen" data-screen="hiring-offer-package" :data-active="currentScreen=='hiring-offer-package'">
    <img class="w-full max-w-100" src="/img/screens/hiring/candidate-offer-partial-fill.png?v=09142023" />
    <div class="links">

      <screen-link left="52%" top="84%" width="10%" height="10%" dot-class="dot-top-center" data-tgt="hiring-offer-package-filled"  @click.native="$screens.onLinkClick"></screen-link>
      <screen-link left="52%" top="111%" width="10%" height="10%" dot-class="dot-top-center" data-tgt="hiring-offer-package-filled"  @click.native="$screens.onLinkClick"></screen-link>
      <company-tab-hiring :company="company" @select="onCompanyChange" />

    </div>
  </div>
  <div class="screen" data-screen="hiring-offer-package-filled" :data-active="currentScreen=='hiring-offer-package-filled'">
    <img class="w-full max-w-100" src="/img/screens/hiring/candidate-offer-filled.png?v=09142023" />
    <div class="links">


      <screen-link left="76%" top="155.5%" width="6.5%" height="6%" dot-class="dot-top-center" data-tgt="hiring-offer-package-signature" data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
      <company-tab-hiring :company="company" @select="onCompanyChange" />

    </div>
  </div>
  <div class="screen" data-screen="hiring-offer-package-signature" :data-active="currentScreen=='hiring-offer-package-signature'">
    <img class="w-full max-w-100" src="/img/screens/hiring/candidate-offer-signature-field.png?v=09142023" />
    <div class="links">


      <screen-link left="31%" top="143.5%" width="5.5%" height="6%" dot-class="dot-top-center" data-tgt="hiring-offer-package-filled"  data-tgt-scrolltop="true"   @click.native="$screens.onLinkClick"></screen-link>
      <screen-link left="71.5%" top="37%" width="10%" height="6%" dot-class="dot-left-center" data-tgt="hiring-offer-package-signature-place"   @click.native="$screens.onLinkClick"></screen-link>
      <company-tab-hiring :company="company" @select="onCompanyChange" />

    </div>
  </div>
  <div class="screen" data-screen="hiring-offer-package-signature-place" :data-active="currentScreen=='hiring-offer-package-signature-place'">
    <img class="w-full max-w-100" src="/img/screens/hiring/candidate-offer-signature-field-place.png?v=09142023" />
    <div class="links">


      <screen-link left="70%" top="37%" width="5.5%" height="6%" dot-class="dot-top-center" data-tgt="hiring-offer-package-signature"  data-tgt-scrolltop="true"   @click.native="$screens.onLinkClick"></screen-link>
      <screen-link left="34%" top="114%" width="9%" height="6%" dot-class="dot-left-center" data-tgt="hiring-offer-package-signature-place-confirm"   @click.native="$screens.onLinkClick"></screen-link>
      <company-tab-hiring :company="company" @select="onCompanyChange" />

    </div>
  </div>

  <div class="screen" data-screen="hiring-offer-package-signature-place-confirm" :data-active="currentScreen=='hiring-offer-package-signature-place-confirm'">
    <img class="w-full max-w-100" src="/img/screens/hiring/candidate-offer-signature-field-place-confirm.png?v=09142023" />
    <div class="links">


      <screen-link left="70%" top="37%" width="5.5%" height="6%" dot-class="dot-top-center" data-tgt="hiring-offer-package-signature"  data-tgt-scrolltop="true"   @click.native="$screens.onLinkClick"></screen-link>
      <screen-link left="76%" top="37%" width="5.5%" height="6%" dot-class="dot-top-center" data-tgt="hiring-offer-package-signature-placed"   @click.native="$screens.onLinkClick"></screen-link>
      <company-tab-hiring :company="company" @select="onCompanyChange" />

    </div>
  </div>

  <div class="screen" data-screen="hiring-offer-package-signature-placed" :data-active="currentScreen=='hiring-offer-package-signature-placed'">
    <img class="w-full max-w-100" src="/img/screens/hiring/candidate-offer-signature-field-placed.png?v=09142023" />
    <div class="links">


      <screen-link left="31%" top="143.5%" width="5.5%" height="6%" dot-class="dot-top-center" data-tgt="hiring-offer-package-filled"  data-tgt-scrolltop="true"   @click.native="$screens.onLinkClick"></screen-link>
      <screen-link left="76%" top="143.5%" width="7%" height="6%" dot-class="dot-left-center" data-tgt="hiring-offer-package-email" data-tgt-scrolltop="true"   @click.native="$screens.onLinkClick"></screen-link>
      <company-tab-hiring :company="company" @select="onCompanyChange" />

    </div>
  </div>

  <div class="screen" data-screen="hiring-offer-package-email" :data-active="currentScreen=='hiring-offer-package-email'">
    <img class="w-full max-w-100" src="/img/screens/hiring/candidate-offer-email.png?v=09152023" />
    <div class="links">

      <div class="offer-email-body" style="top:81%; left:33.4%; width:47.5%; max-height: 18%; color:white;" v-text="$store.state.offerEmailBody"></div>

      <screen-link left="32.5%" top="49%" width="26%" height="6%" dot-class="dot-left-center" data-tgt="hiring-offer-package-email-body"   @click.native="$screens.onLinkClick"></screen-link>
      <company-tab-hiring :company="company" @select="onCompanyChange" />

    </div>
  </div>

  <div class="screen" data-screen="hiring-offer-package-email-body" :data-active="currentScreen=='hiring-offer-package-email-body'">
    <img class="w-full max-w-100" src="/img/screens/hiring/candidate-offer-email-filled.png?v=09152023" />
    <div class="links">
      <div class="offer-email-body" style="top:81%; left:33.4%; width:47.5%; max-height: 18%; color:white;"  v-text="$store.state.offerEmailBody"></div>

      <screen-link left="32.5%" top="80%" width="49%" height="18%" dot-class="dot-top-center" data-tgt="hiring-offer-package-email-filled"  @click.native="$screens.onLinkClick"></screen-link>
      <screen-link left="31.3%" top="105%" width="5.5%" height="6%" dot-class="dot-top-center" data-tgt="hiring-offer-package-signature-placed"  data-tgt-scrolltop="true"   @click.native="$screens.onLinkClick"></screen-link>

      <company-tab-hiring :company="company" @select="onCompanyChange" />

    </div>
  </div>

  <div class="screen" data-screen="hiring-offer-package-email-filled" :data-active="currentScreen=='hiring-offer-package-email-filled'">
    <img class="w-full max-w-100" src="/img/screens/hiring/candidate-offer-email-filled.png?v=09152023" />
    <div class="links">
      <div class="offer-email-body" style="top:81%; left:33.4%; width:47.5%; max-height: 18%;" v-text="$store.state.offerEmailBody"></div>


      <screen-link left="31.3%" top="105%" width="5.5%" height="6%" dot-class="dot-top-center" data-tgt="hiring-offer-package-signature-placed"  data-tgt-scrolltop="true"   @click.native="$screens.onLinkClick"></screen-link>
      <screen-link left="76%" top="104.8%" width="7%" height="6%" dot-class="dot-left-center" data-tgt="hiring-offer-package-review"  data-tgt-scrolltop="true" @click.native="$screens.onLinkClick"></screen-link>
      <company-tab-hiring :company="company" @select="onCompanyChange" />

    </div>
  </div>

  <div class="screen" data-screen="hiring-offer-package-review" :data-active="currentScreen=='hiring-offer-package-review'">
    <img class="w-full max-w-100" src="/img/screens/hiring/candidate-offer-review.png?v=09142023" />
    <div class="links">

      <div class="offer-email-body" style="top:98.9%; left:50.3%; width:25%; max-height: 19.5%;" v-text="$store.state.offerEmailBody"></div>
      <screen-link left="37%" top="124.5%" width="5.5%" height="6%" dot-class="dot-top-center" data-tgt="hiring-offer-package-email-filled"  data-tgt-scrolltop="true"   @click.native="$screens.onLinkClick"></screen-link>
      <screen-link left="70.25%" top="124.5%" width="7%" height="6%" dot-class="dot-top-center" data-tgt="pullout-candidate" data-init-info="offer-confirmation" @click.native="showPullout"></screen-link>
      <company-tab-hiring :company="company" @select="onCompanyChange" />

    </div>
  </div>

  <!--- FLEX ONBOARDING DEMO / COPILOT -->

  <div class="screen" data-screen="hiring-onboarding" :data-active="currentScreen=='hiring-onboarding'">
    <img class="w-full max-w-100" src="/img/screens/hiring/hiring-copilot-dashboard.png?v=12222024" />
    <div class="links">
      <screen-link left="55%" top="41.5%" width="7.5%" height="5%" dot-class="dot-left-center" data-tgt="hiring-copilot" data-tgt-mode="copilot" @click.native="onLinkClick"></screen-link>

      <company-tab-hiring :company="company" @select="onCompanyChange" />

    </div>
  </div>



</div>
</template>

<script>

import CompanyTabHiring from "@/components/CompanyTabHiring";
import BasicOptionsPanel from "@/components/BasicOptionsPanel";
//import ComingSoon from "@/components/ComingSoon";
//import CompanySelectHiring from "@/components/admin/CompanySelectHiring";
export default {
    name: "admin-hiring",
  components: {

    BasicOptionsPanel,
    //CompanySelectHiring,
    CompanyTabHiring
  },
  mounted() {

  },
  props: {
      currentScreen: {},
  },
    data: function(){
        return {
          candidateOptions: [
            { label: "Manage", value: "manage"},
            { label: "Duplicate Job", value: "duplicate"},
            { label: "Edit Hiring Process", value: "edit"},
            { label: "Deactivate", value: "deactivate", variant: "destructive"}
          ],
          candidateSearchOptions: [
            { label: "Person", value: "person", screenLink: true},
            { label: "Resume", value: "resume", screenLink: true},
            { label: "Status", value: "edit"},
          ]
        }
        },
    methods: {

      onPlaceSignature(e){
        console.log(this.$store.state.screenScrollRef, e);
        this.$store.state.screenScrollRef.scrollTo(0,400)
        this.$screens.onLinkClick(e)


      },

      postJob(e){
        this.$store.state.jobPosted = true;
        this.onLinkClick(e)
      },

      onCandidateOptionClick: function(item){

        this.closeOverlay()

        if(item.value === 'view'){
          this.onLinkClick({
            target: {
              dataset: {
                tgt: "candidate-posting",
                tgtMode: "candidate"
              }
            }
          })
        }

        if(item.value === 'resume'){
          this.onLinkClick({
            target: {
              dataset: {
                tgt: "hiring-candidates-search-resume",
                tgtMode: "admin"
              }
            }
          })
        }

        if(item.value === 'person'){
          this.onLinkClick({
            target: {
              dataset: {
                tgt: "hiring-all-candidates",
                tgtMode: "admin"
              }
            }
          })
        }


      },

      onEmailEdit: function(e){
        console.log("email edit", e.target.textContent)
        if(e.target.textContent !== this.$store.state.offerEmailBody){
          //this.$store.commit("updateEmail",)
          this.$store.state.offerEmailBody = e.target.textContent;
        }
      },

      onLinkClick: function(e){
        this.$screens.onLinkClick(e);
      },

      showPullout: function(e){
        this.$screens.showPullout(e)
      },

      showExternalPage: function(e){
        this.$screens.showExternalPage(e);
      },

      closeOverlay: function(){
        this.$screens.setOverlayPanel()
      },

      closePullout: function(){
        this.$screens.closePullout()
      },
      onCompanyChange: function(val){
        console.log("company",val);
        this.$store.commit('setHiringCompany',val);
      }
    },
    computed: {
      company: function(){
        return this.$store.state.hiringCompany
      }
    }
}
</script>

<style scoped>


.admin-reports {

}

.reports-labor-cost-threshold-chart {
  position: absolute;
  width: calc((366/1580) * 100%);
  left: calc((306.5/1580)* 100%);
  top: calc((812.5/1830) * 100%);
  opacity: 0;
  cursor: pointer;

}

.reports-labor-cost-threshold-chart:hover {
  opacity: 1.0;
}
 .offer-email-body {
   position: absolute;
   font-family: Helvetica, Arial, sans-serif;
   white-space: pre-wrap;
   font-size: 0.89em;
   line-height: 1.4em;
   background-color: white;

   overflow: hidden;

 }








</style>
