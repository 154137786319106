<template>
  <div class="company-select">
    <img src="/img/screens/hiring/admin-hiring-company-select.png?v=09142023" />
    <screen-link v-if="activeCompanies.enterprise" left="3%" top="30.3%" width="90%" height="12%" dot-class="dot-right-center" data-tgt="hiring-v2-home" @click.native="setCompany('enterprise','hiring-home')" />

    <screen-link v-if="activeCompanies.select" left="3%" top="43%" width="90%" height="12%" dot-class="dot-right-center" data-tgt="hiring-home" @click.native="setCompany('select','hiring-home')" />
    <screen-link v-if="activeCompanies.initech" left="3%" top="55.7%" width="90%" height="12%" dot-class="dot-right-center" data-tgt="hiring-norecruit" @click.native="setCompany('initech','hiring-norecruit')" />
    <screen-link v-if="activeCompanies.pro" left="3%" top="68.4%" width="90%" height="12%" dot-class="dot-right-center" data-tgt="hiring-essentials" @click.native="setCompany('pro','hiring-essentials')" />
    <screen-link v-if="activeCompanies.sandysport" left="3%" top="81.1%" width="90%" height="12%" dot-class="dot-right-center" data-tgt="hiring-essentials" @click.native="setCompany('sandysport','hiring-onboarding')" />

  </div>
</template>

<script>
export default {
  name: "company-select-hiring",
  props: {
    triggerCode: {
      type: String,
      default: ""
    },
    activeCompanies: {
      type: Object,
      default: function() {
        return {
          enterprise: true,
          pro: true,
          select: true,
          peo: true,
          initech: true,
          sandysport: true
        }
      }
    }
  },
  methods: {
    setCompany: function(company, screen){
      if((company === "pro") || (company === "enterprise")){
        this.$store.commit('setCompany', company);
      }

      this.$screens.onLinkClick({
        target:{
          dataset:{
            tgt: screen
          }
        }
      })

      this.$emit('select',company)
      this.$screens.toggleOverlay();
    }
  }
}
</script>

<style scoped>

  .company-select {
    background-color: white;
    position: absolute;
    top: calc(100% + 0.5em);
    box-shadow: rgba(0,0,0,0.2) 0 0 10px;

  }

  .company-select img {
    width: calc((320 / 1580) * 100 * 1em);
  }

</style>